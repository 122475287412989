import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faBars, faCaretDown, faCaretUp, faHome, faInfoCircle, faFile, faUsers, faAddressCard, faPlusCircle, faMinusCircle, faPen, faEye, faLightbulb, faChartLine, faUser, faSlidersH, faEllipsisV, faTrashAlt, faAngleRight, faAngleDown, faAngleUp, faTimesCircle, faArrowCircleLeft, faArrowCircleRight, faCheckCircle, faFileAlt, faChevronLeft, faClipboard, faShareSquare, faFlag, faDesktop, faExternalLinkAlt, faEdit, faTimes, faFileInvoice, faDatabase, faHouseDamage, faTree, faCircle, faCheck, faSave, faUmbrella, faLock, faTags, faMapSigns, faFileSignature, faDonate, faEnvelope, faLifeRing, faFilePdf, faShareAlt, faSync, faCopy, faCalendarCheck, faBan, faCheckDouble, faClipboardList, faLockOpen, faUnlock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { BootstrapVue } from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2';
import axios from "./axios";

import "vue2-dropzone/dist/vue2Dropzone.min.css";

Vue.prototype.$axios = axios;

library.add(faUserSecret, faBars, faCaretDown, faCaretUp, faHome, faInfoCircle, faFile, faUsers, faAddressCard, faPlusCircle, faMinusCircle, faPen, faEye, faLightbulb, faChartLine, faUser, faSlidersH, faEllipsisV, faTrashAlt, faAngleRight, faAngleDown, faAngleUp, faTimesCircle, faArrowCircleLeft, faFlag, faArrowCircleRight, faCheckCircle, faFileAlt, faChevronLeft, faClipboard, faShareSquare, faDesktop, faExternalLinkAlt, faEdit, faFileInvoice, faDatabase, faHouseDamage, faTimes, faTree, faCircle, faCheck, faSave, faUmbrella, faLockOpen, faLock, faUnlock, faTags, faMapSigns, faFileSignature, faDonate, faEnvelope, faLifeRing, faFilePdf, faShareAlt, faSync, faCopy, faCalendarCheck, faBan, faCheckDouble, faClipboardList)

Vue.component('font-awesome-icon', FontAwesomeIcon)
// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
// Vue.use(IconsPlugin)

Vue.use(Vuelidate)
Vue.use(VueSweetalert2)

Vue.directive('focus', {
  inserted: function (el) {
    el.focus()
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  beforeCreate() {
    this.$store.dispatch('auth/initialiseStore');
  },
  render: h => h(App)
}).$mount('#app')
