import axios from "../../axios";
import { Role } from '@/_helpers/role'

const state = {
    authenticated: JSON.parse(localStorage.getItem('_fsfe_apiKey')) ? true : false,
    apiKey: JSON.parse(localStorage.getItem('_fsfe_apiKey')) || null,
    realname: JSON.parse(localStorage.getItem('_fsfe_realname')) || null,
    accesslevel: JSON.parse(localStorage.getItem('_fsfe_accesslevel')) || null,
}

const getters = {
    isAdmin(state) {
        return state.accesslevel == Role.Admin
    },

    isUser(state) {
        return state.accesslevel == Role.User
    },

    isPartner(state) {
        return state.accesslevel == Role.LF || state.accesslevel == Role.Anticimex
    }
}

const mutations = {
    AUTH_LOGIN(state, payload) {
        state.authenticated = true
        state.apiKey = payload.key
        state.realname = payload.realname
        state.accesslevel = payload.accesslevel
    },

    AUTH_LOGOUT(state) {
        state.authenticated = false
        state.realname = null
        state.accesslevel = null
    },

    SET_AUTHENTICATION(state, payload = false) {
        state.authenticated = payload;
    },
}

const actions = {
    initialiseStore({ rootState, state, commit, dispatch }) {
        if (state.apiKey) {
            axios.get(rootState.baseUrl + 'api/validate.php?key=' + state.apiKey)
                .then((response) => {
                    if (response.data.status == 'ok') {
                        commit('SET_AUTHENTICATION', true)
                        dispatch('evaluation/fetchEvaluationTypes', null, { root: true })
                    } else {
                        commit('AUTH_LOGOUT')
                    }
                })
        } else {
            commit('AUTH_LOGOUT')
        }
    },

    validateAuth({ rootState, commit }) {
        return new Promise((resolve, reject) => {
            axios.post(rootState.baseUrl + 'api/validate.php')
                .then((response) => {
                    if (response.data.status == 'ok') {
                        // commit('AUTH_LOGIN', response.data)
                        resolve(response)
                    } else {
                        commit('ERRORS', response.data.error)
                    }
                })
                .catch((error) => {
                    commit('AUTH_LOGOUT')
                    reject(error)
                })
        })
    },

    login({ rootState, commit }, formData) {
        return new Promise((resolve, reject) => {
            axios.post(rootState.baseUrl + 'api/auth.php', formData)
                .then((response) => {
                    if (response.data.status == 'ok') {
                        commit('AUTH_LOGIN', response.data)
                        commit('ERRORS', null)
                        resolve(response)
                    } else {
                        commit('ERRORS', response.data.error)
                    }
                })
                .catch((error) => {
                    commit('AUTH_LOGOUT')
                    reject(error)
                })
        })
    },

    // auth.store.js
    logout({ rootState, commit }, formData) {
        return new Promise((resolve, reject) => {
            axios.post(rootState.baseUrl + 'api/logout.php', formData)
                .then((response) => {
                    commit('AUTH_LOGOUT')
                    resolve(response)
                })
                .catch((error) => {
                    commit('AUTH_LOGOUT')
                    reject(error)
                })
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}