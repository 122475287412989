<template>
	<div class="home w-100">
		<navbar />
		<div class="content p-4">
			<h3>Välkommen, {{ name }}!</h3>
			Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus, quaerat aliquam. Fuga culpa accusantium doloremque quaerat nobis odio at sed nostrum quibusdam, neque hic nihil, delectus voluptates necessitatibus, modi recusandae?
			<!-- <div class="row">
				<div class="col-xl-3">
					<recent-activity />
				</div>
				<div class="col-xl-9">
					<top-selling-products />
				</div>
			</div>
			<div class="row mt-3">
				<div class="col-xl-9">
					<recent-activity />
				</div>
				<div class="col-xl-3">
					<total-sales />
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
// import YearlyRaport from '@/components/YearlyRaport.vue'
// import TopSellingProducts from '@/components/TopSellingProducts.vue'
// import RecentActivity from '@/components/RecentActivity.vue'
// import TotalSales from '@/components/TotalSales.vue'

export default {
	name: 'home',
	data() {
		return {
			name: null
		}
	},

	components: {
		Navbar,
		// YearlyRaport,
		// RecentActivity,
		// TopSellingProducts,
		// TotalSales
	},

	mounted() {
		this.name = JSON.parse(localStorage.getItem('_fsfe_realname'))
	}
}
</script>
