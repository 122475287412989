<template>
  <div class="insurance-mail">
    <div class="container">
      <div class="row">
        <div class="col-md-8 insurance">
          <div class="object">
            <table class="table table-borderless none">
              <thead>
                <tr>
                  <th>Försäkringsobjekt</th>
                  <th>Försäkringbelopp</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="premium in premiums">
                  <tr
                    :key="premium.draftProductID"
                    v-if="premium.parentID == null"
                  >
                    <td>{{ premium.name }}</td>
                    <td>&nbsp;</td>
                  </tr>
                  <template v-if="premium.objects.length > 0">
                    <tr
                      class="product-child"
                      v-for="object in premium.objects"
                      :key="object.objectID"
                    >
                      <td class="productName" v-if="object.title != null">
                        {{ object.title }} {{ getTerm(object.termID) }}
                      </td>
                      <td class="productName" v-else>(tömt)</td>
                      <td v-if="object.premie">
                        {{ formatValue(object.objectValue) }}
                      </td>
                      <td v-else>0 kr</td>
                    </tr>
                  </template>
                  <template v-if="premium.subProducts.length > 0">
                    <template v-for="product in premium.subProducts">
                      <tr class="product-child" :key="product.productID">
                        <td class="productName">{{ product.name }}</td>
                        <td>&nbsp;</td>
                      </tr>
                      <template v-for="obj in product.objects">
                        <tr class="object-child" :key="obj.objectID">
                          <td class="objectTitle">
                            {{ obj.title }} {{ getTerm(obj.termID) }}
                          </td>
                          <td>
                            {{ formatValue(obj.objectValue) }}
                          </td>
                        </tr>
                      </template>
                    </template>
                  </template>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-md-4 premie">
          <div class="row">
            <div class="col-md-12">
              <div class="payment">
                <h5>Premie och betalning</h5>
                <table class="table table-borderless none">
                  <tbody>
                    <tr>
                      <td>Helårspremie</td>
                      <td>
                        {{
                          totalPremie
                            ? totalPremie.toLocaleString("sv-SE", {
                                style: "currency",
                                currency: "SEK",
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              })
                            : 0 + " kr"
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>Återbäring</td>
                      <td>0 kr</td>
                    </tr>
                    <tr>
                      <td>Kundbonus</td>
                      <td>0 kr</td>
                    </tr>
                    <tr>
                      <td>Att betala</td>
                      <td>
                        {{
                          totalPremie
                            ? totalPremie.toLocaleString("sv-SE", {
                                style: "currency",
                                currency: "SEK",
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              })
                            : 0 + " kr"
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="info">
                  <p></p>
                </div>
              </div>
            </div>
            <p class="divider">&nbsp;</p>
            <div class="col-md-12 sales">
              <div class="sales-info">
                <h5>Självrisker</h5>
                <p></p>
              </div>
            </div>
            <div class="col-md-12">
              <p class="divider">&nbsp;</p>
              <p>
                <strong>Status: </strong>
                <span class="badge badge-secondary" v-if="status === 'draft'"
                  >Utkast</span
                >
                <span class="badge badge-info" v-if="status === 'proposal'"
                  >Offert</span
                >
                <span class="badge badge-success" v-if="status === 'approved'"
                  >Godkänd</span
                >
              </p>
              <button
                v-if="status === 'draft'"
                type="button"
                class="btn btn-warning btn-block btnGr"
                @click="proposal()"
                :disabled="!selectedDate || checkMustEnd || !hasPremiumObject"
              >
                <font-awesome-icon icon="unlock" /> Skapa offert
              </button>
              <div class="alert alert-danger mt-3" v-if="!selectedDate">
                <p class="m-0">Startdatum ej valt</p>
              </div>
              <div class="alert alert-danger mt-3" v-if="checkMustEnd">
                <p class="m-0">Slutdatum ej valt</p>
              </div>
              <div
                class="alert alert-danger mt-3"
                role="alert"
                v-if="!hasPremiumObject"
              >
                <p class="m-0">
                  En eller flera produkter saknar objekt och det går därför inte
                  att spara.
                </p>
              </div>
              <p class="divider">&nbsp;</p>
              <a :href="'/print/rad.php?draftInsuranceID=' + insuranceid + '&key=' + apiKey" target="_blank">Rådgivningsdokumentation</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "insurance-mail",
  props: ["insuranceid", "premiums", "editable", "status"],

  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      apiKey: (state) => state.auth.apiKey,
      insurances: "insurances",
      terms: "terms"
    }),

    totalDraftPremium: function () {
      var sum = 0;
      for (let i = 0; i < this.premiums.length; i++) {
        let p = this.premiums[i];
        if (p.premie != null) {
          sum += p.premie;
        } else if (p.flatFee != null) {
          sum += p.flatFee;
        }
      }
      return sum;
    },

    totalPremie: function () {
      var sum = 0;
      for (var i = 0; i < this.premiums.length; i++) {
        var p = this.premiums[i];
        if (p.premie != null) {
          sum += p.premie;
        } else if (p.flatFee != null) {
          sum += p.flatFee;
        }
        for (var j = 0; j < p.objects.length; j++) {
          var q = p.objects[j];
          if (q.premie != null) {
            sum += q.premie * (q.risk / 100);
          } else if (q.flatFee != null) {
            sum += q.flatFee;
          }
        }
        for (let x = 0; x < p.subProducts.length; x++) {
          var product = p.subProducts[x];
          if (product.premie != null) {
            sum += product.premie;
          } else if (product.flatFee != null) {
            sum += product.flatFee;
          }
          for (let y = 0; y < product.objects.length; y++) {
            var object = product.objects[y];
            if (object.premie != null) {
              sum += object.premie * (object.risk / 100);
            } else if (object.flatFee != null) {
              sum += object.flatFee;
            }
          }
        }
      }
      return sum;
    },

    hasPremiumObject() {
      let hasObject = true;
      if (this.premiums.length > 0) {
        let found = true;
        this.premiums.map((premium) => {
          if (premium.evaluationTypeID === 2) {
            found = premium.objects.length > 0 ? true : false;
          }
        });
        hasObject = found;
      }
      return hasObject;
    },

    selectedDate() {
      let isSelected = true;
      if (this.premiums.length > 0) {
        this.premiums.map((premium) => {
          if (!premium.startDate) {
            isSelected = false;
          }
          let hasDate = true;
          premium.objects.map((obj) => {
            if (!obj.startDate) {
              hasDate = false;
            }
          });
          premium.subProducts.map((sp) => {
            if (!sp.startDate) {
              hasDate = false;
            }
            sp.objects.map((spObj) => {
              if (!spObj.startDate) {
                hasDate = false;
              }
            });
          });
          isSelected = hasDate;
        });
      }
      return isSelected;
    },

    checkMustEnd() {
      let mustEnd = false;
      if (this.premiums.length > 0) {
        this.premiums.map((premium) => {
          if (premium.mustEnd === "y") {
            console.log(premium.name);
            console.log(premium.objects);
          }
          premium.subProducts.map((sp) => {
            if (sp.mustEnd === "y") {
              let found = false;
              sp.objects.map((spObj) => {
                if (!spObj.endDate) {
                  found = true;
                }
              });
              mustEnd = found;
            }
          });
        });
      }
      return mustEnd;
    },
  },

  methods: {
    formatValue(value) {
      if (value > 999999999) {
        // return (value.toString().substring(0, 6) + "-" + value.toString().substring(6));
        return value.toLocaleString("sv-SE", {
          style: "currency",
          currency: "SEK",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      } else {
        return value
          ? value.toLocaleString("sv-SE", {
              style: "currency",
              currency: "SEK",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })
          : 0 + " kr";
      }
    },
    getTerm(termID) {
      let term = this.terms.find((term) => term.termID == termID);
      return term ? "(" + term.term + ")" : "(tom)";
    },

    proposal() {
      this.$axios
        .get(
          this.baseUrl +
            "api/draft.php?type=proposal&insuranceID=" +
            this.insuranceid +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.$emit("refresh", true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.insurance-mail {
  .insurance {
    .object {
      border: 1px solid #808b96;
      border-radius: 2px;
      padding: 10px;
      table.table-borderless {
        th {
          color: #000;
        }
        td {
          padding: 5px;
        }
        tr .productName {
          padding-left: 30px;
        }
        .parent,
        .child {
          width: 100%;
          padding-left: 100px;
          li {
            border: 0;
            // .title, .content {
            //     flex: 1;
            // }
            .content {
              padding-left: 80px;
            }
            .empty {
              width: 105px;
            }
          }
        }
        .parent .has-child {
          display: flex;
          // justify-content: space-between;
        }
        .child {
          li {
            display: flex;
            justify-content: space-between;
          }
        }
        .product-child {
          margin-left: 30px;
          .object-child {
            margin-left: 30px;
          }
        }
      }
    }
  }

  .premie {
    .payment {
      border: 1px solid #808b96;
      border-radius: 2px;
      padding: 10px;
      table {
        td {
          padding: 5px;
        }
      }
    }
    .divider {
      margin: 5px 0;
    }
    .sales {
      margin-top: 10px;
      .sales-info {
        border: 1px solid #808b96;
        border-radius: 2px;
        padding: 10px;
      }
    }
  }
}
</style>