<template>
  <div class="products w-100">
    <navbar />
    <div class="content p-4">
      <div class="row">
        <div class="col-md-10">
          <h3>Produkter</h3>
        </div>
      </div>
      <div class="mb-4"></div>
      <div class="row">
        <div class="col-md-5 box-info">
          <categories />
        </div>
        <div class="col-md-5 box-info">
          <standards />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Navbar from "@/components/Navbar.vue";
import Categories from "./categories.vue";
import Standards from "./standards.vue";
export default {
  name: "products",
  data() {
    return {
      category: [],
    };
  },

  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      apiKey: (state) => state.auth.apiKey,
    }),
  },

  components: {
    Navbar,
    Categories,
    Standards,
  },

  mounted() {
    this.fetchCategories();
  },

  methods: {
    ...mapActions('product', ['fetchCategories' ])
  }
};
</script>

