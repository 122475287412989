import Vue from 'vue'
import Vuex from 'vuex'
import state from './state'
import mutations from './mutations'
import actions from './actions'
import subscribe from "./modules/subscribe";
import client from "./modules/customer.store";
import user from "./modules/user.store";
import product from "./modules/product.store";
import auth from "./modules/auth.store";
import sidebar from "./modules/sidebar.store";
import evaluation from "./modules/evaluation.store";

Vue.use(Vuex)

const store = new Vuex.Store({
	state,
	mutations,
	actions,

	plugins: [subscribe],

	modules: {
		client,
		user,
		product,
		auth,
		sidebar,
		evaluation
	}
})

export default store;