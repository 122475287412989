<template>
  <tr
    class="customer-row"
    :class="{ opened: isOpen, selected: isSelected.isSelected }"
  >
    <td>
      <router-link :to="{ name: 'customer-show', params: { id: customer.id } }">
        {{
          customer.category == "co" ? customer.companyName : customer.firstName
        }}
        {{ customer.category == "co" ? "" : customer.lastName }}
      </router-link>
    </td>
    <td>
      <router-link :to="{ name: 'customer-show', params: { id: customer.id } }">
        {{ customer.street }}
      </router-link>
    </td>
    <td>
      <router-link :to="{ name: 'customer-show', params: { id: customer.id } }">
        {{ nicePostcode(customer.postcode) }}
      </router-link>
    </td>
    <td>
      <router-link :to="{ name: 'customer-show', params: { id: customer.id } }">
        {{ customer.city }}
      </router-link>
    </td>
    <td>
      <router-link :to="{ name: 'customer-show', params: { id: customer.id } }">
        {{
          customer.homePhone
            ? nicePhone(customer.homePhone)
            : nicePhone(customer.mobilePhone)
        }}
      </router-link>
    </td>
    <td>
      <router-link :to="{ name: 'customer-edit', params: { id: customer.id } }">
        <font-awesome-icon class="btnMdGr" icon="pen" />
      </router-link>
    </td>
  </tr>
</template>

<script>
import { parsePostcode, parsePhone } from "@/_helpers/parser";
export default {
  name: "customer",
  props: {
    customer: {
      required: true,
      type: Object,
    },
    isSelected: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      // client: null,
      isOpen: false,
    };
  },

  methods: {
    selectedCustomer() {
      this.isOpen = !this.isOpen;
    },

    nicePostcode(pc) {
      return parsePostcode(pc);
    },

    nicePhone(tfn) {
      return parsePhone(tfn);
    },
  },
};
</script>

<style lang="scss">
.customer-row {
  td {
    a {
      font-size: 14px;
      font-weight: 600;
      color: #7d7d7d;
      &:hover {
        color: #3498db;
      }
    }
  }
}

</style>