<template>
  <div class="sjalvrisk mb-3">
    <div class="boxheadline">
      <div class="row">
        <h5 class="col-md-10">Självrisk</h5>
        <a class="col-md-2" @click="$emit('toggleVisibility')"
          ><button class="closebtn"
            ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg></button
        ></a>
      </div>
    </div>
    <div class="content p-3">
      <div class="row">
        <div :class="{ 'col-md-12': !large, 'col-md-7': large }">
          <div class="alert alert-success" role="alert" v-if="message !== null">
            {{ message }}
          </div>
          <textarea
            class="form-control"
            placeholder="Skriv meddelande"
            v-model="srText"
            id="sr"
            rows="4"
          ></textarea>
        </div>
        <div class="col-md-12 mt-3">
          <div class="row">
            <div :class="{ 'col-md-8': !large, 'col-md-4': large }">
              <datepicker
                format="yyyy-MM-dd"
                input-class="form-control"
                :bootstrap-styling="true"
                v-model="srDate"
                :monday-first="true"
                :language="sv"
                placeholder="Välj datum"
              ></datepicker>
            </div>
            <div
              class="col-md-3"
              :class="{ 'col-md-4': !large, 'col-md-3': large }"
            >
              <button
                type="button"
                class="btn btn-block btnLargeGr"
                @click="saveSr()"
              >
                Spara
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { sv } from "vuejs-datepicker/dist/locale";
import moment from "moment";
import { mapState } from "vuex";
export default {
  props: ["message", "large"],
  data() {
    return {
      srSaved: null,
      srDate: moment().format("YYYY-MM-DD"),
      sv: sv,
    };
  },

  computed: {
    ...mapState(["sr", "insuranceInfo"]),
    srText: {
      get() {
        return this.srSaved == null ? this.sr : this.srSaved;
      },
      set(newValue) {
        this.srSaved = newValue;
      },
    },
  },

  components: { Datepicker },

  methods: {
    saveSr() {
      this.$emit("onClick", { srText: this.srText, srDate: this.srDate });
    },
  },
};
</script>

<style lang="scss" scoped>
.sjalvrisk {
  background: #fff;
  border-radius: 10px;
}
.boxheadline {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.closebtn {
  background-color: rgba(255, 255, 255, 0);
  border: none;
  color: #5b5b5b;
}
</style>