import axios from "../../axios";

const state = {
    evaluationTypes: []
}

const mutations = {
    FETCH_EVALUATIONTYPES(state, payload) {
        state.evaluationTypes = payload
    }
}

const actions = {
    fetchEvaluationTypes({rootState, commit}) {
        return new Promise((resolve, reject) => {
            axios.get(rootState.baseUrl + 'api/get.php?type=evaluationTypes&key=' + rootState.auth.apiKey)
                .then((response) => {
                    if (response.data.status == 'ok') {
                        commit('FETCH_EVALUATIONTYPES', response.data.data)
                        resolve(response)
                    } else {
                        commit('ERRORS', response.data.error)
                        reject(response.data.error)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}