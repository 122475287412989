<template>
  <div class="damage-details w-100">
    <navbar />
    <div class="content p-4">
      <div class="row">
        <div class="col-md-12 box-info">
          <div class="row docs-holder">
            <div class="col-md-12">
              <div class="header">
                <h4>Artiklar</h4>
                <button
                  type="button"
                  class="btn btn-secondary btnLargeGr"
                  v-if="!dropzone"
                  @click="addImages()"
                >
                  <font-awesome-icon icon="plus-circle" />&nbsp;Ladda upp
                </button>
                <div class="actions" v-if="dropzone">
                  <button
                    type="button"
                    class="btn btn-success mr-3"
                    :disabled="files.length == 0"
                    @click="saveImages()"
                  >
                    <span v-if="!loading">
                      <font-awesome-icon icon="share-square" />&nbsp;Spara
                    </span>
                    <span v-if="loading">
                      <pulse-loader
                        :loading="loading"
                        size="15px"
                      ></pulse-loader>
                    </span>
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger"
                    :disabled="loading"
                    @click="resetDropzone()"
                  >
                    <font-awesome-icon icon="times-circle" />&nbsp;Avbryt
                  </button>
                </div>
              </div>
              <div
                class="alert alert-success"
                role="alert"
                v-if="alertMassage != null && !dropzone"
              >
                {{ alertMassage }}
              </div>
              <pulse-loader
                :loading="loading && !dropzone"
                size="18px"
              ></pulse-loader>
              <div
                class="table-responsive table-striped docs"
                v-if="docs.length > 0 && !dropzone"
              >
                <table class="table">
                  <tbody>
                    <tr v-for="(doc, index) in docs" :key="index">
                      <td>
                        <div class="thumbnail">
                          <img
                            :src="getURL(doc.fileID)"
                            class="img-thumbnail"
                            width="60px"
                            v-if="fileType(doc.mimetype) == 'IMAGE'"
                          />
                          <img
                            :src="require('@/assets/pdf.png')"
                            class="img-thumbnail"
                            width="60px"
                            v-if="fileType(doc.mimetype) == 'PDF'"
                          />
                          <a
                            class="overlay text-center"
                            target="_blank"
                            :href="getURL(doc.fileID)"
                          >
                            <font-awesome-icon icon="share-square" />
                          </a>
                        </div>
                      </td>
                      <td>
                        <span v-if="doc.description != 'null'">{{
                          doc.description
                        }}</span>
                        <span v-if="doc.description == 'null'">&nbsp;</span>
                      </td>
                      <td>{{ doc.ts }}</td>
                      <td>
                        <span class="edit" @click="deleteFile(doc.fileID)">
                          <font-awesome-icon icon="trash-alt" />
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                class="alert alert-primary"
                role="alert"
                v-if="docs.length == 0 && !dropzone && !loading"
              >
                Inga filer uppladdade
              </div>
              <div class="image-zone" v-if="dropzone">
                <div class="table-responsive preview" v-if="files.length > 0">
                  <table class="table">
                    <tbody>
                      <tr v-for="(file, index) in files" :key="index">
                        <td>
                          <img
                            :src="createURL(file.file)"
                            class="img-thumbnail"
                            width="60px"
                            v-if="fileType(file.file.type) == 'IMAGE'"
                          />
                          <img
                            :src="require('@/assets/pdf.png')"
                            class="img-thumbnail"
                            width="60px"
                            v-if="fileType(file.file.type) == 'PDF'"
                          />
                        </td>
                        <td>{{ file.name }}</td>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Ange beskrivning"
                            v-model="file.description"
                          />
                        </td>
                        <td>{{ file.uploadedAt }}</td>
                        <td>
                          <span class="remove" @click="removeImage(index)">
                            <font-awesome-icon icon="times-circle" />
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="field imageDropzone">
                  <small>Filformat som stöds är pdf, docx, jpg och png.</small>
                  <div
                    id="dropzone"
                    :class="['dropzone', dragging ? 'dragover' : '']"
                    @drop="handleImageDrop"
                    @dragstart="dragging = true"
                    @dragover="dragging = true"
                    @dragenter="dragging = true"
                    @dragleave="dragging = false"
                    @dragend="dragging = false"
                  >
                    <h2 class="title">Dra och släpp filen här</h2>
                    <p>eller</p>
                    <div class="custom-file mb-3">
                      <input
                        type="file"
                        class="custom-file-input"
                        id="file"
                        @change="onFileChange"
                        required
                      />
                      <label
                        class="custom-file-label"
                        for="file"
                        data-browse="Välj fil"
                        >Välj fil...</label
                      >
                      <div class="invalid-feedback">
                        Example invalid custom file feedback
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="alert alert-danger"
                  role="alert"
                  v-if="errorMessage != null"
                >
                  {{ errorMessage }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { mapState } from "vuex";
import Navbar from "@/components/Navbar.vue";
import { required } from "vuelidate/lib/validators";
var moment = require("moment");

export default {
  name: "article-admin",
  data() {
    return {
      dropzone: false,
      dragging: false,
      extensions: ["png", "jpg", "jpeg", "pdf", "docx"],
      url: null,
      files: [],
      errorMessage: null,
      docs: [],
      alertMassage: null,
      loading: false,
    };
  },

  validations: {
    note: { required },
  },

  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      apiKey: (state) => state.auth.apiKey,
    }),
  },

  components: {
    Navbar,
    PulseLoader,
  },

  mounted() {
    this.fetchFiles();

    document.addEventListener("drop", function (e) {
      e.preventDefault();
    });
    document.addEventListener("dragover", function (e) {
      e.preventDefault();
    });
  },

  beforeRouteLeave(to, from, next) {
    if (this.files != null && this.files.length > 0) {
      const answer = window.confirm(
        "Du har inte sparat filerna. Vill du radera dem?"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },

  methods: {
    convertDate(date) {
      return moment(date).format("D MMM YYYY, HH:mm");
    },

    handleDragOver() {
      this.dragging = true;
    },

    handleImageDrop(e) {
      this.errorMessage = null;
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      for (let i = 0; i < files.length; i++) {
        if (this.isImageValidity(files[i])) {
          let fileName = files[i].name.split(".");
          this.files.push({
            file: files[i],
            name: fileName[0],
            description: null,
          });
        } else {
          this.errorMessage = "En eller några filer är inte tillåtna att välja";
        }
      }
    },

    onFileChange(e) {
      this.errorMessage = null;
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }

      if (this.isImageValidity(files[0])) {
        let fileName = files[0].name.split(".");
        this.files.push({
          file: files[0],
          name: fileName[0],
          description: null,
        });
      } else {
        this.errorMessage = "En eller några filer är inte tillåtna att välja";
      }
    },

    isImageValidity(file) {
      const name = file.name.split(".");
      const extension = name[1];
      const check = this.extensions.includes(extension);

      if (check) {
        return true;
      }

      return false;
    },

    fetchFiles() {
      this.loading = true;
      this.docs = [];
      this.$axios
        .get(this.baseUrl + "api/get.php?type=articles&key=" + this.apiKey)
        .then((response) => {
          if (response.data.status == "ok") {
            this.docs = response.data.data;
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deleteFile(fileID) {
      if (confirm("Är du säker på att du vill ta bort filen?")) {
        this.$axios
          .get(
            this.baseUrl +
              "api/edit.php?type=deleteFile&fileID=" +
              fileID +
              "&key=" +
              this.apiKey
          )
          .then(() => {
            this.fetchFiles();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    getURL(fileID) {
      return (
        this.baseUrl +
        "api/getFile.php?fileID=" +
        fileID +
        "&key=" +
        this.apiKey
      );
    },

    addImages() {
      this.dropzone = !this.dropzone;
      this.alertMassage = null;
    },

    createURL(file) {
      let url = URL.createObjectURL(file);

      return url;
    },

    resetDropzone() {
      this.dropzone = !this.dropzone;
      this.files = [];
      this.errorMessage = null;
    },

    removeImage(selectedIndex) {
      this.$delete(this.files, selectedIndex);
    },

    fileType(mimetype) {
      if (
        mimetype == "image/png" ||
        mimetype == "image/jpeg" ||
        mimetype == "image/jpg"
      ) {
        return "IMAGE";
      } else if (mimetype == "application/pdf") {
        return "PDF";
      } else if (
        mimetype ==
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        return "DOCX";
      }
    },

    saveImages() {
      this.loading = true;
      this.files.forEach((file, index) => {
        var formData = new FormData();
        formData.append("file", file.file);
        formData.append("description", file.description);
        this.$axios
          .post(
            this.baseUrl + "api/uploadArticle.php?key=" + this.apiKey,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            if (response.data.status == "ok") {
              if (index === this.files.length - 1) {
                this.loading = false;
                this.alertMassage = "Filerna uppladdade";
                this.files = [];
                this.dropzone = !this.dropzone;
                this.fetchFiles();
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
  },
};
</script>

<style lang="scss">
.damage-details {
  .damages {
    display: flex;
    flex-direction: row;
    padding: 20px;
    background: #f1f1f1;
    .card {
      flex: 1;
      // max-width: 500px;
    }
    .insurance {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .customer {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    table {
      th {
        font-size: 14px;
        padding-right: 10px;
      }
    }
  }
  .events {
    margin-top: 16px;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
  .event {
    padding: 10px 15px;
    background: #f7f7f7;
    border-radius: 5px;
    .event-text {
      font-size: 16px;
    }
    .author {
      font-size: 12px;
      font-weight: 600;
      color: #797979;
      margin: 0;
      span:not(:last-child) {
        padding-right: 8px;
      }
      svg {
        font-size: 14px;
      }
    }
    .separator {
      color: #000;
    }
  }

  .docs-holder {
    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #d7d7dd;
      border-bottom: 1px solid #d7d7dd;
      padding: 15px 10px;
      background: #f1f1f1;
      margin: 20px 0;
      h4 {
        margin: 0;
        padding: 0;
      }
    }
    .docs {
      table {
        tr:hover {
          background: #f1f1f1;
          td .overlay {
            display: block;
          }
        }
        td {
          vertical-align: middle;
          .thumbnail {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            width: 60px;
            height: 60px;
            .overlay {
              display: none;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: rgba(0, 0, 0, 0.65);
              z-index: 9999;
              color: #fff;
              cursor: pointer;
              border-radius: 4px;
              svg {
                font-size: 20px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }
          .edit {
            cursor: pointer;
            &:hover {
              color: #209cee;
            }
          }
        }
        p {
          margin: 0;
          padding: 0;
        }
        img {
          border: 0;
        }
      }
    }
    .image-zone {
      .preview {
        table {
          tr:hover {
            background: #f1f1f1;
          }
          td {
            vertical-align: middle;
          }
          p {
            margin: 0;
            padding: 0;
          }
          img {
            border: 0;
          }
          svg {
            color: #e74c3c;
            font-size: 18px;
            cursor: pointer;
          }
        }
      }
      .imageDropzone {
        margin: 15px 0;
      }

      .dropzone {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 2px dashed #8e8e8e;
        padding: 35px 0;
        .custom-file {
          width: 400px;
        }
        .title {
          text-transform: uppercase;
          color: #6f6f6f;
          font-size: 22px;
        }
        .file {
          align-items: center;
          justify-content: center;
        }
      }
      .dropzone.dragover {
        border-color: #209cee;
        background: #efefef;
      }
    }
  }

  .costs {
    table {
      th {
        font-size: 15px;
      }
    }
  }

  @media (max-width: 1725.98px) {
    .damages {
      flex-direction: column;
      .card {
        width: 100%;
      }
    }
  }
}
</style>