var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"economy w-100"},[_c('navbar'),_c('div',{staticClass:"content p-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 box-info"},[_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"payment-form"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.year),expression:"year"}],staticClass:"form-control",attrs:{"type":"number"},domProps:{"value":(_vm.year)},on:{"input":function($event){if($event.target.composing){ return; }_vm.year=$event.target.value}}})]),_c('b-form-group',{staticClass:"payment-form"},[_c('datepicker',{attrs:{"format":"yyyy-MM-dd","input-class":"form-control","bootstrap-styling":true,"monday-first":true,"language":_vm.sv,"placeholder":"Välj datum"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('b-form-group',{staticClass:"payment-form"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.refnr),expression:"refnr"}],ref:"am",staticClass:"form-control",class:{
                red: _vm.wrongOcr && _vm.refnr != null && _vm.refnr.length > 0,
                green: !_vm.wrongOcr && _vm.refnr != null && _vm.refnr.length > 0,
                white: _vm.refnr == null || _vm.refnr.length == 0,
              },attrs:{"type":"number","placeholder":"OCR","id":"OCR"},domProps:{"value":(_vm.refnr)},on:{"keyup":function($event){return _vm.checkOcr()},"input":function($event){if($event.target.composing){ return; }_vm.refnr=$event.target.value}}})]),_c('b-form-group',{staticClass:"payment-form"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.amount),expression:"amount"}],staticClass:"form-control",class:{
                red: _vm.wrongAmount && _vm.amount != null && _vm.amount.length > 0,
                green: !_vm.wrongAmount && _vm.amount != null && _vm.amount.length > 0,
                white: _vm.amount == null || _vm.amount.length == 0,
              },attrs:{"type":"number","placeholder":"Belopp","id":"amount"},domProps:{"value":(_vm.amount)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==13){ return null; }return _vm.checkout.apply(null, arguments)},"keyup":function($event){return _vm.checkAmount()},"input":function($event){if($event.target.composing){ return; }_vm.amount=$event.target.value}}})]),_c('b-button',{staticClass:"payment-form",staticStyle:{"max-height":"37px"},attrs:{"id":"save","disabled":_vm.wrongOcr || !_vm.customer},on:{"click":function($event){return _vm.save()}}},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")])],1),_c('div',{staticClass:"col-md-10"},[_c('p',[_vm._v(" Kund: "+_vm._s(_vm.searching ? "Söker..." : _vm.customer == null ? "Ange giltigt OCR-nummer." : _vm.customer.category == "pp" ? _vm.customer.firstName + " " + _vm.customer.lastName : _vm.customer.companyName)+_vm._s(_vm.customer == null ? "" : ", " + _vm.customer.street + ", " + _vm.customer.postcode + " " + _vm.customer.city + ". (Kundnummer: " + _vm.customer.customerNr + ")")+" ")])])])]),_c('div',{staticClass:"row",staticStyle:{"margin-top":"24px"}},[_c('div',{staticClass:"col-md-12 box-info"},[_c('h3',[_vm._v("Senaste inbetalningarna")]),(_vm.payments != null && _vm.payments.length > 0)?_c('table',{staticClass:"table table-striped"},[_vm._m(0),_c('tbody',_vm._l((_vm.payments),function(p,index){return _c('tr',{key:index,attrs:{"id":"payment"}},[_c('td',[_vm._v(_vm._s(p.insuranceNr))]),_c('td',[_vm._v(" "+_vm._s(p.category == "pp" ? p.firstName + " " + p.lastName : p.companyName)+" ")]),_c('td',{staticStyle:{"text-align":"right"}},[_vm._v(" "+_vm._s(p.amount.toLocaleString("sv"))+" ")]),_c('td',{staticStyle:{"text-align":"right"}},[_vm._v(_vm._s(p.balance))])])}),0)]):_vm._e()])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("Försäkringsnr")]),_c('th',[_vm._v("Kund")]),_c('th',[_vm._v("Inbetalt")]),_c('th',[_vm._v("Saldo")])])])}]

export { render, staticRenderFns }