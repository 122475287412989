
export function parsePostcode(pc) {
    if (pc == undefined || pc == null || pc == "" || pc.length != 5) {
        return pc;
    } else {
        return pc.substring(0, 3) + " " + pc.substring(3);
    }
}

export function parsePhone(tfn) {
    if (tfn == undefined || tfn == null || tfn == "") {
        return "";
    }
    tfn = tfn.toString();
    if (tfn.substring(0, 1) != "0") {
        tfn = "0" + tfn;
    }
    var s = tfn.substring(0, 3);
    var t = tfn.substring(0, 4);
    var rikt, nr;
    if (t == "0730" || t == "0760" || t == "0720" || t == "0700") {
        rikt = t;
        nr = tfn.substring(4);
    } else if (
        s == "011" ||
        s == "013" ||
        s == "016" ||
        s == "018" ||
        s == "019" ||
        s == "021" ||
        s == "023" ||
        s == "026" ||
        s == "031" ||
        s == "033" ||
        s == "035" ||
        s == "036" ||
        s == "040" ||
        s == "042" ||
        s == "044" ||
        s == "046" ||
        s == "054" ||
        s == "060" ||
        s == "063" ||
        s == "090" ||
        s == "070" ||
        s == "072" ||
        s == "073" ||
        s == "076"
    ) {
        rikt = s;
        nr = tfn.substring(3);
    } else if (tfn.substring(0, 2) == "08") {
        rikt = tfn.substring(0, 2);
        nr = tfn.substring(2);
    } else {
        rikt = tfn.substring(0, 4);
        nr = tfn.substring(4);
    }
    if (nr.length == 5) {
        nr = nr.substring(0, 3) + " " + nr.substring(3);
    } else if (nr.length == 6) {
        nr =
            nr.substring(0, 2) + " " + nr.substring(2, 4) + " " + nr.substring(4);
    } else if (nr.length == 7) {
        nr =
            nr.substring(0, 3) + " " + nr.substring(3, 5) + " " + nr.substring(5);
    } else if (nr.length == 8) {
        nr =
            nr.substring(0, 2) +
            " " +
            nr.substring(2, 4) +
            " " +
            nr.substring(4, 6) +
            " " +
            nr.substring(6);
    }
    return rikt + "-" + nr;
}