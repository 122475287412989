<template>
  <div class="backup w-100 box-info">
    <ul>
      <li>
        <a target="_blank" :href="baseUrl + 'api/getBackup.php?key=' + apiKey"
          >Anteckningar</a
        >
      </li>
      <li>
        <a
          target="_blank"
          :href="baseUrl + 'api/getBackupInsurance.php?key=' + apiKey"
          >Försäkringar</a
        >
      </li>
      <li>
        <a
          target="_blank"
          :href="baseUrl + 'api/getBackupCustomers.php?key=' + apiKey"
          >Kunder</a
        >
      </li>
      <li>
        <a
          target="_blank"
          :href="baseUrl + 'api/getBackupDamages.php?key=' + apiKey"
          >Skador</a
        >
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "backup",
  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      apiKey: (state) => state.auth.apiKey,
      hidden: (state) => state.sidebar.hidden,
      authenticated: (state) => state.auth.authenticated,
      accesslevel: (state) => state.auth.accesslevel,
    }),
  },
};
</script>