<template>
  <div class="edit-damage w-100">
    <navbar />
    <div class="content p-4">
      <div class="row">
        <div class="col-md-7">
          <h4>Redigera skadehantering</h4>
          <div
            class="alert alert-success mt-3"
            role="alert"
            v-if="submitStatus == 'OK' && successMessage != null"
          >
            {{ successMessage }}
          </div>
          <div class="form-group box-over-info">
            <p class="mt-3" v-if="damage">
              {{ damage.customer.firstName }} {{ damage.customer.lastName
              }}{{ damage.customer.companyName }}
              <span class="vertical-divider" style="margin: 10px"> </span> Tel:
              {{ damage.customer.homePhone }},
              {{ damage.customer.mobilePhone }}
              <span class="vertical-divider" style="margin: 10px"> </span>
              Försäkringsnummer:
              <span class="font-weight-bold">{{ insuranceNr }}</span>
            </p>
          </div>
          <div class="box-info">
            <div class="form-group">
              <label for="damageDate">Skadedatum</label>
              <datepicker
                format="yyyy-MM-dd"
                input-class="form-control form-control-lg"
                :bootstrap-styling="true"
                v-model="damageDate"
                :monday-first="true"
                :language="sv"
                :disabledDates="disabledDates"
                placeholder="Ange datum"
                :class="{
                  'is-invalid': submitStatus == 'ERROR' && $v.damageDate.$error,
                }"
                :inputClass="{ 'is-invalid': skadedatum }"
              ></datepicker>
              <div class="invalid-feedback" v-if="!$v.damageDate.required">
                Välj skadedatum
              </div>
            </div>
            <div class="form-group">
              <label for="reportDate">Rapporteringsdatum</label>
              <datepicker
                format="yyyy-MM-dd"
                input-class="form-control form-control-lg"
                :bootstrap-styling="true"
                v-model="reportDate"
                :monday-first="true"
                :language="sv"
                :disabledDates="disabledDates"
                placeholder="Välj rapporteringsdatum"
                :inputClass="{ 'is-invalid': rapporteringsdatum }"
              ></datepicker>
            </div>
            <div class="form-group">
              <label for="endDate">Ärendet avslutat</label>
              <datepicker
                format="yyyy-MM-dd"
                input-class="form-control form-control-lg"
                :bootstrap-styling="true"
                v-model="endDate"
                :monday-first="true"
                :language="sv"
                :disabledDates="disabledDates"
                placeholder="Ärendet avslutat"
                :inputClass="{ 'is-invalid': avslutat }"
              ></datepicker>
            </div>
            <div class="form-group">
              <label for="prognosis">Prognostiserad kostnad</label>
              <input
                v-model="prognosis"
                type="number"
                class="form-control form-control-lg"
                id="prognosis"
                placeholder="Prognostiserad kostnad"
                :style="kostnad ? warningStyle : ''"
              />
            </div>
            <div class="form-group">
              <label for="category">Kategori</label>
              <select
                id="category"
                class="form-control form-control-lg"
                v-model="categoryID"
                :class="{
                  'is-invalid': submitStatus == 'ERROR' && $v.categoryID.$error,
                }"
                :style="kategori ? warningStyle : ''"
              >
                <option :value="null">Välj kategori</option>
                <template v-if="categories.length > 0">
                  <option
                    :value="category.categoryID"
                    v-for="(category, index) in categories"
                    :key="index"
                    :selected="category.categoryID == categoryID"
                  >
                    {{ category.category }}
                  </option>
                </template>
              </select>
              <div class="invalid-feedback" v-if="!$v.categoryID.required">
                Du måste välja kategori
              </div>
            </div>
            <div class="form-group">
              <label for="damageReport">Godkänd skadeanmälan upprättad:</label>
              <br />
              <div style="padding: 0.5 rem 1rem">
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    v-model="damageReport"
                    class="form-check-input"
                    id="n"
                    value="n"
                  />
                  <label
                    for="n"
                    class="form-check-label"
                    :class="{ 'text-danger': skadeanmalan }"
                    >nej</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    v-model="damageReport"
                    class="form-check-input"
                    id="y"
                    value="y"
                  />
                  <label
                    for="y"
                    class="form-check-label"
                    :class="{ 'text-danger': skadeanmalan }"
                    >ja</label
                  >
                </div>
              </div>
            </div>
            <button
              type="button"
              class="btn btn-secondary btn-block btn-lg btnGr"
              :disabled="
                !skadedatum &&
                !rapporteringsdatum &&
                !avslutat &&
                !kostnad &&
                !kategori &&
                !skadeanmalan
              "
              @click="updateDamage()"
            >
              Uppdatera
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Navbar from "@/components/Navbar.vue";
import { required } from "vuelidate/lib/validators";
import Datepicker from "vuejs-datepicker";
import { sv } from "vuejs-datepicker/dist/locale";
import moment from "moment";
export default {
  name: "edit-damage",
  data() {
    return {
      sv: sv,
      categories: [],
      damage: null,
      insuranceNr: null,
      damageDate: null,
      reportDate: new Date(),
      endDate: null,
      prognosis: null,
      categoryID: null,
      damageReport: null,
      disabledDates: {
        from: new Date(),
      },
      submitStatus: null,
      successMessage: null,
      initialValues: {
        damageDate: null,
        reportDate: null,
        endDate: null,
        prognosis: null,
        categoryID: null,
        damageReport: null,
      },
      warningStyle: {
        background: "#FADBD8",
        borderColor: "#E74C3C",
      },
    };
  },

  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      apiKey: (state) => state.auth.apiKey,
    }),

    skadedatum() {
      let damageDate = this.damageDate
        ? moment(this.damageDate).format("YYYY-MM-DD")
        : null;

      return this.initialValues.damageDate !== damageDate;
    },

    rapporteringsdatum() {
      let reportDate = this.reportDate
        ? moment(this.reportDate).format("YYYY-MM-DD")
        : null;

      return this.initialValues.reportDate !== reportDate;
    },

    avslutat() {
      let endDate = this.endDate
        ? moment(this.endDate).format("YYYY-MM-DD")
        : null;

      return this.initialValues.endDate !== endDate;
    },

    kostnad() {
      return (
        parseInt(this.initialValues.prognosis) !== parseInt(this.prognosis)
      );
    },

    kategori() {
      return (
        parseInt(this.initialValues.categoryID) !== parseInt(this.categoryID)
      );
    },

    skadeanmalan() {
      return this.initialValues.damageReport !== this.damageReport;
    },
  },

  validations: {
    insuranceNr: { required },
    damageDate: { required },
    categoryID: { required },
  },

  components: {
    Navbar,
    Datepicker,
  },

  mounted() {
    this.fetchDamageCategories();
    this.fetchDamage();
  },

  methods: {
    fetchDamageCategories() {
      this.$axios
        .get(this.baseUrl + "api/getDamageCategories.php?key=" + this.apiKey)
        .then((response) => {
          this.categories = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchDamage() {
      let damageID = this.$route.params.damageID;
      this.$axios
        .get(
          this.baseUrl +
            "api/getDamage.php?id=" +
            damageID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.damage = response.data;
            this.insuranceNr = this.damage.damage.insuranceNr;
            this.damageDate = this.damage.damage.damageDate;
            this.reportDate = this.damage.damage.reportDate;
            this.endDate = this.damage.damage.endDate;
            this.prognosis = this.damage.damage.prognosis;
            this.categoryID = this.damage.damage.categoryID;
            this.damageReport =
              this.damage.damage.damageReport == null ? "n" : "y";

            this.setInitialValues(this.damage.damage);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateDamage() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";

        let damageID = this.$route.params.damageID;

        this.submitStatus = "PENDING";

        let damageDate = moment(this.damageDate).format("YYYY-MM-DD");
        let reportDate = moment(this.reportDate).format("YYYY-MM-DD");
        let endDate = moment(this.endDate).format("YYYY-MM-DD");

        var formData = new FormData();
        formData.append("id", damageID);
        formData.append("insuranceNr", this.insuranceNr);
        formData.append("damageDate", damageDate);
        formData.append("categoryID", this.categoryID);
        formData.append("damageReport", this.damageReport);
        formData.append("reportDate", reportDate);
        formData.append("endDate", endDate);
        formData.append("prognosis", this.prognosis);
        this.$axios
          .post(this.baseUrl + "api/damage.php?key=" + this.apiKey, formData)
          .then((response) => {
            if (response.data.status == "ok") {
              this.submitStatus = "OK";
              this.$router.push({
                name: "damage-details",
                params: { damageID: damageID },
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    setInitialValues(damage) {
      this.initialValues = {
        damageDate: damage.damageDate,
        reportDate: damage.reportDate,
        endDate: damage.endDate,
        prognosis: damage.prognosis,
        categoryID: damage.categoryID,
        damageReport: !damage.damageReport ? "n" : "y",
      };
    },
  },
};
</script>