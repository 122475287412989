<template>
  <div class="accounting w-100">
    <navbar headertext="Startsida" />
    <div class="content p-4">
      <div class="row">
        <div class="col-lg-12">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 box-info">
                <h4>Försäkringar</h4>
                <ul>
                  <li>
                    {{
                      start.qBrev != null ? start.qBrev : ""
                    }}
                    försäkringsbrev.
                  </li>
                  <li>
                    {{
                      start.qFritid != null ? start.qFritid : ""
                    }}
                    fritidshusförsäkringar.
                  </li>
                  <li>
                    {{
                      start.qHem != null ? start.qHem : ""
                    }}
                    hemförsäkringar.
                  </li>
                  <li>
                    {{
                      start.qVilla != null ? start.qVilla : ""
                    }}
                    villaförsäkringar.
                  </li>
                  <li>
                    {{
                      start.qVillahem != null ? start.qVillahem : ""
                    }}
                    villahemförsäkringar.
                  </li>
                  <li>
                    {{
                      start.qGard != null ? start.qGard : ""
                    }}
                    gårdsförsäkringar.
                  </li>
                </ul>
                <h4>Tilläggsförsäkringar</h4>
                <ul>
                  <li>
                    {{ start.qAllriskp != null ? start.qAllriskp : "" }} allrisk
                    personlig lösegendom.
                  </li>
                  <li>
                    {{ start.qAllriskb != null ? start.qAllriskb : "" }} allrisk
                    byggnad.
                  </li>
                  <li>
                    {{ start.qPO != null ? start.qPO : "" }} privatolycksfall.
                  </li>
                  <li>
                    {{ start.qUtr != null ? start.qUtr : "" }} utökat reseskydd.
                  </li>
                  <li>
                    {{ start.sbas != null ? start.sbas : "" }} skogsbas.
                  </li>
                  <li>
                    {{ start.smer != null ? start.smer : "" }} skogsmer.
                  </li>
                  <li>
                    {{ start.smax != null ? start.smax : "" }} skogsmax.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import { mapState } from "vuex";

var moment = require("moment");
moment.locale("sv");

export default {
  name: "start",
  components: {
    Navbar,
  },
  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      hidden: (state) => state.sidebar.hidden,
      apiKey: (state) => state.auth.apiKey,
      accesslevel: (state) => state.auth.accesslevel,
    }),
  },

  data() {
    return {
      start: {},
      customer: null,
      date: moment().format("YYYY-MM-DD"),
    };
  },

  mounted() {
    this.getStart();
  },

  methods: {
    getStart() {
      this.$axios
        .get(this.baseUrl + "api/get.php?type=start&key=" + this.apiKey)
        .then((response) => {
          console.log(response);
          if (response.data.status == "ok") {
            this.start = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.red {
  background-color: #ffaaaa;
}
.green {
  background-color: lightgreen;
}
.white {
  background-color: white;
}
input {
  margin-bottom: 8px;
}
</style>