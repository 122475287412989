<template>
  <div class="profile insuranceList" v-if="insurance.length > 0">
    <div class="table-responsive content whitebox">
      <table class="table table-striped">
        <thead>
          <tr>
            <th style="border-top: none">Försäkring</th>
            <th style="border-top: none">Saldo</th>
            <th style="border-top: none">Fastighet</th>
            <th style="border-top: none">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(ins, index) in insurance" :key="index">
            <td>
              <a
                href="#"
                @click.prevent="showInsurance(ins.insuranceID, ins.insuranceNr)"
              >
                {{
                  ins.insuranceNr > 0
                    ? ins.insuranceNr
                    : "(" + ins.insuranceID + ")"
                }}</a
              >
            </td>
            <td>{{ ins.balance.toLocaleString("sv") }}</td>
            <td>{{ ins.property }}</td>
            <td>
              <a
                href="#"
                @click.prevent="editInsurance(ins.insuranceID, ins.insuranceNr)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#5A6064"
                  class="bi bi-pencil-square"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                  /></svg
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "insurance-list",
  props: ["customer", "insurance"],
  data() {
    return {
      message: null,
      submitStatus: null,
      user: null,
      error: null,
    };
  },
  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      hidden: (state) => state.sidebar.hidden,
      apiKey: (state) => state.auth.apiKey,
      accesslevel: (state) => state.auth.accesslevel,
    }),
  },
  validations: {
    message: { required },
  },

  methods: {
    showInsurance(insuranceID, insuranceNr) {
      this.$emit("update", [insuranceID, insuranceNr]);
    },
    editInsurance(insuranceID, insuranceNr) {
      this.$emit("editInsurance", [insuranceID, insuranceNr]);
    },
  },
};
</script>

<style lang="scss">
a {
  color: blue;
}
a:hover {
  text-decoration: underline;
}

.insuranceList {
  button {
    font-size: 15px;
    font-weight: 600;
    border-radius: 5px;
    border: 1px solid #dde3eb;
    margin: 4px;
    background-color: white;
  }
}
</style>