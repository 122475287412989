<template>
  <div class="customer-insurances whitebox">
    <table class="table table-striped" v-if="drafts.length > 0">
      <thead>
        <tr>
          <th scope="col">Offertnr</th>
          <th scope="col">Datum</th>
          <th scope="col">Status</th>
          <th scope="col">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(insurance, insuranceKey) in drafts" :key="insuranceKey">
          <th scope="row">
            <a href="#" @click.prevent="editDraft(insurance)">{{ insurance.insuranceID }}</a>
          </th>
          <td>{{ dateFormat(insurance.ts) }}</td>
          <td>
            <span
              class="badge badge-warning"
              v-if="insurance.draftStatus === 'draft'"
              >Utkast</span
            >
            <span
              class="badge badge-success"
              v-if="insurance.draftStatus === 'proposal'"
              >Offert</span
            >
          </td>
          <td class="actions">
            <span class="clone" @click="proposal(insurance)" v-if="insurance.draftStatus == 'draft'">
              <font-awesome-icon icon="clipboard-list" />
            </span>
            <!-- <span class="edit" @click="editDraft(insurance)" v-if="insurance.draftStatus == 'draft'">
              <font-awesome-icon icon="edit" />
            </span> -->
            <span class="approve" v-if="insurance.draftStatus == 'proposal'" @click="approve(insurance)">
              <font-awesome-icon icon="check-double" />
            </span>
            <!-- <span class="eye" v-if="insurance.draftStatus == 'proposal'" @click="show(insurance)">
              <font-awesome-icon icon="eye" />
            </span> -->
            <span class="delete" @click="deleteDraft(insurance)">
              <font-awesome-icon icon="trash-alt" />
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: ["insurances"],
  name: "customer-insurances",

  computed: {
    drafts() {
      return this.insurances.filter(
        (insurance) => insurance.draftStatus !== "approved"
      );
    },
  },

  methods: {
    dateFormat(date) {
      return moment(date).format("YYYY-MM-DD");
    },

    deleteDraft(insurance) {
      this.$emit('delete', insurance);
    },

    editDraft(insurance) {
      this.$emit('edit', insurance);
    },

    proposal(insurance) {
      this.$emit('proposal', insurance);
    },

    approve(insurance) {
      this.$emit('approve', insurance);
    },

    // show(insurance) {
    //   this.$emit('show', insurance);
    // }
  },
};
</script>

<style lang="scss" scoped>
.customer-insurances {
  margin-top: 24px;
  background: #fff;

  .table {
    .actions {
      text-align: right;
      span {
        margin-left: 12px;
        font-size: 15px;
        cursor: pointer;

        &:hover {
          color: #000;
        }
      }
      span.clone {
        color: #1A5276;
      }
      span.edit {
        color: #D4AC0D;
      }
      span.delete {
        color: #E74C3C;
      }
    }
  }
}
</style>