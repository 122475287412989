<template>
    <div>
        <input type="file" multiple name="fields[upload][]" id="upload">
    </div>
</template>

<script>
export default {
    name: "filedrag",
    props: {
        url: {
            required: true,
            type: String
        }
    }
}
</script>

<style lang="scss">
</style>