<template>
  <div class="insurances w-100">
    <navbar />
    <div class="content p-4 box-info">
      <div class="row">
        <div class="col-md-8">
          <h3>Nya försäkringar (utkast)</h3>
        </div>
        <div class="col-md-4 text-right">
          <div class="form-row">
            <div class="form-group col-md">
              <input
                type="checkbox"
                class="form-check-input"
                style="margin-top: 10px; font-weight: medium; zoom: 1.5"
                id="approved"
                v-model="approved"
              />
              <label
                class="form-check-label btn-cutom"
                for="approved"
                style="font-size: 1em; margin-top: 11px"
                ><h4>Visa godkända</h4></label
              >
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table
              class="table table-striped"
              v-if="filterdInsurances.length > 0"
            >
              <thead>
                <tr>
                  <th scope="col">Utkastnummer</th>
                  <th scope="col">Kund</th>
                  <th scope="col">Skapad av</th>
                  <th scope="col">Skapades</th>
                  <th scope="col">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(insurance, index) in filterdInsurances"
                  :key="index"
                >
                  <th scope="row">
                    <a
                      id="insurance-number"
                      href="#"
                      @click.prevent="
                        editInsurance(
                          insurance.insuranceID,
                          insurance.customerID
                        )
                      "
                      >{{ insurance.insuranceID }}</a
                    >
                  </th>
                  <td>
                    <router-link
                      id="customer-show"
                      :to="{
                        name: 'customer-show',
                        params: { id: insurance.customerID },
                      }"
                    >
                      {{
                        insurance.category == "co"
                          ? insurance.companyName
                          : insurance.firstName + " " + insurance.lastName
                      }}</router-link
                    >
                  </td>
                  <td>{{ insurance.realname }}</td>
                  <td>{{ insurance.ts }}</td>
                  <td class="approval">
                    <button
                      v-if="insurance.draftStatus == 'draft'"
                      type="button"
                      class="btn btn-warning btn-sm"
                      id="approv-draft"
                      @click="saveApproval(insurance)"
                    >
                      <font-awesome-icon icon="edit" /> Godkänn
                    </button>
                    <button
                      v-if="insurance.draftStatus == 'draft'"
                      type="button"
                      class="btn btn-danger btn-sm ml-2"
                      id="delete-draft"
                      @click="deleteDraftInsurance(insurance.insuranceID)"
                    >
                      <font-awesome-icon icon="trash-alt" /> Ta bort
                    </button>
                    <button
                      v-if="insurance.draftStatus == 'approved'"
                      type="button"
                      class="btn btn-success btn-sm"
                      disabled
                      id="approved"
                    >
                      <font-awesome-icon icon="check-double" /> Godkänd
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <b-modal
        content-class="customer-modal"
        id="modal-1"
        size="lg"
        :title="title"
        v-model="showModal"
      >
        <div class="form-group">
          <input
            autocomplete="off"
            type="text"
            class="form-control form-control-lg"
            placeholder="Sök kund..."
            v-model="keyword"
            v-focus
            @keyup="searchCustomer"
          />
        </div>
        <div class="form-group">
          <div class="table-responsive" v-if="customers.length > 0">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Namn</th>
                  <th scope="col">Adress</th>
                  <th scope="col">PostNr</th>
                  <th scope="col">Ort</th>
                  <th scope="col">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(customer, index) in customers" :key="index">
                  <td>
                    {{
                      customer.category == "co"
                        ? customer.companyName
                        : customer.firstName
                    }}
                    {{ customer.category == "co" ? "" : customer.lastName }}
                  </td>
                  <td>{{ customer.street }}</td>
                  <td>{{ customer.postcode }}</td>
                  <td>{{ customer.city }}</td>
                  <td>
                    <button
                      type="button"
                      class="btn btn-info btn-sm btnSmGr"
                      @click="createInsurance(customer.id)"
                    >
                      <font-awesome-icon icon="external-link-alt" /> Skapa
                      försäkring
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="alert alert-secondary"
            role="alert"
            v-if="customers.length == 0"
          >
            <p>Ingen kund hittades</p>
          </div>
        </div>
        <template v-slot:modal-footer="{ cancel }">
          <b-button variant="secondary" @click="cancel()">Avbryt</b-button>
          <!-- <b-button variant="success" @click="saveEvaluationForms()">Spara</b-button> -->
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Navbar from "@/components/Navbar.vue";
import moment from "moment";
export default {
  name: "insurances-draft",
  data() {
    return {
      showModal: false,
      title: "Välj kund",
      keyword: null,
      count: 25,
      insurances: [],
      approved: false,
    };
  },

  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      apiKey: (state) => state.auth.apiKey,
      customers: "customers",
    }),

    filterdInsurances() {
      if (this.approved) {
        return this.insurances;
      } else {
        return this.insurances.filter(
          (insurance) => insurance.draftStatus != "approved"
        );
      }
    },
  },

  watch: {
    insurances: function () {
      this.insurances.forEach(
        (insurance) => (insurance.approvalDate = moment().format("YYYY-MM-DD"))
      );
    },
  },

  components: {
    Navbar,
  },

  mounted() {
    this.getInsurances();
  },

  methods: {
    editInsurance(insuranceID, customerID) {
      this.$router
        .push({
          name: "create-insurance",
          query: { insuranceID: insuranceID, customerID: customerID },
        })
        .catch(() => {});
    },

    getInsurances() {
      this.$axios
        .get(
          this.baseUrl + "api/get.php?type=draftInsurance&key=" + this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.insurances = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    searchCustomer() {
      if (this.keyword != null) {
        var keyword = this.keyword.toLowerCase();
        this.$store.dispatch("client/fetchCustomers", {
          keyword: keyword,
          limit: this.count,
        });
      } else {
        this.$store.commit("client/FETCH_CUSTOMERS", []);
      }
    },

    createInsurance(customerID) {
      this.showModal = false;
      this.$axios
        .post(
          this.baseUrl +
            "api/draft.php?type=create&customerID=" +
            customerID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            console.log(response.data);
            let insuranceID = response.data.data.insuranceID;
            this.$router
              .push({
                name: "create-insurance",
                query: { insuranceID: insuranceID },
              })
              .catch(() => {});
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    saveApproval(insurance) {
      if (insurance.approvalDate != null) {
        this.$axios
          .get(
            this.baseUrl +
              "api/draft.php?type=confirm&insuranceID=" +
              insurance.insuranceID +
              "&date=" +
              insurance.approvalDate +
              "&key=" +
              this.apiKey
          )
          .then((response) => {
            if (response.data.status == "ok") {
              this.getInsurances();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$swal(
          "Meddelande",
          "Du måste ange datum för att godkänna försäkringen",
          "error"
        );
      }
    },

    // draft.php?type=delete&insuranceID=
    deleteDraftInsurance(insuranceID) {
      this.$axios
        .get(
          this.baseUrl +
            "api/draft.php?type=delete&insuranceID=" +
            insuranceID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.getInsurances();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.insurances {
  tr {
    th {
      font-size: 14px;
    }
    .approval {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      input {
        margin-right: 10px;
        max-width: 180px;
      }

      button {
        font-weight: 600;
        font-size: 12px;
        svg {
          font-size: 11px;
        }
      }
    }
    button {
      font-size: 14px;
      // font-weight: 600;
      letter-spacing: 1px;
    }
  }
  .customer-modal {
    background: black;
    color: white;
  }
}
.btnSmGr {
  color: #fff;
  background-color: #39796e;
  border-color: #39796e;
  border-color: none;
  border-radius: 9px;
}
</style>