<template>
  <div class="economy w-100">
    <navbar />
    <div class="content p-4">
      <div class="row">
        <div class="col-md-12 box-info">
          <div class="row">
            <b-form-group class="payment-form">
              <input type="number" v-model="year" class="form-control" />
            </b-form-group>
            <b-form-group class="payment-form">
              <datepicker
                format="yyyy-MM-dd"
                input-class="form-control"
                :bootstrap-styling="true"
                v-model="date"
                :monday-first="true"
                :language="sv"
                placeholder="Välj datum"
              ></datepicker>
            </b-form-group>
            <b-form-group class="payment-form">
              <input
                type="number"
                ref="am"
                placeholder="OCR"
                v-model="refnr"
                @keyup="checkOcr()"
                class="form-control"
                id="OCR"
                :class="{
                  red: wrongOcr && refnr != null && refnr.length > 0,
                  green: !wrongOcr && refnr != null && refnr.length > 0,
                  white: refnr == null || refnr.length == 0,
                }"
              />
            </b-form-group>
            <b-form-group class="payment-form">
              <input
                type="number"
                placeholder="Belopp"
                v-model="amount"
                class="form-control"
                id="amount"
                @keydown.13="checkout"
                @keyup="checkAmount()"
                :class="{
                  red: wrongAmount && amount != null && amount.length > 0,
                  green: !wrongAmount && amount != null && amount.length > 0,
                  white: amount == null || amount.length == 0,
                }"
              />
            </b-form-group>
            <b-button
              id="save"
              class="payment-form"
              style="max-height: 37px"
              @click="save()"
              :disabled="wrongOcr || !customer"
            >
              {{ buttonText }}
            </b-button>
          </div>

          <div class="col-md-10">
            <p>
              Kund:
              {{
                searching
                  ? "Söker..."
                  : customer == null
                  ? "Ange giltigt OCR-nummer."
                  : customer.category == "pp"
                  ? customer.firstName + " " + customer.lastName
                  : customer.companyName
              }}{{
                customer == null
                  ? ""
                  : ", " +
                    customer.street +
                    ", " +
                    customer.postcode +
                    " " +
                    customer.city +
                    ". (Kundnummer: " +
                    customer.customerNr +
                    ")"
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="row" style="margin-top: 24px">
        <div class="col-md-12 box-info">
          <h3>Senaste inbetalningarna</h3>
          <table
            class="table table-striped"
            v-if="payments != null && payments.length > 0"
          >
            <thead>
              <tr>
                <th>Försäkringsnr</th>
                <th>Kund</th>
                <th>Inbetalt</th>
                <th>Saldo</th>
              </tr>
            </thead>
            <tbody>
              <tr id="payment" v-for="(p, index) in payments" :key="index">
                <td>{{ p.insuranceNr }}</td>
                <td>
                  {{
                    p.category == "pp"
                      ? p.firstName + " " + p.lastName
                      : p.companyName
                  }}
                </td>
                <td style="text-align: right">
                  {{ p.amount.toLocaleString("sv") }}
                </td>
                <td style="text-align: right">{{ p.balance }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import { mapState } from "vuex";
import Datepicker from "vuejs-datepicker";
import { sv } from "vuejs-datepicker/dist/locale";
var moment = require("moment");
moment.locale("sv");

export default {
  name: "economy-payment",
  components: {
    Navbar,
    Datepicker,
  },
  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      hidden: (state) => state.sidebar.hidden,
      apiKey: (state) => state.auth.apiKey,
      accesslevel: (state) => state.auth.accesslevel,
    }),
  },

  data() {
    return {
      customer: null,
      amount: null,
      wrongAmount: true,
      wrongOcr: true,
      year: moment().add(31, "days").format("YYYY"),
      refnr: "",
      date: moment().format("YYYY-MM-DD"),
      payments: [],
      searching: false,
      buttonText: "Spara",
      sv: sv,
    };
  },

  mounted() {
    this.setFocus();
    this.getPayments();
  },

  methods: {
    setFocus() {
      this.$refs.am.focus();
    },
    getPayments() {
      this.$axios
        .get(
          this.baseUrl +
            "api/get.php?type=latestPayments" +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.payments = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getInsurance(insuranceNr) {
      this.searching = true;
      this.$axios
        .get(
          this.baseUrl +
            "api/get.php?type=customer&insuranceNr=" +
            insuranceNr +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.customer = response.data.data;
            this.searching = false;
          } else {
            this.wrongOcr = true;
          }
        })
        .catch((error) => {
          this.searching = false;
          this.wrongOcr = true;
          console.log(error);
        });
    },
    checkOcr() {
      var n = this.refnr;
      this.customer = null;
      this.wrongOcr = true;
      if (n.length > 2) {
        var lenDigit = n.substring(n.length - 2, n.length - 1);
        if (lenDigit == n.length) {
          var checkDigit = n.substring(n.length - 1, n.length);
          if (this.checksum(n.substring(0, n.length - 1)) == checkDigit) {
            this.getInsurance(n.substring(0, n.length - 2));
            this.wrongOcr = false;
          }
        }
      }
    },
    save() {
      if (this.customer && this.refnr) {
        this.buttonText = "Sparar...";
        this.$axios
          .get(
            this.baseUrl +
              "api/edit.php?type=pay&insuranceID=" +
              this.customer.insuranceID +
              "&amount=" +
              this.amount +
              "&year=" +
              this.year +
              "&date=" +
              moment(this.date).format("YYYY-MM-DD") +
              "&key=" +
              this.apiKey
          )
          .then((response) => {
            if (response.data.status == "ok") {
              this.buttonText = "Spara";
              this.amount = null;
              this.customer = null;
              this.refnr = "";
              this.getPayments();
              this.setFocus();
            } else {
              this.buttonText = "Spara";
            }
          })
          .catch((error) => {
            this.searching = false;
            this.wrongOcr = true;
            console.log(error);
          });
      }
    },
    checkout() {
      if (!this.wrongAmount) {
        this.save();
      }
    },
    checkAmount() {
      if (parseInt(this.amount) + this.customer.balance == 0) {
        this.wrongAmount = false;
      } else {
        this.wrongAmount = true;
      }
    },
    checksum(n) {
      var sum = 0,
        p;
      for (var i = 0; i < n.length; i++) {
        if (i % 2 == 1) {
          p = n.substring(i, i + 1);
        } else {
          p = parseInt(n.substring(i, i + 1) * 2);
          if (p > 9) {
            p = this.digitSum(p);
          }
        }
        sum += parseInt(p);
      }
      return (10 - (sum % 10)) % 10;
    },
    digitSum(n) {
      var sum = 0;
      n = n.toString();
      for (var i = 0; i < n.length; i++) {
        var p = parseInt(n.substring(i, i + 1));
        sum += p;
      }
      return sum;
    },
  },
};
</script>

<style scoped>
.red {
  background-color: #ffaaaa;
}
.green {
  background-color: lightgreen;
}
.white {
  background-color: white;
}
input {
  margin-bottom: 8px;
}
.payment-form {
  margin: 10px;
}
</style>
