var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"staff w-100"},[_c('navbar'),_c('div',{staticClass:"content p-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8 box-info"},[_c('h3',[_vm._v("Inställningar")]),_c('h6',[_vm._v("Byt lösenord")]),(_vm.submitStatus == 'OK' && _vm.successMessage != null)?_c('div',{staticClass:"alert alert-success",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.successMessage)+" ")]):_vm._e(),(_vm.submitStatus == 'ERROR' && _vm.errorMessage != null)?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.password.$model),expression:"$v.password.$model"}],staticClass:"form-control",class:{
              'is-invalid': _vm.submitStatus == 'ERROR' && !_vm.$v.password.required,
            },attrs:{"type":"password","id":"password","placeholder":"Nuvarande lösenord"},domProps:{"value":(_vm.$v.password.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.password, "$model", $event.target.value)}}}),(!_vm.$v.password.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Ange nuvarande lösenord ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.newPassword.$model),expression:"$v.newPassword.$model"}],staticClass:"form-control",class:{
              'is-invalid':
                _vm.submitStatus == 'ERROR' && !_vm.$v.newPassword.required,
            },attrs:{"type":"password","id":"new-password","placeholder":"Nytt lösenord"},domProps:{"value":(_vm.$v.newPassword.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.newPassword, "$model", $event.target.value)}}}),(!_vm.$v.newPassword.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Ange nytt lösenord ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.confirmPassword.$model),expression:"$v.confirmPassword.$model"}],staticClass:"form-control",class:{
              'is-invalid':
                _vm.submitStatus == 'ERROR' && !_vm.$v.confirmPassword.required,
            },attrs:{"type":"password","id":"confirm-password","placeholder":"Bekräfta nytt lösenord"},domProps:{"value":(_vm.$v.confirmPassword.$model)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==13){ return null; }return _vm.changePassword.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.confirmPassword, "$model", $event.target.value)}}}),(!_vm.$v.confirmPassword.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Bekräfta lösenord ")]):_vm._e()]),_c('button',{staticClass:"btn btn-secondary btn-lg btn-block btnSmGr",attrs:{"type":"button"},on:{"click":_vm.changePassword}},[_vm._v(" Spara ")])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }