<template>
  <div
    class="row divborder divbg align-items-center"
    v-if="damage != null"
    style="
      margin-top: -10px;
      margin-left: -25px;
      margin-bottom: 8px;
      margin-right: -25px;
      padding: 8px;
      border-top: 1px solid #cbcbcb;
      border-bottom: 1px solid #cbcbcb;
      border-radius: none;
      background-color: #e1ebea;
    "
  >
    <div class="box">
      Skadenummer: {{ damage.damageNr }}<br />
      Händelsedatum: {{ damage.damageDate }}
    </div>
    <div class="box">
      Kategori: {{ damage.category }}<br />
      Avslutat: {{ damage.endDate == null ? "--" : damage.endDate }}
    </div>
    <div class="box">
      Rapporterat av: {{ damage.realname }}<br />
      Rapportdatum: {{ damage.reportDate }}
    </div>
    <div class="box">
      Godkänd skadeanmälan: {{ damage.damageReport == "y" ? "Ja" : "Nej"
      }}<br />
      Fastighet: {{ damage.fastighet }}
    </div>
    <div class="box">
      <button type="button" class="btn btn-light" @click="$emit('edit')">
        Redigera
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "damage-header",
  props: ["damage"],
  data() {
    return {
      // client: null,
      isOpen: false,
    };
  },
  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      hidden: (state) => state.sidebar.hidden,
      apiKey: (state) => state.auth.apiKey,
      accesslevel: (state) => state.auth.accesslevel,
    }),
  },
};
</script>

<style lang="scss">
.divborder {
  border-radius: 5px;
  //border: 1px solid #dde3eb;
}
.divbg {
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.toolbar {
  img {
    margin: -5px;
    border-radius: 25px;
  }
  button {
    font-size: 15px;
    font-weight: 600;
    border-radius: 5px;
    //border: 1px solid #dde3eb;
    margin: 4px;
    background-color: white;
  }
}
</style>