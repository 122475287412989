// import axios from "../../axios";

const state = {
    visible: true,
    hidden: false
}

const mutations = {
    SIDEBAR_VISIBLE(state, payload) {
        state.visible = payload
    },

    HIDE_SIDEBAR(state, payload) {
        state.hidden = payload
    },
}

const actions = {
    sidebarToggle({ commit }, status) {
        commit('SIDEBAR_VISIBLE', status)
    },

    hideSidebar({ commit }, data) {
        commit('HIDE_SIDEBAR', data)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}