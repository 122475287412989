import axios from 'axios';

const actions = {
    // auth.store.js
    // initialiseStore({
    //     state,
    //     commit,
    //     dispatch
    // }) {
    //     // commit('STANDARDS', standards)
    //     if (state.apiKey) {
    //         axios.get(state.baseUrl + 'api/validate.php?key=' + state.apiKey)
    //             .then((response) => {
    //                 if (response.data.status == 'ok') {
    //                     state.authenticated = true
    //                     dispatch('fetchEvaluationTypes')
    //                 } else {
    //                     commit('AUTH_LOGOUT')
    //                 }
    //             })
    //     } else {
    //         commit('AUTH_LOGOUT')
    //     }
    // },

    // sidebar.store.js
    // sidebarToggle({ commit }, status) {
    //     commit('SIDEBAR_VISIBLE', status)
    // },

    // sidebar.store.js
    // hideSidebar({
    //     commit
    // }, data) {
    //     commit('HIDE_SIDEBAR', data)
    // },

    // auth.store.js
    // validateAuth({
    //     commit,
    //     state
    // }) {
    //     return new Promise((resolve, reject) => {
    //         axios.post(state.baseUrl + 'api/validate.php')
    //             .then((response) => {
    //                 if (response.data.status == 'ok') {
    //                     // commit('AUTH_LOGIN', response.data)
    //                     resolve(response)
    //                 } else {
    //                     commit('ERRORS', response.data.error)
    //                 }
    //             })
    //             .catch((error) => {
    //                 commit('AUTH_LOGOUT')
    //                 reject(error)
    //             })
    //     })
    // },

    // auth.store.js
    // login({
    //     commit,
    //     state
    // }, formData) {
    //     return new Promise((resolve, reject) => {
    //         axios.post(state.baseUrl + 'api/auth.php', formData)
    //             .then((response) => {
    //                 if (response.data.status == 'ok') {
    //                     commit('AUTH_LOGIN', response.data)
    //                     commit('ERRORS', null)
    //                     resolve(response)
    //                 } else {
    //                     commit('ERRORS', response.data.error)
    //                 }
    //             })
    //             .catch((error) => {
    //                 commit('AUTH_LOGOUT')
    //                 reject(error)
    //             })
    //     })
    // },

    // auth.store.js
    // logout({
    //     commit,
    //     state
    // }, formData) {
    //     return new Promise((resolve, reject) => {
    //         axios.post(state.baseUrl + 'api/logout.php', formData)
    //             .then((response) => {
    //                 commit('AUTH_LOGOUT')
    //                 resolve(response)
    //             })
    //             .catch((error) => {
    //                 commit('AUTH_LOGOUT')
    //                 reject(error)
    //             })
    //     })
    // },

    getCategory({
        commit,
        state
    }, categoryID) {
        var category = state.categories.find((category) => category.id == categoryID)
        commit('CATEGORY', category)
    },

    standard({
        commit,
        state
    }, standardID) {
        var standard = state.standards.find((standard) => standard.id == standardID)
        commit('STANDARD', standard)
    },

    fetchNotes({
        state,
        commit,
        dispatch
    }, data) {
        return new Promise((resolve, reject) => {
            axios.get(state.baseUrl + 'api/get.php?type=notes&search=' + encodeURIComponent(data.keyword) + '&user=' + encodeURIComponent(data.user) + '&note=' + encodeURIComponent(data.note) + '&limit=' + encodeURIComponent(data.limit) + '&key=' + encodeURIComponent(state.auth.apiKey))
                .then(response => {
                    if (response.data.status == 'ok') {
                        commit('FETCH_NOTES', response.data.data)
                        resolve(response)
                    } else {
                        dispatch('logout')
                        reject(response.data.error)
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    // evaluation.store.js
    // fetchEvaluationTypes({
    //     state,
    //     commit
    // }) {
    //     return new Promise((resolve, reject) => {
    //         axios.get(state.baseUrl + 'api/get.php?type=evaluationTypes&key=' + state.apiKey)
    //             .then((response) => {
    //                 if (response.data.status == 'ok') {
    //                     commit('FETCH_EVALUATIONTYPES', response.data.data)
    //                     resolve(response)
    //                 } else {
    //                     commit('ERRORS', response.data.error)
    //                     reject(response.data.error)
    //                 }
    //             })
    //             .catch((error) => {
    //                 console.log(error)
    //             })
    //     })
    // },

    // product.store.js
    // fetchCategories({
    //     state,
    //     commit
    // }) {
    //     return new Promise((resolve, reject) => {
    //         axios.get(state.baseUrl + 'api/getCategories.php?key=' + state.apiKey)
    //             .then((response) => {
    //                 if (response.data.status == 'ok') {
    //                     commit('FETCH_CATEGORIES', response.data.data)
    //                     resolve(response.data)
    //                 }
    //             })
    //             .catch((error) => {
    //                 commit('ERRORS', error)
    //                 reject(error)
    //             })
    //     })
    // },

    // product.store.js
    // fetchProducts({
    //     state,
    //     commit
    // }) {
    //     return new Promise((resolve, reject) => {
    //         axios.get(state.baseUrl + 'api/get.php?type=products&key=' + state.auth.apiKey)
    //             .then((response) => {
    //                 var products = []
    //                 if (response.data.data.length > 0) {
    //                     response.data.data.map((data) => {
    //                         data.selected = false
    //                         data.products = []
    //                         products.push(data)
    //                     })
    //                 }
    //                 commit('FETCH_PRODUCTS', response.data.data)
    //                 resolve(response.data)
    //             })
    //             .catch((error) => {
    //                 commit('ERRORS', error)
    //                 reject(error)
    //             })
    //     })
    // },

    // guideline.store.js
    fetchGuideLines({
        state,
        commit
    }, productID) {
        return new Promise((resolve, reject) => {
            axios.get(state.baseUrl + 'api/get.php?type=guidelines&productID=' + productID + '&key=' + state.auth.apiKey)
                .then((response) => {
                    if (response.data.status == 'ok') {
                        commit('FETCH_GUIDELINES', response.data)
                        resolve(response.data)
                    }
                })
                .catch((error) => {
                    commit('ERRORS', error)
                    reject(error)
                })
        })
    },

    // product.store.js
    // fetchSelectedProducts({
    //     state,
    //     commit
    // }, insuranceID) {
    //     return new Promise((resolve, reject) => {
    //         axios.get(state.baseUrl + 'api/get.php?type=draftProducts&insuranceID=' + encodeURIComponent(insuranceID) + '&key=' + encodeURIComponent(state.auth.apiKey))
    //             .then((response) => {
    //                 if (response.data.status == 'ok') {
    //                     commit('FETCH_SELECTED_PRODUCTS', response.data.data)
    //                     resolve(response.data.data)
    //                 }
    //             })
    //             .catch((error) => {
    //                 commit('ERRORS', error)
    //                 reject(error)
    //             })
    //     })
    // },

    // product.store.js
    // fetchSubProducts({
    //     state,
    //     commit
    // }) {
    //     return new Promise((resolve, reject) => {
    //         axios.get(state.baseUrl + 'api/get.php?type=subProducts&key=' + encodeURIComponent(state.apiKey))
    //             .then((response) => {
    //                 if (response.data.status == 'ok') {
    //                     commit('FETCH_SUBPRODUCTS', response.data.data)
    //                     resolve(response.data.data)
    //                 }
    //             })
    //             .catch((error) => {
    //                 commit('ERRORS', error)
    //                 reject(error)
    //             })
    //     })
    // },

    fetchInsuranceInfo({
        state,
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            axios.get(state.baseUrl + 'api/get.php?type=insuranceInfo&insuranceID=' + encodeURIComponent(data.insuranceID) + '&date=' + encodeURIComponent(data.selectedDate) + '&key=' + encodeURIComponent(state.auth.apiKey))
                .then((response) => {
                    if (response.data.status == 'ok') {
                        commit('FETCH_INSURANCE_INFO', response.data)
                        resolve(response.data)
                    }
                })
                .catch((error) => {
                    commit('ERRORS', error)
                    reject(error)
                })
        })
    },

    fetchAllObjects({
        state,
        commit
    }, insuranceID) {
        return new Promise((resolve, reject) => {
            axios.get(state.baseUrl + 'api/get.php?type=draftObjects&insuranceID=' + encodeURIComponent(insuranceID) + '&key=' + encodeURIComponent(state.auth.apiKey))
                .then((response) => {
                    if (response.data.status == 'ok') {
                        commit('FETCH_ALL_OBJECTS', response.data.data)
                        resolve(response.data.data)
                    }
                })
                .catch((error) => {
                    commit('ERRORS', error)
                    reject(error)
                })
        })
    },

    fetchTerms({
        state,
        commit
    }) {
        return new Promise((resolve, reject) => {
            axios.get(state.baseUrl + 'api/get.php?type=draftTerms&key=' + encodeURIComponent(state.auth.apiKey))
                .then((response) => {
                    if (response.data.status == 'ok') {
                        commit('FETCH_TERMS', response.data.data)
                        resolve(response.data.data)
                    }
                })
                .catch((error) => {
                    commit('ERRORS', error)
                    reject(error)
                })
        })
    },

    // guideline.store.js
    fetchInsuranceGuidelines({
        state,
        commit
    }, insuranceID) {
        return new Promise((resolve, reject) => {
            axios.get(state.baseUrl + 'api/get.php?type=guidelines&insuranceID=' + encodeURIComponent(insuranceID) + '&key=' + encodeURIComponent(state.auth.apiKey))
                .then((response) => {
                    if (response.data.status == 'ok') {
                        commit('FETCH_INSURANCE_GUIDELINES', response.data.data)
                        commit('GUIDELINE_DATE', response.data.guidelineDate)
                        commit('FILTER_GUIDELINES')
                        resolve(response.data.data)
                    }
                })
                .catch((error) => {
                    commit('ERRORS', error)
                    reject(error)
                })
        })
    },

    // guideline.store.js
    fetchExtraGuidelines({
        state,
        commit
    }, insuranceID) {
        return new Promise((resolve, reject) => {
            axios.get(state.baseUrl + 'api/get.php?type=extraGuidelines&insuranceID=' + insuranceID + '&key=' + state.auth.apiKey)
                .then((response) => {
                    if (response.data.status == 'ok') {
                        commit('FETCH_EXTRA_GUIDELINES', response.data.data)
                        resolve(response.data.data)
                    }
                })
                .catch((error) => {
                    commit('ERRORS', error)
                    reject(error)
                })
        })
    }
}

export default actions;