<template>
  <div class="customer-show w-100 fourth">
    <navbar headertext="Kunder" />
    <div class="content p-4">
      <CustomerHeader
        v-if="customer != null"
        :customer="customer"
        :insuranceNr="selectedInsurance"
        :editing="editingInsurance"
        @editInsurance="editInsurance()"
        @toggleCustomer="toggleCustomer($event)"
        @note="toggleNote($event)"
        :visible="enableNote"
        @close="editingInsurance = false"
        @invoice="showInvoice"
        @sjalvrisk="showSjalvrisk"
        @letter="showLetter"
        @discount="showDiscount"
      />
      <div class="row" v-if="customer != null && !loading">
        <div class="col-md-4">
          <div
            class="alert alert-danger"
            v-if="!customer.mobilePhone && customer.email"
          >
            Mobilnummer saknas!
          </div>
          <div
            class="alert alert-danger"
            v-if="customer.mobilePhone && !customer.email"
          >
            E-mail saknas!
          </div>
          <div
            class="alert alert-danger"
            v-if="!customer.mobilePhone && !customer.email"
          >
            Mobilnummer och e-mail saknas!
          </div>
          <NewNote
            v-if="customer != null && enableNote && users.length > 0"
            @update="fetchCustomer()"
            :enableCustomer="false"
            @close="enableNote = !enableNote"
            :customer="customer"
            :users="users"
            class="px-3"
          />
          <br />
          <Notes
            v-if="customer != null && enableNote && messages != null"
            @update="fetchCustomer()"
            :customer="customer"
            :messages="messages"
          />
        </div>
        <div class="col-md-8" v-if="editingInsurance">
          <discount
            v-if="visibleDiscount"
            @close="visibleDiscount = false"
            :date="selectedDate"
            @save="saveDiscount"
            :discounts="discounts"
            :resetkey="resetkey"
          />
          <letter-text
            v-if="visibleLetter"
            @close="visibleLetter = false"
            :date="selectedDate"
            @save="saveLetter"
            :letters="brevtexts"
            :resetkey="resetkey"
          />
          <Invoice
            v-if="printable"
            @onClick="printInvoicePDF"
            @close="hideInvoice()"
          />
          <Sjalvrisk
            v-if="showCreate"
            @onClick="saveSr"
            :message="srAlertMessage"
            :large="true"
            @toggleVisibility="showSjalvrisk"
          />
          <NewNote
            v-if="customer != null && users.length > 0 && enableCustomer"
            @update="closeCustomerNote()"
            :enableCustomer="true"
            :customer="customer"
            :users="users"
          />
          <EditInsurance
            v-if="editingInsurance"
            :insuranceNr="selectedInsurance"
            :insuranceID="selectedInsuranceID"
            @close="closeEditInsurance()"
            @delete="deleteInsuranceInfo($event)"
            @selected="selectedPicker"
          />
        </div>
        <div class="col-md-4" v-else>
          <InsuranceList
            v-if="insurance != null && customer != null"
            @update="showInsurance($event)"
            @editInsurance="openEditInsurance($event)"
            :insurance="insurance"
            :customer="customer"
          />
          <FileUpload
            :docs="docs"
            :uploadUrl="
              'api/uploadCustomer.php?customerID=' + customer.id + '&'
            "
            @update="fetchFiles()"
          />

          <damages
            :damages="damages"
            @navigate="navigateDamage"
            @show="showDamage"
          />

          <customer-insurances
            :insurances="customerInsurances"
            @delete="deleteDraftInsurance"
            @edit="editDraftInsurance"
            @proposal="proposalInsurance"
            @approve="approveDraft"
          />
        </div>
        <div class="col-md-4" v-if="!editingInsurance">
          <discount
            v-if="visibleDiscount"
            @close="visibleDiscount = false"
            :date="selectedDate"
            @save="saveDiscount"
            :discounts="discounts"
            :resetkey="resetkey"
          />
          <letter-text
            v-if="visibleLetter"
            @close="visibleLetter = false"
            :date="selectedDate"
            @save="saveLetter"
            :letters="brevtexts"
            :resetkey="resetkey"
          />
          <Invoice
            v-if="printable"
            @onClick="printInvoicePDF"
            @close="hideInvoice()"
          />
          <Sjalvrisk
            v-if="showCreate"
            @onClick="saveSr"
            :message="srAlertMessage"
            :large="false"
            @toggleVisibility="showSjalvrisk"
          />
          <NewNote
            v-if="customer != null && users.length > 0 && enableCustomer"
            @update="closeCustomerNote()"
            :enableCustomer="true"
            :customer="customer"
            :users="users"
          />
          <Insurance
            v-if="selectedInsuranceID != null"
            :insuranceID="selectedInsuranceID"
            :customerID="customer.id"
            :key="updateKey"
            ref="insurance"
          />
          <div v-else>
            <b-alert show variant="primary" v-if="insurance.length > 0"
              >Välj en försäkring.</b-alert
            >
            <b-alert show variant="primary" v-else>
              Ingen försäkring hittades.
              <button
                type="button"
                class="btn btn-link"
                @click="createInsurance()"
              >
                Skapa ny försäkring
              </button>
            </b-alert>
          </div>
        </div>
      </div>
      <pulse-loader :loading="loading"></pulse-loader>
    </div>
  </div>
</template>

<script>
import { parsePostcode, parsePhone } from "@/_helpers/parser";
import Navbar from "@/components/Navbar.vue";
import { mapState } from "vuex";
import Insurance from "@/components/Insurance.vue";
import CustomerHeader from "@/components/CustomerHeader.vue";
import Notes from "@/components/Notes.vue";
import NewNote from "@/components/NewNote.vue";
import EditInsurance from "@/components/EditInsurance.vue";
import FileUpload from "@/components/FileUpload.vue";
import InsuranceList from "@/components/InsuranceList.vue";
import CustomerInsurances from "@/components/CustomerInsurances.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import moment from "moment";
import Damages from "@/components/Damages.vue";
import Invoice from "@/components/Invoice.vue";
import Sjalvrisk from "@/components/Sjalvrisk.vue";
import LetterText from "@/components/LetterText.vue";
import Discount from "@/components/Discount.vue";

export default {
  name: "customer-show",
  watch: {
    $route(newVal) {
      this.showModal = newVal.meta && newVal.meta.showModal;
    },
  },
  data() {
    return {
      showModal: false,
      loading: false,
      customer: null,
      enableCustomer: false,
      enableNote: true,
      insurance: null,
      selectedInsurance: null,
      selectedInsuranceID: null,
      messages: [],
      users: [],
      chKey: 0,
      insuranceNr: "",
      property: "",
      editingInsurance: false,
      updateKey: 0,
      damages: [],
      extensions: ["png", "jpg", "jpeg", "pdf"],
      dropzone: false,
      dragging: false,
      uploading: false,
      files: [],
      alertMassage: null,
      errorMessage: null,
      docs: [],
      customerInsurances: [],
      customerID: null,
      // startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY") + "-12-31",
      printable: false,
      srDate: moment().format("YYYY-MM-DD"),
      srSaved: null,
      showCreate: false,
      srAlertMessage: null,
      visibleLetter: false,
      visibleDiscount: false,
      selectedDate: null,
      resetkey: 0,
    };
  },

  components: {
    Navbar,
    CustomerHeader,
    PulseLoader,
    Insurance,
    Notes,
    InsuranceList,
    NewNote,
    EditInsurance,
    FileUpload,
    CustomerInsurances,
    Damages,
    Invoice,
    Sjalvrisk,
    LetterText,
    Discount,
  },

  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      hidden: (state) => state.sidebar.hidden,
      apiKey: (state) => state.auth.apiKey,
      accesslevel: (state) => state.auth.accesslevel,
      brevtexts: (state) => state.client.brevtexts,
      discounts: (state) => state.client.discounts,
    }),

    srText: {
      get() {
        return this.srSaved == null ? this.sr : this.srSaved;
      },
      set(newValue) {
        this.srSaved = newValue;
      },
    },
  },

  mounted() {
    this.customerID = this.$route.params.id;
    this.fetchUsers();
    this.fetchCustomer();
    this.fetchFiles();
    this.fetchCustomerInsurances();

    this.checkUrl();
  },

  beforeRouteLeave(to, from, next) {
    if (this.message != null && this.message.length > 0) {
      const answer = window.confirm(
        "Du har inte sparat anteckningen. Vill du radera den?"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },

  methods: {
    checkUrl() {
      const keyword = this.$route.query.keyword;
      if (keyword) {
        this.selectedInsuranceID = parseInt(keyword);
        this.$store.dispatch("client/fetchCustomer", {
          customerID: this.customerID,
          insuranceID: this.selectedInsuranceID,
        });
      }
    },

    selectLoadInsurance() {
      const keyword = this.$route.query.keyword;
      if (this.insurance && this.insurance.length == 1 && !keyword) {
        const insurance = this.insurance[0];
        this.selectedInsuranceID = insurance.insuranceNr;
        this.selectedInsurance = insurance.insuranceNr;
        this.$store.dispatch("client/fetchCustomer", {
          customerID: this.customerID,
          insuranceID: this.selectedInsuranceID,
        });
      }
    },

    fetchUsers() {
      this.$axios
        .get(this.baseUrl + "api/get.php?type=users&key=" + this.apiKey)
        .then((response) => {
          if (response.data.status == "ok") {
            this.users = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    custMsg(val) {
      if (!val) {
        this.customerMsg = null;
      }
    },

    showInsurance(insuranceData) {
      this.selectedInsuranceID = insuranceData[0];
      this.selectedInsurance = insuranceData[1];
      // this.updateKey++;

      this.$store.dispatch("client/fetchCustomer", {
        customerID: this.customerID,
        insuranceID: this.selectedInsuranceID,
      });
    },
    openEditInsurance(insuranceData) {
      this.selectedInsuranceID = insuranceData[0];
      this.selectedInsurance = insuranceData[1];
      this.updateKey++;
      this.editInsurance();
    },

    toggleCustomer(value) {
      this.enableCustomer = value;
      this.printable = false;
      this.showCreate = false;
    },

    toggleNote(value) {
      this.enableNote = value;
      this.printable = false;
      this.showCreate = false;
    },

    closeCustomerNote() {
      this.enableCustomer = false;
      this.fetchCustomer();
    },

    editInsurance() {
      this.srAlertMessage = null;
      this.printable = false;
      this.showCreate = false;
      this.editingInsurance = true;
      this.chKey++;
    },

    closeEditInsurance() {
      this.srAlertMessage = null;
      this.printable = false;
      this.showCreate = false;
      this.editingInsurance = false;
    },

    fetchCustomer() {
      this.loading = true;
      let id = this.$route.params.id;
      this.$axios
        .get(
          this.baseUrl + "api/getCustomer.php?id=" + id + "&key=" + this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.customer = response.data.customer;
            this.insurance = response.data.insurance;
            this.messages = [];
            this.selectLoadInsurance();
            if (this.insurance.length == 1) {
              this.selectedInsurance = this.insurance[0].insuranceNr;
              this.selectedInsuranceID = this.insurance[0].insuranceID;
            } else if (this.$route.query.keyword != null) {
              let kw = this.$route.query.keyword;
              console.log(kw);
              console.log(kw.length == 6);
              console.log(!isNaN(kw));
              if (kw.length == 6 && !isNaN(kw)) {
                console.log("Here!");
                this.selectedInsurance = parseInt(kw);
                for (var j = 0; j < this.insurance.length; j++) {
                  if (this.insurance[j].insuranceNr == this.selectedInsurance) {
                    this.selectedInsuranceID = this.insurance[j].insuranceID;
                  }
                }
              }
            }
            for (var i = 0; i < response.data.notes.length; i++) {
              this.messages.push({
                note: response.data.notes[i].note,
                ts: response.data.notes[i].ts,
                realname: response.data.notes[i].realname,
                status: response.data.notes[i].status,
                assignee: response.data.notes[i].assignee,
                alertID: response.data.notes[i].alertID,
                customerMsg: response.data.notes[i].customerMsg,
              });
            }
            this.loading = false;
          } else {
            this.$router.push({ name: "login" });
          }
        });
      this.$axios
        .get(
          this.baseUrl +
            "api/get.php?type=customerDamages&id=" +
            id +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.damages = response.data.data;
          }
        });
    },

    saveNote(message) {
      var postdata = new FormData();
      postdata.append("id", this.$route.params.id);
      postdata.append("note", message);
      this.$axios
        .post(this.baseUrl + "api/saveNote.php?key=" + this.apiKey, postdata)
        .then((response) => {
          if (response.data.status == "ok") {
            console.log(response);
          }
        });
    },

    updateCustomer() {
      console.log(this.customer);
    },

    nicePostcode(pc) {
      return parsePostcode(pc);
    },

    nicePhone(tfn) {
      return parsePhone(tfn);
    },

    fetchFiles() {
      this.loading = true;
      this.docs = [];
      this.$axios
        .get(
          this.baseUrl +
            "api/get.php?type=customerFiles&customerID=" +
            this.customerID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.docs = response.data.data;
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // edit.php?type=deleteInsurance&insuranceID=
    // Går inte att ta bort insurance för att insuranceNr !== insuranceID
    // (insuranceNr skickat som props istället insuranceID), behöver fixa
    deleteInsuranceInfo(insuranceID) {
      // console.log("Insurance info deleted... " + insuranceID);
      this.$axios
        .get(
          this.baseUrl +
            "api/edit.php?type=deleteInsurance&insuranceID=" +
            insuranceID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.fetchCustomer();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    selectedPicker(e) {
      console.log(e);
      this.selectedDate = moment(e).format("YYYY-MM-DD");
    },

    // get.php?type=drafts&customerID=
    fetchCustomerInsurances() {
      this.$axios
        .get(
          this.baseUrl +
            "api/get.php?type=drafts&customerID=" +
            this.customerID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          // console.log(response);
          if (response.data.status == "ok") {
            this.customerInsurances = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // draft.php?type=delete&insuranceID=
    deleteDraftInsurance(insurance) {
      this.$axios
        .get(
          this.baseUrl +
            "api/draft.php?type=delete&insuranceID=" +
            insurance.insuranceID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.fetchCustomerInsurances();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // /create-insurance?insuranceID=
    editDraftInsurance(insurance) {
      let status = insurance.draftStatus == "proposal" ? false : true;
      this.$store.commit("EDITABLE_MODE", status);
      this.$router
        .push({
          name: "create-insurance",
          query: {
            insuranceID: insurance.insuranceID,
            customerID: this.customerID,
          },
        })
        .catch(() => {});
    },

    // draft.php?type=proposal&insuranceID=
    proposalInsurance(insurance) {
      this.$axios
        .get(
          this.baseUrl +
            "api/draft.php?type=proposal&insuranceID=" +
            insurance.insuranceID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.fetchCustomerInsurances();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // draft.php?type=confirm&insuranceID=
    approveDraft(insurance) {
      this.$axios
        .get(
          this.baseUrl +
            "api/draft.php?type=confirm&insuranceID=" +
            insurance.insuranceID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.fetchCustomerInsurances();
            this.fetchCustomer();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    showInvoice() {
      // if (this.editingInsurance) {
      this.enableCustomer = false;
      this.printable = !this.printable;
      this.showCreate = false;
      this.srAlertMessage = null;
      this.visibleLetter = false;
      this.visibleDiscount = false;
      // }
    },

    hideInvoice() {
      this.printable = false;
    },

    printInvoicePDF(event) {
      window.open(
        this.baseUrl +
          "print/bg.php?insuranceNr=" +
          this.selectedInsurance +
          "&startDate=" +
          event.startDate +
          "&endDate=" +
          event.endDate +
          "&key=" +
          this.apiKey
      );
    },

    showSjalvrisk() {
      // if (this.editingInsurance) {
      // this.$store.dispatch("fetchInsuranceInfo", {
      //   insuranceID: this.selectedInsuranceID,
      //   selectedDate: event.srDate,
      // });
      this.enableCustomer = false;
      this.showCreate = !this.showCreate;
      this.printable = false;
      this.srAlertMessage = null;
      this.visibleLetter = false;
      this.visibleDiscount = false;
      // }
    },

    showLetter() {
      this.enableCustomer = false;
      this.showCreate = false;
      this.printable = false;
      this.srAlertMessage = null;
      this.visibleDiscount = false;
      this.visibleLetter = true;
    },

    saveLetter(e) {
      if (e.selectedDate && e.letter) {
        let selectedDate = moment(e.selectedDate).format("YYYY-MM-DD");
        this.$axios
          .get(
            "api/edit.php?type=addExtraTerms&insuranceID=" +
              this.selectedInsuranceID +
              "&date=" +
              selectedDate +
              "&value=" +
              e.letter +
              "&key=" +
              this.apiKey
          )
          .then((response) => {
            if (response.data.status === "ok") {
              this.resetkey++;
              this.$store.dispatch("client/fetchCustomer", {
                customerID: this.customerID,
                insuranceID: this.selectedInsuranceID,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    showDiscount() {
      this.enableCustomer = false;
      this.showCreate = false;
      this.printable = false;
      this.srAlertMessage = null;
      this.visibleLetter = false;
      this.visibleDiscount = true;
    },

    saveDiscount(e) {
      if (e.selectedDate && e.discount) {
        let selectedDate = moment(e.selectedDate).format("YYYY-MM-DD");
        this.$axios
          .get(
            this.baseUrl +
              "api/edit.php?type=addDiscount&insuranceID=" +
              this.selectedInsuranceID +
              "&date=" +
              selectedDate +
              "&value=" +
              parseInt(e.discount) +
              "&key=" +
              this.apiKey
          )
          .then((response) => {
            if (response.data.status === "ok") {
              this.resetkey++;
              this.$store.dispatch("client/fetchCustomer", {
                customerID: this.customerID,
                insuranceID: this.selectedInsuranceID,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    saveSr(event) {
      this.$axios
        .get(
          this.baseUrl +
            "api/edit.php?type=editObject&date=" +
            moment(event.srDate).format("YYYY-MM-DD") +
            "&insuranceID=" +
            this.selectedInsuranceID +
            "&sr=" +
            encodeURIComponent(event.srText) +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status === "ok") {
            this.srAlertMessage = "Meddelande uppdaterat";
            this.$store.dispatch("fetchInsuranceInfo", {
              insuranceID: this.selectedInsuranceID,
              selectedDate: event.srDate,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    navigateDamage(damage) {
      this.$router.push({
        name: "damage-details",
        params: { damageID: damage.damageID },
      });
    },

    showDamage(damage) {
      let insurance = this.insurance.find(
        (ins) => ins.insuranceNr == damage.insuranceNr
      );
      this.selectedInsuranceID = insurance.insuranceID;
      this.selectedInsurance = damage.insuranceNr;
      this.updateKey++;
    },

    createInsurance() {
      this.$axios
        .post(
          this.baseUrl +
            "api/draft.php?type=create&customerID=" +
            this.customerID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            let insuranceID = response.data.data.insuranceID;
            this.$router
              .push({
                name: "create-insurance",
                query: {
                  insuranceID: insuranceID,
                  customerID: this.customerID,
                },
              })
              .catch(() => {});
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-route {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  .modal-content {
    overflow: auto;
    width: 90%;
    height: 90%;
    top: 5%;
    left: 5%;
    position: absolute;
    background: white;
  }
}
.customer-show {
  button {
    font-size: 15px;
    font-weight: 600;
    border-radius: 5px;
    border: 1px solid #dde3eb;
    margin: 4px;
    background-color: white;
  }
  .printable,
  .create {
    background: #fff;
    .card-header {
      background-color: #b8d1cd;
      color: #39796e;
    }
    .btn {
      margin-bottom: 1.3rem;
      max-width: 80%;
      background-color: rgb(90, 161, 150);
      color: white;
    }
  }
}
</style>