<template>
  <div class="invoice mb-3">
    <div class="boxheadline">
      <h5 class="hl-box">Skapa faktura</h5>
      <a @click="$emit('close')"
        ><button class="closebtn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            class="bi bi-x"
            viewBox="0 0 16 16"
          >
            <path
              d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
            />
          </svg></button
      ></a>
    </div>
    <div class="content p-3">
      <div class="form-row">
        <div class="col-md-5">
          <b-form-input v-model="year"></b-form-input>
        </div>
        <div class="col-md-3">
          <button
            type="button"
            class="btn btn-block btnLargeGr"
            @click="print()"
          >
            Skriv ut
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      year: moment().add(31, "days").format("YYYY"),
      startDate: moment().format("YYYY") + "-01-01",
      endDate: moment().format("YYYY") + "-12-31",
    };
  },

  methods: {
    print() {
      this.startDate = this.year + "-01-01";
      this.endDate = this.year + "-12-31";
      this.$emit("onClick", {
        startDate: this.startDate,
        endDate: this.endDate,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.invoice {
  background: #fff;
  .boxheadline {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
</style>