import Vue from 'vue'
import VueRouter from 'vue-router'
import Start from '@/views/Start.vue'
import IndexProducts from '@/views/products/Index.vue'
import Batch from '@/views/Batch.vue'
import ProductsCategory from '@/views/products/ProductsCategory.vue'
import Standard from '@/views/products/Standard.vue'
import Account from '@/views/economy/account.vue'
import Balance from '@/views/economy/balance.vue'
import Question from '@/views/products/Question.vue'
import IndexReports from '@/views/reports/Index.vue'
import Insurances from '@/views/insurances/Insurances.vue'
import InsuranceLists from '@/views/insurances/InsuranceLists.vue'
import InsurancesDraft from '@/views/insurances/InsurancesDraft.vue'
import CreateInsurance from '@/views/insurances/CreateInsurance.vue'
import IndexCustomer from '@/views/customers/Index.vue'
import ShowCustomer2 from '@/views/customers/Show2.vue'
import CustomerInsurance from '@/views/customers/Insurance.vue'
import EditCustomer from '@/views/customers/Edit.vue'
import ListNotes from '@/views/customers/Notes.vue'
import ShowAlerts from '@/views/customers/Alerts.vue'
import IndexUsers from '@/views/users/Index.vue'
import CreateUser from '@/views/users/Create.vue'
import Settings from '@/views/Settings.vue'
import Login from '@/views/Login.vue'
import MainCustomer from '@/views/customers/main.vue'
import SystemSettings from '@/views/SystemSettings.vue'
import InsuranceCompany from '@/views/InsuranceCompany.vue'
import ChangesLog from '@/views/ChangesLog.vue'
import DamageChangeLog from '@/views/DamageChangeLog.vue'
import DamageManagement from '@/views/damages/DamageManagement.vue'
import DamageReport from '@/views/damages/DamageReport.vue'
import CreateDamage from '@/views/damages/CreateDamage.vue'
import DamageDetails2 from '@/views/damages/DamageDetails2.vue'
import EditDamage from '@/views/damages/EditDamage.vue'
import DeletedCustomers from '@/views/DeletedCustomers.vue'
import ChangedObjects from '@/views/ChangedObjects.vue'
import ChangedInsurances from '@/views/ChangedInsurances.vue'
import DeletedDamages from '@/views/DeletedDamages.vue'
import Backup from '@/views/Backup.vue'
import Premie from '@/views/economy/premie.vue'
import Accounting from '@/views/economy/accounting.vue'
import Autogiro from '@/views/economy/autogiro.vue'
import ArticleAdmin from '@/views/ArticleAdmin.vue'
import Economy from '@/views/economy/economy.vue'
import Payment from '@/views/economy/payment.vue'
import News from '@/views/News.vue'
import Partners from '@/views/Partners.vue'
import Unauthorized from '@/views/Unauthorized.vue'
import store from '@/store/index'
// import { isAuthenticated, accessLevel } from '@/_helpers/auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login/:usertype?',
    name: 'login',
    component: Login,
    meta: { requiresGuest: true },
    props: true
  },
  {
    path: '/',
    name: 'home',
    component: Start,
    meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: { requiresGuest: true }
  },
  {
    path: '/backup',
    name: 'backup',
    component: Backup,
    meta: { requiresAuth: true, roles: ['admin'] }
  },
  {
    path: '/products',
    name: 'products',
    component: IndexProducts,
    meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] }
  },
  {
    path: '/batch',
    name: 'batch',
    component: Batch,
    meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] }
  },
  {
    path: '/products/category/:categoryID',
    name: 'products-category',
    component: ProductsCategory,
    props: true,
    meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] }
  },
  {
    path: '/standard/:formID',
    name: 'standard',
    component: Standard,
    props: true,
    meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] }
  },
  {
    path: '/question/:questionID',
    name: 'question',
    component: Question,
    props: true,
    meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] }
  },
  {
    path: '/reports',
    name: 'reports',
    component: IndexReports,
    meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] }
  },
  {
    path: '/insurances',
    name: 'insurances',
    component: Insurances,
    meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] },
    props: true
  },
  {
    path: '/insurance-show',
    name: 'insurance-show',
    component: InsuranceLists,
    meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] },
    props: true
  },
  {
    path: '/insurances/draft',
    name: 'insurances-draft',
    component: InsurancesDraft,
    meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] },
    props: true
  },
  {
    path: '/create-insurance',
    name: 'create-insurance',
    component: CreateInsurance,
    meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] },
    props: true
  },
  {
    path: '/ekonomi',
    name: 'economy',
    component: Economy,
    props: true,
    meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] }
  },
  {
    path: '/ekonomi/autogiro',
    name: 'economy-autogiro',
    component: Autogiro,
    props: true,
    meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] }
  },
  {
    path: '/ekonomi/betalning',
    name: 'economy-payment',
    component: Payment,
    props: true,
    meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] }
  },
  {
    path: '/ekonomi/bokforing',
    name: 'economy-accounting',
    component: Accounting,
    props: true,
    meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] }
  },
  {
    path: '/ekonomi/konto',
    name: 'economy-account',
    component: Account,
    props: true,
    meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] }
  },
  {
    path: '/ekonomi/saldo',
    name: 'economy-balance',
    component: Balance,
    props: true,
    meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] }
  },
  {
    path: '/ekonomi/premie',
    name: 'economy-premie',
    component: Premie,
    props: true,
    meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] }
  },
  {
    path: '/kunder',
    component: MainCustomer,
    props: true,
    meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] },
    children: [
      {
        path: '/kundlista',
        name: 'customers',
        meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] },
        component: IndexCustomer
      },
      {
        path: ':customerID/insurance/:insuranceNr',
        name: 'customer-insurance',
        component: CustomerInsurance,
        meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] },
        props: true,
      },
      {
        path: ':id/show',
        name: 'customer-show',
        component: ShowCustomer2,
        meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] },
        props: true,
      },
      {
        path: 'notes',
        name: 'message',
        meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] },
        component: ListNotes
      },
      {
        path: 'alerts',
        name: 'alerts',
        meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] },
        component: ShowAlerts
      },
      {
        path: ':id/edit',
        name: 'customer-edit',
        component: EditCustomer,
        meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] },
        props: true
      },
    ]
  },
  {
    path: '/news',
    name: 'news',
    component: News,
    meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] }
  },
  {
    path: '/users',
    name: 'users',
    component: IndexUsers,
    meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] }
  },
  {
    path: '/users/create',
    name: 'create-user',
    component: CreateUser,
    meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] }
  },
  {
    path: '/changeslog',
    name: 'changeslog',
    component: ChangesLog,
    meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] }
  },
  {
    path: '/changed/damages',
    name: 'changed-damages',
    component: DamageChangeLog,
    meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] }
  },
  {
    path: '/skadehantering',
    name: 'damage-management',
    component: DamageManagement,
    meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] }
  },
  {
    path: '/skaderapport',
    name: 'damage-report',
    component: DamageReport,
    meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] }
  },
  {
    path: '/skadehantering/create/:customerID/:insuranceID',
    name: 'create-damage',
    component: CreateDamage,
    meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] }
  },
  {
    path: '/skadehantering/:damageID',
    name: 'damage-details',
    component: DamageDetails2,
    meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] }
  },
  {
    path: '/skadehantering/edit/:damageID',
    name: 'edit-damage',
    component: EditDamage,
    meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] }
  },
  {
    path: '/deleted/customers',
    name: 'deletedCustomers',
    component: DeletedCustomers,
    meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] }
  },
  {
    path: '/changed/objects',
    name: 'ChangedObjects',
    component: ChangedObjects,
    meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] }
  },
  {
    path: '/changed/insurances',
    name: 'ChangedInsurances',
    component: ChangedInsurances,
    meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] }
  },
  // {
  //   path: '/deleted/insurance',
  //   name: 'deletedInsurance',
  //   component: DeletedInsurance,
  //   meta: { requiresAuth: true, roles: ['user', 'admin'] }
  // },
  {
    path: '/deleted/damages',
    name: 'deletedDamages',
    component: DeletedDamages,
    meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] }
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    meta: { requiresAuth: true, roles: ['user', 'office', 'admin'] }
  },
  {
    path: '/articleAdmin',
    name: 'article-admin',
    component: ArticleAdmin,
    meta: { requiresAuth: true, roles: ['admin'] }
  },
  {
    path: '/system',
    name: 'system',
    component: SystemSettings,
    meta: { requiresAuth: true, roles: ['admin'] }
  },
  {
    path: '/forsakringsbolag',
    name: 'insurance-company',
    component: InsuranceCompany,
    meta: { requiresAuth: true, roles: ['admin'] }
  },
  {
    path: '/partners',
    name: 'partners',
    component: Partners,
    meta: { requiresAuth: true, roles: ['lf', 'anticimex', 'admin'] }
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: Unauthorized,
  },
  { path: '*', redirect: '/' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.auth.authenticated) {
      next({ name: 'login', query: { redirect: to.fullPath } })
    } else if (to.name === 'login' || to.name == 'home') {
      if (store.state.auth.accesslevel == 'lf' || store.state.auth.accesslevel == 'anticimex') {
        next({ name: 'partners' })
      } else {
        next();
      }
    } else if (to.meta.roles) {
      if (to.meta.roles.includes(store.state.auth.accesslevel)) {
        next();
      } else {
        next({ name: 'unauthorized' })
      }
    }
  } else if (to.matched.some(record => record.meta.requiresGuest)) {
    if (store.state.auth.authenticated) {
      next({ name: 'home' })
    } else {
      next();
    }
  }
})

export default router
