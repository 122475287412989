<template>
  <div class="damage w-100 fourth">
    <navbar headertext="Skadehantering" />
    <div class="content p-4">
      <CustomerHeader
        v-if="customer != null"
        :customer="customer"
        @toggleCustomer="toggleCustomer($event)"
      />
      <div class="row">
        <div class="col-md-6 box-info">
          <div class="row">
            <h3 class="col-lg-10">Anmäl skada</h3>
            <a @click="$router.go(-1)">
              <b-button class="btn-light">Avbryt</b-button></a
            >
          </div>
          <div
            class="alert alert-success mt-3"
            role="alert"
            v-if="submitStatus == 'OK' && successMessage != null"
          >
            {{ successMessage }}
          </div>
          <div class="form-group">
            <label for="insuranceNr">Försäkringsnummer</label>
            <input
              type="text"
              class="form-control form-control-lg"
              id="insuranceNr"
              placeholder="Försäkringsnummer"
              v-model="insuranceNr"
              readonly
              :class="{
                'is-invalid': submitStatus == 'ERROR' && $v.insuranceNr.$error,
              }"
            />
            <div class="invalid-feedback" v-if="!$v.insuranceNr.required">
              Du måste ange försäkringsnummer
            </div>
          </div>
          <div class="form-group">
            <label for="title">Skadenummer</label>
            <input
              v-model="damageNr"
              type="number"
              class="form-control form-control-lg"
              id="title"
              placeholder="Skadenummer"
            />
          </div>
          <div class="form-group">
            <label for="damageDate">Skadedatum</label>
            <datepicker
              format="yyyy-MM-dd"
              input-class="form-control form-control-lg"
              :bootstrap-styling="true"
              v-model="damageDate"
              :monday-first="true"
              :language="sv"
              :disabledDates="disabledDates"
              id="damageDate"
              placeholder="Ange datum"
              :class="{
                'is-invalid': submitStatus == 'ERROR' && $v.damageDate.$error,
              }"
            ></datepicker>
            <div class="invalid-feedback" v-if="!$v.damageDate.required">
              Välj skadedatum
            </div>
          </div>
          <div class="form-group">
            <label for="reportDate">Rapporteringsdatum</label>
            <datepicker
              format="yyyy-MM-dd"
              input-class="form-control form-control-lg"
              :bootstrap-styling="true"
              v-model="reportDate"
              :monday-first="true"
              :language="sv"
              :disabledDates="disabledDates"
              placeholder="Välj rapporteringsdatum"
            ></datepicker>
          </div>
          <div class="form-group">
            <label for="endDate">Ärendet avslutat</label>
            <datepicker
              format="yyyy-MM-dd"
              input-class="form-control form-control-lg"
              :bootstrap-styling="true"
              v-model="endDate"
              :monday-first="true"
              :language="sv"
              :disabledDates="disabledDates"
              placeholder="Ärendet avslutat"
            ></datepicker>
          </div>
          <div class="form-group">
            <label for="prognosis">Prognostiserad kostnad</label>
            <input
              v-model="prognosis"
              type="number"
              class="form-control form-control-lg"
              id="prognosis"
              placeholder="Prognostiserad kostnad"
            />
          </div>
          <div class="form-group">
            <label for="category">Kategori</label>
            <select
              class="form-control form-control-lg"
              id="category"
              v-model="categoryID"
              :class="{
                'is-invalid': submitStatus == 'ERROR' && $v.categoryID.$error,
              }"
            >
              <option :value="null">Välj kategori</option>
              <template v-if="categories.length > 0">
                <option
                  :value="category.categoryID"
                  v-for="(category, index) in categories"
                  :key="index"
                >
                  {{ category.category }}
                </option>
              </template>
            </select>
            <div class="invalid-feedback" v-if="!$v.categoryID.required">
              Du måste välja kategori
            </div>
          </div>
          <div class="form-group">
            <label for="damageReport">Godkänd skadeanmälan upprättad:</label>
            <br />
            <div style="padding: 0.5 rem 1rem">
              <div class="form-check form-check-inline">
                <input
                  type="radio"
                  v-model="damageReport"
                  class="form-check-input"
                  id="n"
                  value="n"
                />
                <label for="n" class="form-check-label">nej</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  type="radio"
                  v-model="damageReport"
                  class="form-check-input"
                  id="y"
                  value="y"
                />
                <label for="y" class="form-check-label">ja</label>
              </div>
            </div>
          </div>
          <button
            type="button"
            class="btn btn-secondary btn-block btn-lg btnGr"
            @click="saveDamage()"
          >
            Spara
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomerHeader from "@/components/CustomerHeader.vue";
import moment from "moment";
import { mapState } from "vuex";
import Navbar from "@/components/Navbar.vue";
import { required } from "vuelidate/lib/validators";
import Datepicker from "vuejs-datepicker";
import { sv } from "vuejs-datepicker/dist/locale";
export default {
  name: "create-damage",
  data() {
    return {
      customer: null,
      sv: sv,
      damages: [],
      customerID: null,
      insuranceNr: null,
      damageNr: null,
      damageDate: null,
      reportDate: null,
      endDate: null,
      prognosis: null,
      damageReport: "n",
      categoryID: null,
      categories: [],
      submitStatus: null,
      successMessage: null,
      disabledDates: {
        // to: new Date(Date.now() - 8640000),
        from: new Date(),
      },
    };
  },

  validations: {
    customerID: { required },
    insuranceNr: { required },
    damageDate: { required },
    categoryID: { required },
  },

  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      apiKey: (state) => state.auth.apiKey,
    }),
  },

  components: {
    CustomerHeader,
    Navbar,
    Datepicker,
  },

  mounted() {
    this.reportDate = new Date();
    this.customerID = parseInt(this.$route.params.customerID);
    this.insuranceNr = parseInt(this.$route.params.insuranceID);
    this.fetchDamageCategories();
    this.fetchCustomer();
  },

  methods: {
    fetchCustomer() {
      this.loading = true;
      this.$axios
        .get(
          this.baseUrl +
            "api/getCustomer.php?id=" +
            this.customerID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.customer = response.data.customer;
            this.loading = false;
          }
        });
    },
    fetchDamageCategories() {
      this.$axios
        .get(this.baseUrl + "api/getDamageCategories.php?key=" + this.apiKey)
        .then((response) => {
          this.categories = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    saveDamage() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";

        let damageDate = moment(this.damageDate).format("YYYY-MM-DD");
        let reportDate = moment(this.reportDate).format("YYYY-MM-DD");
        let endDate = moment(this.endDate).format("YYYY-MM-DD");

        var formData = new FormData();
        formData.append("damageNr", this.damageNr);
        formData.append("insuranceNr", this.insuranceNr);
        formData.append("damageDate", damageDate);
        formData.append("categoryID", this.categoryID);
        formData.append("damageReport", this.damageReport);
        formData.append("reportDate", reportDate);
        formData.append("endDate", endDate);
        formData.append("prognosis", this.prognosis);

        this.$axios
          .post(this.baseUrl + "api/damage.php?key=" + this.apiKey, formData)
          .then((response) => {
            if (response.data.status == "ok") {
              var damageID = response.data.damageID;
              this.$router.push({
                name: "damage-details",
                params: { damageID: damageID },
              });
            }
          });
      }
    },
  },
};
</script>