<template>
  <div class="customer-edit w-100">
    <navbar headertext="Ändra kunduppgifter" />
    <div class="content p-4">
      <CustomerHeader
        v-if="customer != null"
        :customer="customer"
        @toggleCustomer="toggleCustomer($event)"
      />
      <NewNote
        v-if="customer != null && users.length > 0 && enableCustomer"
        @update="closeCustomerNote()"
        :enableCustomer="true"
        :customer="customer"
        :users="users"
      />
      <div class="row">
        <div class="col-md-8">
          <div class="box-info">
            <div class="row">
              <h3 class="col-lg-10">Ändra kunduppgifter</h3>
              <a @click="$router.go(-1)"
                ><b-button class="btn-light">Avbryt</b-button></a
              >
            </div>
            <template v-if="customer !== null">
              <div class="form-row">
                <div class="form-group col-md-5">
                  <label for="firstName">Förnamn</label>
                  <input
                    type="text"
                    class="form-control"
                    id="firstName"
                    placeholder="Förnamn"
                    @keyup="onUpdate"
                    v-model="customer.firstName"
                  />
                </div>
                <div class="form-group col-md-7">
                  <label for="lastName">Efternamn</label>
                  <input
                    type="text"
                    class="form-control"
                    id="lastName"
                    placeholder="Efternamn"
                    @keyup="onUpdate"
                    v-model="customer.lastName"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-5">
                  <label for="pnr">Personnummer</label>
                  <input
                    type="text"
                    class="form-control"
                    id="pnr"
                    placeholder="Personnummer"
                    @keyup="onUpdate"
                    v-model="customer.pnr"
                  />
                </div>
                <div class="form-group col-md-7">
                  <label for="pnr">Org.nr</label>
                  <input
                    type="text"
                    class="form-control"
                    id="orgnr"
                    placeholder="Org.nr"
                    @keyup="onUpdate"
                    v-model="customer.orgnr"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-5">
                  <label for="street">Företagsnamn</label>
                  <input
                    type="text"
                    class="form-control"
                    id="companyName"
                    placeholder="Fylls endast i för företagskund"
                    @keyup="onUpdate"
                    v-model="customer.companyName"
                  />
                </div>
                <div class="form-group col-md-5">
                  <label for="street">C/O</label>
                  <input
                    type="text"
                    class="form-control"
                    id="careOf"
                    placeholder="c/o"
                    @keyup="onUpdate"
                    v-model="customer.careOf"
                  />
                </div>
                <div class="form-group col-md-2">
                  <label for="street">Kundnr</label>
                  <input
                    type="text"
                    class="form-control"
                    id="customerNr"
                    placeholder="Kundnummer"
                    @keyup="onUpdate"
                    v-model="customer.customerNr"
                  />
                </div>
              </div>
              <div class="form-group">
                <label for="street">Adress</label>
                <input
                  type="text"
                  class="form-control"
                  id="street"
                  placeholder="Adress"
                  @keyup="onUpdate"
                  v-model="customer.street"
                />
              </div>
              <div class="form-row">
                <div class="form-group col-md-5">
                  <label for="postcode">Postnummer</label>
                  <input
                    type="text"
                    class="form-control"
                    id="postcode"
                    placeholder="Postnummer"
                    @keyup="onUpdate"
                    v-model="customer.postcode"
                  />
                </div>
                <div class="form-group col-md-7">
                  <label for="city">Ort</label>
                  <input
                    type="text"
                    class="form-control"
                    id="city"
                    placeholder="Ort"
                    @keyup="onUpdate"
                    v-model="customer.city"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-5">
                  <label for="homePhone">Hemtelefon</label>
                  <input
                    type="text"
                    class="form-control"
                    id="homePhone"
                    placeholder="Hemtelefon"
                    @keyup="onUpdate"
                    v-model="customer.homePhone"
                  />
                </div>
                <div class="form-group col-md-7">
                  <label for="mobilePhone">Mobiltelefon</label>
                  <input
                    type="text"
                    class="form-control"
                    id="mobilePhone"
                    placeholder="Mobiltelefon"
                    @keyup="onUpdate"
                    v-model="customer.mobilePhone"
                  />
                </div>
              </div>
              <div class="form-group">
                <label for="street">E-mail</label>
                <input
                  type="text"
                  class="form-control"
                  id="email"
                  placeholder="E-mail"
                  @keyup="onUpdate"
                  v-model="customer.email"
                />
              </div>
              <button
                :disabled="btnDisabled"
                type="button"
                :class="btnClass"
                @click="updateCustomer()"
              >
                {{ btnText }}
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewNote from "@/components/NewNote.vue";

import CustomerHeader from "@/components/CustomerHeader.vue";
import { mapState } from "vuex";
import Navbar from "@/components/Navbar.vue";
export default {
  name: "customer-edit",
  data() {
    return {
      enableCustomer: false,

      // users: [],
      customer: null,
      originalCustomer: null,
      btnClass: "btn btn-success btn-lg btn-block",
      btnDisabled: true,
      btnText: "Sparad",
    };
  },

  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      apiKey: (state) => state.auth.apiKey,
      users: (state) => state.user.users,
    }),
  },

  components: {
    CustomerHeader,
    Navbar,
    NewNote,
  },

  mounted() {
    this.$store.dispatch("user/fetchUsers");
    this.fetchCustomer();
  },

  methods: {
    toggleCustomer(value) {
      this.enableCustomer = value;
    },

    closeCustomerNote() {
      this.enableCustomer = !this.enableCustomer;
    },

    onUpdate() {
      if (!this.compareCustomer(this.originalCustomer, this.customer)) {
        this.btnClass = "btn btn-danger btn-lg btn-block";
        this.btnDisabled = false;
        this.btnText = "Spara";
      } else {
        this.btnClass = "btn btn-lg btn-block btnSmGr";
        this.btnDisabled = true;
        this.btnText = "Sparad";
      }
    },
    compareCustomer(a, b) {
      if (a.firstName != b.firstName) {
        return false;
      } else if (a.lastName != b.lastName) {
        return false;
      } else if (a.pnr != b.pnr) {
        return false;
      } else if (a.orgnr != b.orgnr) {
        return false;
      } else if (a.companyName != b.companyName) {
        return false;
      } else if (a.careOf != b.careOf) {
        return false;
      } else if (a.customerNr != b.customerNr) {
        return false;
      } else if (a.street != b.street) {
        return false;
      } else if (a.postcode != b.postcode) {
        return false;
      } else if (a.city != b.city) {
        return false;
      } else if (a.homePhone != b.homePhone) {
        return false;
      } else if (a.mobilePhone != b.mobilePhone) {
        return false;
      } else if (a.email != b.email) {
        return false;
      }
      return true;
    },
    fetchCustomer() {
      let customerID = this.$route.params.id;

      this.$axios
        .get(
          this.baseUrl +
            "api/getCustomer.php?id=" +
            customerID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.customer = response.data.customer;
            this.originalCustomer = JSON.parse(
              JSON.stringify(response.data.customer)
            );
            this.onUpdate();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    updateCustomer() {
      let homePhone = "";
      let mobilePhone = "";
      if (this.customer.homePhone) {
        let phone = this.customer.homePhone;
        homePhone = phone.replace(/[^\w\d]/gi, "");
      }
      if (this.customer.mobilePhone) {
        let phone = this.customer.mobilePhone;
        mobilePhone = phone.replace(/[^\w\d]/gi, "");
      }
      var formData = new FormData();
      formData.append("id", parseInt(this.customer.id));
      formData.append("firstName", this.customer.firstName ?? "");
      formData.append("lastName", this.customer.lastName ?? "");
      formData.append("homePhone", homePhone);
      formData.append("mobilePhone", mobilePhone);
      formData.append("street", this.customer.street ?? "");
      formData.append("postcode", this.customer.postcode ?? "");
      formData.append("city", this.customer.city ?? "");
      formData.append("email", this.customer.email ?? "");
      formData.append("customerNr", this.customer.customerNr ?? null);
      formData.append("careOf", this.customer.careOf ?? "");
      formData.append("companyName", this.customer.companyName ?? "");
      formData.append("pnr", this.customer.pnr ?? "")
      formData.append("orgnr", this.customer.orgnr ?? "")

      this.$axios
        .post(this.baseUrl + "api/customer.php?key=" + this.apiKey, formData)
        .then((response) => {
          console.log(response.data);
          this.fetchCustomer();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
