<template>
  <div class="staffs w-100">
    <navbar />
    <div class="content p-4 box-info">
      <div class="row">
        <div class="col-md-8">
          <h3>Användare</h3>
        </div>
        <div class="col-md-4 text-right">
          <button class="btn btn-custom btnSmGr" id="create-user" @click="createUser()">
            <font-awesome-icon icon="plus-circle" />&nbsp;Lägg till användare
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div
            class="alert alert-success mt-3"
            role="alert"
            v-if="submitStatus == 'OK' && successMessage != null"
          >
            {{ successMessage }}
          </div>
        </div>
        <div class="col-md-12">
          <table class="table table-striped mt-3" v-if="users.length > 0">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Namn</th>
                <th scope="col">E-post</th>
                <th scope="col">Roll</th>
                <th scope="col">Inloggning</th>
                <th scope="col">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(user, index) in users" :key="index">
                <td>{{ user.userID }}</td>
                <td>{{ user.realname }}</td>
                <td>{{ user.email }}</td>
                <td v-html="getAccessLevel(user.accesslevel)"></td>
                <td>{{ user.smslogin == "y" ? "sms" : "-" }}</td>
                <td>
                  <span class="edit" @click="editUser(user)">
                    <font-awesome-icon icon="edit" />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <template v-if="users.length == 0">
          <div class="col-md-12">
            <div class="alert alert-secondary" role="alert">
              Ingen användare hittades
            </div>
          </div>
        </template>
      </div>
      <div class="modal">
        <b-modal
          id="modal-center modal-lg"
          size="lg"
          centered
          :title="title"
          v-model="showModal"
          @hide="reset()"
        >
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              id="realname"
              placeholder="Namn"
              v-model="user.realname"
              :class="{
                'is-invalid':
                  submitStatus == 'ERROR' && $v.user.realname.$error,
              }"
            />
            <div class="invalid-feedback" v-if="!$v.user.realname.required">
              Ange namn
            </div>
          </div>
          <div class="form-group">
            <input
              type="email"
              class="form-control"
              id="email"
              placeholder="E-postadress"
              v-model="user.email"
              :class="{
                'is-invalid': submitStatus == 'ERROR' && $v.user.email.$error,
              }"
            />
            <div class="invalid-feedback" v-if="!$v.user.email.required">
              Du måste ange e-postadress
            </div>
            <div class="invalid-feedback" v-if="!$v.user.email.email">
              Ange giltig e-postadress
            </div>
          </div>
          <div class="form-group">
            <input
              type="phone"
              class="form-control"
              id="mobilePhone"
              placeholder="Mobiltelefon"
              v-model="user.mobilePhone"
              :class="{
                'is-invalid':
                  submitStatus == 'ERROR' && $v.user.mobilePhone.$error,
              }"
            />
            <div class="invalid-feedback" v-if="!$v.user.mobilePhone.required">
              Du måste ange mobilnummer
            </div>
          </div>
          <div
            class="form-group custom-control custom-switch"
            v-if="status == 'edit'"
          >
            <input
              type="checkbox"
              class="custom-control-input"
              id="update-password"
              v-model="hasUpdatePassword"
            />
            <label class="custom-control-label" for="update-password"
              >Ändra lösenordet?</label
            >
          </div>
          <div class="form-group" v-if="hasUpdatePassword">
            <input
              type="password"
              class="form-control"
              id="password"
              placeholder="Lösenord"
              v-model="user.password"
              :class="{
                'is-invalid':
                  submitStatus == 'ERROR' && $v.user.password.$error,
              }"
            />
            <div class="invalid-feedback" v-if="!$v.user.password.required">
              Du måste ange lösenord
            </div>
            <div class="invalid-feedback" v-if="!$v.user.password.min">
              Ange minst 8 tecken
            </div>
          </div>
          <div class="form-group">
            <select
              class="form-control"
              v-model="user.accesslevel"
              :class="{
                'is-invalid':
                  submitStatus == 'ERROR' && $v.user.accesslevel.$error,
              }"
              @change="accesslevelChanged()"
            >
              <option :value="null">Välj roll</option>
              <option value="admin">Admin</option>
              <option value="office">Kontor</option>
              <option value="user">Användare</option>
              <option value="lf">Länsförsäkringar</option>
              <option value="anticimex">Anticimex</option>
            </select>
            <div class="invalid-feedback" v-if="!$v.user.accesslevel.required">
              Du måste välja roll
            </div>
          </div>
          <div class="form-group">
            <textarea
              class="form-control"
              id="education"
              rows="3"
              v-model="user.education"
              placeholder="Utbildning"
            ></textarea>
          </div>
          <div class="form-group">
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                class="custom-control-input"
                id="sms-inloggning"
                v-model="user.smslogin"
                :disabled="
                  user.mobilePhone == null ||
                  user.mobilePhone == '' ||
                  user.accesslevel == 'lf' ||
                  user.accesslevel == 'anticimex'
                "
              />
              <label class="custom-control-label" for="sms-inloggning"
                >SMS-inloggning</label
              >
            </div>
          </div>
          <template v-slot:modal-footer="{ cancel }">
            <b-button variant="secondary" @click="cancel()">Avbryt</b-button>
            <b-button
              variant="success"
              @click="addUser()"
              v-if="status == 'create'"
              >Spara</b-button
            >
            <b-button
              variant="success"
              @click="updateUser()"
              v-if="status == 'edit'"
              >Spara</b-button
            >
          </template>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Navbar from "@/components/Navbar.vue";
import {
  required,
  email,
  minLength,
  requiredIf,
} from "vuelidate/lib/validators";
import { Role } from "@/_helpers/role";
export default {
  name: "staff",
  data() {
    return {
      // users: [],
      showModal: false,
      submitStatus: null,
      successMessage: null,
      user: {
        userID: null,
        realname: null,
        email: null,
        mobilePhone: null,
        password: null,
        accesslevel: null,
        smslogin: false,
        education: null,
      },
      title: "Lägg till användare",
      status: "create",
      hasUpdatePassword: true,
    };
  },

  validations() {
    if (this.hasUpdatePassword) {
      return {
        user: {
          realname: { required },
          email: { required, email },
          password: { required, minLength: minLength(8) },
          accesslevel: { required },
          mobilePhone: {
            required: requiredIf(function () {
              return (
                this.user.accesslevel == "lf" ||
                this.user.accesslevel == "anticimex"
              );
            }),
          },
        },
      };
    } else {
      return {
        user: {
          realname: { required },
          email: { required, email },
          accesslevel: { required },
          mobilePhone: {
            required: requiredIf(function () {
              return (
                this.user.accesslevel == "lf" ||
                this.user.accesslevel == "anticimex"
              );
            }),
          },
        },
      };
    }
  },

  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      apiKey: (state) => state.auth.apiKey,
      users: (state) => state.user.allUsers,
    }),
  },

  components: {
    Navbar,
  },

  mounted() {
    this.getAllUsers();
  },

  methods: {
    ...mapActions("user", ["getAllUsers", "fetchUsers"]),

    accesslevelChanged() {
      if (
        this.user.accesslevel == "lf" ||
        this.user.accesslevel == "anticimex"
      ) {
        this.user.smslogin = true;
      } else {
        this.user.smslogin = false;
      }
    },

    createUser() {
      this.status = "create";
      (this.title = "Lägg till användare"),
        (this.user = {
          realname: null,
          email: null,
          mobilePhone: null,
          password: null,
          accesslevel: null,
        });
      this.showModal = !this.showModal;
    },

    addUser() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";

        let smslogin =
          this.user.accesslevel == "lf" || this.user.accesslevel == "anticimex"
            ? "y"
            : this.user.smslogin;

        var formData = new FormData();
        formData.append("user", this.user.email);
        formData.append("pass", this.user.password);
        formData.append("realname", this.user.realname);
        formData.append("accesslevel", this.user.accesslevel);
        formData.append("mobilePhone", this.user.mobilePhone);
        formData.append("smslogin", smslogin);
        formData.append("education", this.user.education);
        this.$axios
          .post(this.baseUrl + "api/adduser.php?key=" + this.apiKey, formData)
          .then(() => {
            (this.user = {
              realname: null,
              email: null,
              password: null,
              mobilePhone: null,
              accesslevel: null,
              smslogin: false,
              education: null,
            }),
              (this.showModal = false);
            this.submitStatus = "OK";
            this.successMessage = "En ny användare har lagts till.";
            this.fetchUsers();
            this.reset();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    getAccessLevel(accesslevel) {
      if (accesslevel == Role.Admin) {
        return '<span class="badge badge-warning">Administratör</span>';
      } else if (accesslevel == Role.Office) {
        return '<span class="badge badge-info">Kontor</span>';
      } else if (accesslevel == Role.User) {
        return '<span class="badge badge-dark">Användare</span>';
      } else if (accesslevel == Role.LF) {
        return '<span class="badge badge-primary">Länsförsäkringar</span>';
      } else if (accesslevel == Role.Anticimex) {
        return '<span class="badge badge-success">Anticimex</span>';
      } else {
        return null;
      }
    },

    editUser(selectedUser) {
      this.status = "edit";
      this.title = "Redigera användare";
      this.hasUpdatePassword = false;
      this.showModal = true;
      this.user = {
        userID: parseInt(selectedUser.userID),
        realname: selectedUser.realname,
        email: selectedUser.email,
        password: selectedUser.password,
        mobilePhone: selectedUser.mobilePhone,
        accesslevel: selectedUser.accesslevel,
        smslogin: selectedUser.smslogin == "y" ? true : false,
        education: selectedUser.education,
      };
    },

    updateUser() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";

        let password =
          this.user.password == undefined ? "" : this.user.password;
        let smslogin = this.user.smslogin ? "y" : "n";

        var formData = new FormData();
        formData.append("userID", this.user.userID);
        formData.append("user", this.user.email);
        formData.append("realname", this.user.realname);
        formData.append("accesslevel", this.user.accesslevel);
        formData.append("mobilePhone", this.user.mobilePhone);
        formData.append("smslogin", smslogin);
        formData.append("pass", password);
        formData.append("education", this.user.education);
        this.$axios
          .post(this.baseUrl + "api/user.php?key=" + this.apiKey, formData)
          .then(() => {
            (this.user = {
              userID: null,
              realname: null,
              email: null,
              password: null,
              mobilePhone: null,
              accesslevel: null,
              smslogin: null,
              education: null,
            }),
              (this.showModal = false);
            this.submitStatus = "OK";
            this.successMessage = "Användaren har uppdaterats.";
            this.fetchUsers();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    reset() {
      this.$v.$reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.staffs {
  .table {
    th {
      font-size: 0.9rem;
    }
    td {
      font-size: 0.96rem;
      a {
        color: #4c4c4c;
        font-weight: 600;
        font-size: 0.9rem;
      }
      .badge {
        font-size: 13px;
      }
      .edit {
        cursor: pointer;
        svg {
          color: #969696;
        }
        &:hover {
          svg {
            color: #868686;
          }
        }
      }
    }
    tr:hover {
      td a {
        color: #3498db;
      }
    }
  }
}
</style>
