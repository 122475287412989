<template>
  <div class="main w-100">
    <navbar />
    <div class="container partners">
      <div class="header">
        <div class="logo">
          <img src="@/assets/akerboharad.png" alt="" />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="form-row">
                <div class="col">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Försäkringsnummer"
                    @keyup.13="search()"
                    v-model="insuranceNumber"
                  />
                </div>
                <div class="col">
                  <datepicker
                    format="yyyy-MM-dd"
                    input-class="form-control"
                    :bootstrap-styling="true"
                    v-model="selectedDate"
                    :monday-first="true"
                    :language="sv"
                    placeholder="Välj datum"
                    :disabled-dates="disabledDates"
                  ></datepicker>
                </div>
                <div class="col">
                  <button class="btn btn-success btn-block" @click="search()">
                    Sök
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p style="width: 100%; text-align: center; padding: 16px">
          Vid oklarheter, maila
          <a href="mailto:info@akerboharad.se">info@akerboharad.se</a>.
        </p>
        <div class="col-md-12 mt-4" v-if="customer != null && searched">
          <h5 class="title">Kunduppgifter</h5>
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">Namn</th>
                <th scope="col">Persnr/Orgnr</th>
                <th scope="col">Adress</th>
                <th scope="col">Postnr</th>
                <th scope="col">Ort</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{ customer.companyName != null ? customer.companyName : "" }}
                  {{ customer.companyName && customer.firstName ? " /" : "" }}
                  {{ customer.firstName ? customer.firstName + " " : "" }}
                  {{ customer.lastName ? customer.lastName : "" }}
                </td>
                <td>{{ customer.orgnr ? customer.orgnr : customer.pnr }}</td>
                <td>{{ customer.street }}</td>
                <td>{{ customer.postcode }}</td>
                <td>{{ customer.city }}</td>
              </tr>
            </tbody>
          </table>
          <div class="insurance mt-4 mb-5">
            <h5 class="title">Försäkringar</h5>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Namn</th>
                  <th scope="col">Produkt</th>
                  <th scope="col">Areal</th>
                  <th scope="col">Försäkrad</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="ins in insurance">
                  <tr :key="ins.productID">
                    <td>{{ ins.name }}</td>
                    <td colspan="3"></td>
                  </tr>
                  <template v-for="object in ins.objects">
                    <tr :key="object.objectID">
                      <td>
                        {{ object.title }}
                        {{
                          object.property != null && object.property.length > 0
                            ? "(" + object.property + ")"
                            : ""
                        }}
                      </td>
                      <td>{{ ins.name }}</td>
                      <td>
                        {{
                          object.objectValue == null
                            ? ""
                            : formatValue(object.objectValue) + " ha"
                        }}
                      </td>
                      <td colspan="2"></td>
                    </tr>
                  </template>
                  <template v-for="sp in ins.subProducts">
                    <tr :key="sp.productID">
                      <td>{{ sp.name }}</td>
                      <td>{{ ins.name }}</td>
                      <td colspan="2"></td>
                    </tr>
                    <template v-for="(obj, objKey) in sp.objects">
                      <tr :key="objKey + ' ' + sp.productID">
                        <td>{{ obj.title }}</td>
                        <td>{{ sp.name }}</td>
                        <td>
                          {{
                            obj.objectValue == null ||
                            obj.objectValue > 99999999
                              ? ""
                              : formatValue(obj.objectValue) + " ha"
                          }}
                        </td>
                        <td>
                          {{
                            obj.objectValue != null &&
                            obj.objectValue > 99999999
                              ? formatValue(obj.objectValue)
                              : ""
                          }}
                        </td>
                      </tr>
                    </template>
                  </template>
                </template>
              </tbody>
            </table>
          </div>
          <div class="additional mt-4 mb-5">
            <h5 class="title">Övrigt</h5>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Självrisk</th>
                  <th scope="col">Rådgivare</th>
                  <th scope="col">Rådgivarens utbildning</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ standardSr }}</td>
                  <td>{{ user }}</td>
                  <td>{{ education }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="insurance mt-4 mb-5" v-if="customer != null && searched">
          <h5 class="title">Rådgivning</h5>
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">Rådgivning</th>
                <th scope="col">Produkt</th>
                <th scope="col">Datum</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(gl, index) in guidelines" :key="'gl' + index">
                <td>{{ gl.guideline }}</td>
                <td>{{ gl.product }}</td>
                <td>{{ guidelineDate }}</td>
              </tr>
            </tbody>
          </table>
          <div class="card" v-if="guidelineDate">
            <div class="card-body">
              <p class="m-0">
                <strong>Distribution rådgivning: </strong>
                <span v-if="guidelinePrintDate"
                  >{{ guidelinePrintDate }} via
                  {{ customer.email ? "e-post" : "brev" }}</span
                ><span v-else>ej distribuerat</span>
              </p>
              <p class="m-0">
                <strong>Distribution försäkringsbrev: </strong>
                <span v-if="printDate"
                  >{{ printDate }} via
                  {{ customer.email ? "e-post" : "brev" }}</span
                ><span v-else>ej distribuerat</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 mt-3" v-if="searched && customer == null">
        <div class="alert alert-secondary" role="alert">
          <p class="m-0">
            Felaktigt försäkringsnummer eller sökt datum utanför
            giltighetsperiod.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import moment from "moment";
import { mapState } from "vuex";
import Datepicker from "vuejs-datepicker";
import { sv } from "vuejs-datepicker/dist/locale";
export default {
  name: "partners",
  data() {
    return {
      selectedDate: moment().format("YYYY-MM-DD"),
      insuranceNumber: null,
      customer: null,
      searched: false,
      insurance: null,
      guidelines: [],
      sv: sv,
      disabledDates: {
        to: new Date(2020, 0, 1),
      },
      standardSr: null,
      user: null,
      education: null,
      guidelineDate: null,
      guidelinePrintDate: null,
      printDate: null,
    };
  },

  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      apiKey: (state) => state.auth.apiKey,
      accesslevel: (state) => state.auth.accesslevel,
    }),

    anticimexInsurances() {
      return this.insurance.filter((ins) => ins);
    },

    lfInsurances() {
      return this.insurance.filter((ins) => ins.productID == 17);
    },
  },

  components: {
    Navbar,
    Datepicker,
  },

  methods: {
    search() {
      this.searched = true;
      this.insuranceInfo();
      this.fetchGuidelines();
    },
    fetchGuidelines() {
      this.$axios
        .get(
          this.baseUrl +
            "api/get.php?type=partnerGuidelines&insuranceNr=" +
            this.insuranceNumber +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.guidelines = response.data.data;
          }
        });
    },

    // get.php?type=insuranceInfo&insuranceID=&date=
    insuranceInfo() {
      this.$axios
        .get(
          this.baseUrl +
            "api/get.php?type=partnerInsuranceInfo&insuranceID=" +
            this.insuranceNumber +
            "&date=" +
            moment(this.selectedDate).format("YYYY-MM-DD") +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          console.log(response.data);
          if (response.data.status == "ok") {
            this.guidelineDate = response.data.guidelineDate;
            this.guidelinePrintDate = response.data.guidelinePrintDate;
            this.printDate = response.data.printDate;
            this.standardSr = response.data.standardSr;
            this.user = response.data.user ? response.data.user.realname : null;
            this.education = response.data.user
              ? response.data.user.education
              : null;
            this.insurance = response.data.data;
            this.customer = response.data.customer;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    formatValue(value) {
      if (value > 999999999) {
        return (
          value.toString().substring(0, 6) + "-" + value.toString().substring(6)
        );
      } else {
        return value.toLocaleString("sv");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.partners {
  .header {
    text-align: center;
    padding: 50px 0;
    h3 {
      text-transform: uppercase;
      font-weight: 600;
      margin-top: 30px;
    }
  }
  .card {
    background: #f1f1f1;
    button {
      font-weight: 600;
    }
  }
  .title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
  }
}
</style>
