var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"staffs w-100"},[_c('navbar'),_c('div',{staticClass:"content p-4 box-info"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-md-4 text-right"},[_c('button',{staticClass:"btn btn-custom btnSmGr",attrs:{"id":"create-user"},on:{"click":function($event){return _vm.createUser()}}},[_c('font-awesome-icon',{attrs:{"icon":"plus-circle"}}),_vm._v(" Lägg till användare ")],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[(_vm.submitStatus == 'OK' && _vm.successMessage != null)?_c('div',{staticClass:"alert alert-success mt-3",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.successMessage)+" ")]):_vm._e()]),_c('div',{staticClass:"col-md-12"},[(_vm.users.length > 0)?_c('table',{staticClass:"table table-striped mt-3"},[_vm._m(1),_c('tbody',_vm._l((_vm.users),function(user,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(user.userID))]),_c('td',[_vm._v(_vm._s(user.realname))]),_c('td',[_vm._v(_vm._s(user.email))]),_c('td',{domProps:{"innerHTML":_vm._s(_vm.getAccessLevel(user.accesslevel))}}),_c('td',[_vm._v(_vm._s(user.smslogin == "y" ? "sms" : "-"))]),_c('td',[_c('span',{staticClass:"edit",on:{"click":function($event){return _vm.editUser(user)}}},[_c('font-awesome-icon',{attrs:{"icon":"edit"}})],1)])])}),0)]):_vm._e()]),(_vm.users.length == 0)?[_vm._m(2)]:_vm._e()],2),_c('div',{staticClass:"modal"},[_c('b-modal',{attrs:{"id":"modal-center modal-lg","size":"lg","centered":"","title":_vm.title},on:{"hide":function($event){return _vm.reset()}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return cancel()}}},[_vm._v("Avbryt")]),(_vm.status == 'create')?_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){return _vm.addUser()}}},[_vm._v("Spara")]):_vm._e(),(_vm.status == 'edit')?_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){return _vm.updateUser()}}},[_vm._v("Spara")]):_vm._e()]}}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.realname),expression:"user.realname"}],staticClass:"form-control",class:{
              'is-invalid':
                _vm.submitStatus == 'ERROR' && _vm.$v.user.realname.$error,
            },attrs:{"type":"text","id":"realname","placeholder":"Namn"},domProps:{"value":(_vm.user.realname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "realname", $event.target.value)}}}),(!_vm.$v.user.realname.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Ange namn ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.email),expression:"user.email"}],staticClass:"form-control",class:{
              'is-invalid': _vm.submitStatus == 'ERROR' && _vm.$v.user.email.$error,
            },attrs:{"type":"email","id":"email","placeholder":"E-postadress"},domProps:{"value":(_vm.user.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "email", $event.target.value)}}}),(!_vm.$v.user.email.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Du måste ange e-postadress ")]):_vm._e(),(!_vm.$v.user.email.email)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Ange giltig e-postadress ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.mobilePhone),expression:"user.mobilePhone"}],staticClass:"form-control",class:{
              'is-invalid':
                _vm.submitStatus == 'ERROR' && _vm.$v.user.mobilePhone.$error,
            },attrs:{"type":"phone","id":"mobilePhone","placeholder":"Mobiltelefon"},domProps:{"value":(_vm.user.mobilePhone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "mobilePhone", $event.target.value)}}}),(!_vm.$v.user.mobilePhone.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Du måste ange mobilnummer ")]):_vm._e()]),(_vm.status == 'edit')?_c('div',{staticClass:"form-group custom-control custom-switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hasUpdatePassword),expression:"hasUpdatePassword"}],staticClass:"custom-control-input",attrs:{"type":"checkbox","id":"update-password"},domProps:{"checked":Array.isArray(_vm.hasUpdatePassword)?_vm._i(_vm.hasUpdatePassword,null)>-1:(_vm.hasUpdatePassword)},on:{"change":function($event){var $$a=_vm.hasUpdatePassword,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.hasUpdatePassword=$$a.concat([$$v]))}else{$$i>-1&&(_vm.hasUpdatePassword=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.hasUpdatePassword=$$c}}}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":"update-password"}},[_vm._v("Ändra lösenordet?")])]):_vm._e(),(_vm.hasUpdatePassword)?_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.password),expression:"user.password"}],staticClass:"form-control",class:{
              'is-invalid':
                _vm.submitStatus == 'ERROR' && _vm.$v.user.password.$error,
            },attrs:{"type":"password","id":"password","placeholder":"Lösenord"},domProps:{"value":(_vm.user.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "password", $event.target.value)}}}),(!_vm.$v.user.password.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Du måste ange lösenord ")]):_vm._e(),(!_vm.$v.user.password.min)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Ange minst 8 tecken ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"form-group"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.accesslevel),expression:"user.accesslevel"}],staticClass:"form-control",class:{
              'is-invalid':
                _vm.submitStatus == 'ERROR' && _vm.$v.user.accesslevel.$error,
            },on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.user, "accesslevel", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.accesslevelChanged()}]}},[_c('option',{domProps:{"value":null}},[_vm._v("Välj roll")]),_c('option',{attrs:{"value":"admin"}},[_vm._v("Admin")]),_c('option',{attrs:{"value":"office"}},[_vm._v("Kontor")]),_c('option',{attrs:{"value":"user"}},[_vm._v("Användare")]),_c('option',{attrs:{"value":"lf"}},[_vm._v("Länsförsäkringar")]),_c('option',{attrs:{"value":"anticimex"}},[_vm._v("Anticimex")])]),(!_vm.$v.user.accesslevel.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Du måste välja roll ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.education),expression:"user.education"}],staticClass:"form-control",attrs:{"id":"education","rows":"3","placeholder":"Utbildning"},domProps:{"value":(_vm.user.education)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "education", $event.target.value)}}})]),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"custom-control custom-switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.smslogin),expression:"user.smslogin"}],staticClass:"custom-control-input",attrs:{"type":"checkbox","id":"sms-inloggning","disabled":_vm.user.mobilePhone == null ||
                _vm.user.mobilePhone == '' ||
                _vm.user.accesslevel == 'lf' ||
                _vm.user.accesslevel == 'anticimex'},domProps:{"checked":Array.isArray(_vm.user.smslogin)?_vm._i(_vm.user.smslogin,null)>-1:(_vm.user.smslogin)},on:{"change":function($event){var $$a=_vm.user.smslogin,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.user, "smslogin", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.user, "smslogin", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.user, "smslogin", $$c)}}}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":"sms-inloggning"}},[_vm._v("SMS-inloggning")])])])])],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-8"},[_c('h3',[_vm._v("Användare")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("#")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Namn")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("E-post")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Roll")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Inloggning")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"alert alert-secondary",attrs:{"role":"alert"}},[_vm._v(" Ingen användare hittades ")])])}]

export { render, staticRenderFns }