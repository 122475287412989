<template>
  <div class="customers">
    <navbar headertext="Kunder" />
    <div class="content p-4">
      <div class="row">
        <div class="col-md-10">
          <div class="card mb-3 header">
            <div class="card-body">
              <input
                autocomplete="off"
                type="text"
                class="form-control form-control-lg"
                id="keyword"
                placeholder="Sök kund..."
                v-model="keyword"
                @keyup="searchCustomer"
                ref="keyword"
              />
            </div>
          </div>
        </div>
        <div class="col-md-2 text-right">
          <button
            id="show-modal"
            class="btn btn-info mb-3 btn-custom"
            style="background-color: #39796e"
            @click="openModal()"
          >
            <font-awesome-icon icon="plus-circle" /> Ny kund
          </button>
        </div>
      </div>
      <div class="customers-list">
        <template v-if="customers && customers.length > 0">
          <div class="card" id="customers-table">
            <div class="card-body">
              <div class="table-responsive table-striped">
                <table class="table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">Namn</th>
                      <th scope="col">Adress</th>
                      <th scope="col">Postnr</th>
                      <th scope="col">Ort</th>
                      <th scope="col">Telefon</th>
                      <th scope="col">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    <customer
                      v-for="(customer, index) in customers"
                      :key="index"
                      :customer="customer"
                      :isSelected="{ isSelected: index == selectedIndex }"
                    />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </template>
      </div>
      <pulse-loader :loading="loading"></pulse-loader>
      <div class="modal">
        <b-modal
          id="modal-center modal-lg"
          size="lg"
          centered
          title="Ny kund"
          ref="modal-customer"
          :hide-footer="true"
        >
          <div
            class="alert alert-success"
            role="alert"
            v-if="submitStatus == 'OK' && successMessage != null"
          >
            {{ successMessage }}
          </div>
          <div class="form-row">
            <div class="form-group col-md-5">
              <input
                type="text"
                class="form-control"
                id="firstName"
                placeholder="Förnamn"
                v-model.trim="$v.customer.firstName.$model"
                :class="{
                  'is-invalid':
                    submitStatus == 'ERROR' && !$v.customer.firstName.required,
                }"
              />
              <div
                class="invalid-feedback"
                v-if="!$v.customer.firstName.required"
              >
                Ange förnamn
              </div>
            </div>
            <div class="form-group col-md-7">
              <input
                type="text"
                class="form-control"
                id="lastName"
                placeholder="Efternamn"
                v-model.trim="$v.customer.lastName.$model"
                :class="{
                  'is-invalid':
                    submitStatus == 'ERROR' && !$v.customer.lastName.required,
                }"
              />
              <div
                class="invalid-feedback"
                v-if="!$v.customer.lastName.required"
              >
                Ange efternamn
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-5">
              <input
                type="text"
                class="form-control"
                id="pnr"
                placeholder="Personnummer"
                v-model="customer.pnr"
              />
            </div>
            <div class="form-group col-md-7">
              <input
                type="text"
                class="form-control"
                id="orgnr"
                placeholder="Org.nr"
                v-model="customer.orgnr"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-5">
              <input
                type="text"
                class="form-control"
                id="companyName"
                placeholder="Företagsnamn"
                v-model="customer.companyName"
              />
            </div>
            <div class="form-group col-md-5">
              <input
                type="text"
                class="form-control"
                id="careOf"
                placeholder="C/O"
                v-model="customer.careOf"
              />
            </div>
            <div class="form-group col-md-2">
              <input
                type="text"
                class="form-control"
                id="customerNr"
                placeholder="Kundnr"
                v-model="customer.customerNr"
              />
            </div>
          </div>
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              id="street"
              placeholder="Adress"
              v-model="customer.street"
            />
          </div>
          <div class="form-row">
            <div class="form-group col-md-5">
              <input
                type="text"
                class="form-control"
                id="postcode"
                placeholder="Postnummer"
                v-model="customer.postcode"
              />
            </div>
            <div class="form-group col-md-7">
              <input
                type="text"
                class="form-control"
                id="city"
                placeholder="Ort"
                v-model="customer.city"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-5">
              <input
                type="text"
                class="form-control"
                id="homePhone"
                placeholder="Hemtelefon"
                v-model="customer.homePhone"
              />
            </div>
            <div class="form-group col-md-7">
              <input
                type="text"
                class="form-control"
                id="mobilePhone"
                placeholder="Mobiltelefon"
                v-model="customer.mobilePhone"
              />
            </div>
          </div>
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              id="email"
              placeholder="E-mail"
              v-model="customer.email"
            />
          </div>
          <footer class="modal-footer">
            <b-button variant="secondary" @click="closeModal()"
              >Avbryt</b-button
            >
            <b-button
              id="save-customer"
              variant="success"
              class="btnSmGr"
              @click="saveCustomer()"
              :disabled="submitStatus === 'PENDING'"
              >Spara</b-button
            >
          </footer>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Navbar from "@/components/Navbar.vue";
import Customer from "@/components/Customer.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { required } from "vuelidate/lib/validators";

export default {
  name: "customer-create",
  data() {
    return {
      selectedIndex: 0,
      customer: {
        firstName: null,
        lastName: null,
        pnr: null,
        orgnr: null,
        homePhone: null,
        mobilePhone: null,
        street: null,
        postcode: null,
        city: null,
        companyName: null,
        customerNr: null,
        careOf: null,
        email: null,
      },
      submitStatus: null,
      successMessage: null,
      keyword: "",
      limit: 20,
    };
  },

  validations: {
    customer: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
    },
  },

  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      apiKey: (state) => state.auth.apiKey,
      hidden: (state) => state.sidebar.hidden,
      visible: (state) => state.sidebar.visible,
      customers: (state) => state.client.customers,
      loading: (state) => state.client.loading,
      authenticated: (state) => state.auth.authenticated,
    }),
  },

  components: {
    Navbar,
    Customer,
    PulseLoader,
  },

  mounted() {
    this.checkUrl();
    this.fetchCustomers();
  },

  methods: {
    openModal() {
      this.$refs["modal-customer"].show();
    },

    closeModal() {
      this.$refs["modal-customer"].hide();
    },

    checkUrl() {
      const searchQuery = this.$route.query;
      if (Object.keys(searchQuery).length > 0) {
        this.keyword = searchQuery.search;
      }
    },

    fetchCustomers() {
      this.$store.dispatch("client/fetchCustomers", {
        keyword: this.keyword,
        limit: this.limit,
      });
    },

    searchCustomer() {
      if (event.keyCode == 38 && this.selectedIndex > 0) {
        this.selectedIndex--;
      } else if (
        event.keyCode == 40 &&
        this.selectedIndex < this.customers.length - 1
      ) {
        this.selectedIndex++;
      } else if (event.keyCode == 13) {
        if (
          this.selectedIndex == undefined ||
          this.customers == undefined ||
          this.customers[this.selectedIndex] == undefined
        ) {
          return;
        }
        this.$router.push({
          name: "customer-show",
          params: {
            id: this.customers[this.selectedIndex].id,
            index: this.selectedIndex,
          },
          query: { keyword: this.keyword },
        });
      } else if (
        this.keyword != null &&
        this.keyword != "" &&
        this.keyword.length > 2
      ) {
        var keyword = this.keyword.toLowerCase();
        var count = 50;
        this.$router.push({ query: { search: keyword } });
        this.$store.dispatch("client/fetchCustomers", {
          keyword: keyword,
          limit: count,
        });
      } else if (this.keyword.length == 0) {
        this.$router.push({ query: {} });
        this.fetchCustomers();
      }
    },

    saveCustomer() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";

        let homePhone = "";
        let mobilePhone = "";
        if (this.customer.homePhone) {
          let phone = this.customer.homePhone;
          homePhone = phone.replace(/[^\w\d]/gi, "");
        }
        if (this.customer.mobilePhone) {
          let phone = this.customer.mobilePhone;
          mobilePhone = phone.replace(/[^\w\d]/gi, "");
        }

        var postdata = new FormData();
        postdata.append("firstName", this.customer.firstName);
        postdata.append("lastName", this.customer.lastName);
        postdata.append("pnr", this.customer.pnr);
        postdata.append("orgnr", this.customer.orgnr);
        postdata.append("homePhone", homePhone);
        postdata.append("mobilePhone", mobilePhone);
        postdata.append("street", this.customer.street ?? "");
        postdata.append("city", this.customer.city ?? "");
        postdata.append("postcode", this.customer.postcode ?? "");
        postdata.append("companyName", this.customer.companyName ?? "");
        postdata.append("customerNr", this.customer.customerNr ?? "");
        postdata.append("careOf", this.customer.careOf ?? "");
        postdata.append("email", this.customer.email ?? "");
        this.$axios
          .post(this.baseUrl + "api/customer.php?key=" + this.apiKey, postdata)
          .then((response) => {
            console.log(response.data);
            if (response.data.status == "ok") {
              this.customer = {
                firstName: null,
                lastName: null,
                pnr: null,
                orgnr: null,
                homePhone: null,
                mobilePhone: null,
                street: null,
                postcode: null,
                city: null,
                careOf: null,
                companyName: null,
                customerNr: null,
                email: null,
              };
              this.closeModal();
              this.submitStatus = "OK";
              this.successMessage = "En kund har lagts till";
              this.fetchCustomers();
            } else {
              this.submitStatus = "ERROR";
              this.$store.dispatch("auth/logout");
              // this.$router.push({ name: "login" });
            }
          })
          .catch((error) => {
            console.log(error);
            this.submitStatus = "ERROR";
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.customers {
  .header {
    background: #f1f1f1;
  }
  .table {
    td {
      vertical-align: middle;
    }
  }
}
</style>
