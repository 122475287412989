<template>
  <div class="economy w-100" style="background-color: #fafafa">
    <navbar headertext="Premieuträkning" />
    <div class="content p-4">
      <CustomerHeader :customer="customer" v-if="customer != null" />
      <div class="box-info">
        <div class="row">
          <div class="col-md-2">
            <input
              type="number"
              placeholder="Försäkringsnr"
              v-model="insuranceNr"
              @keyup="checkInsuranceNr()"
              class="form-control"
              id="insuranceNr"
            />
          </div>
          <div class="col-md-2">
            <input
              type="number"
              placeholder="Ange år"
              class="form-control"
              id="year"
              @change="checkInsuranceNr()"
              v-model="year"
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-12">
            <h3>Aktuellt år</h3>
            <table
              class="table payments"
              v-if="payments != null && payments.length > 0"
            >
              <thead>
                <tr>
                  <th>Produkt</th>
                  <th>Objekt</th>
                  <th colspan="2">Giltighetstid</th>
                  <th>Faktorvärde</th>
                  <th colspan="2">Faktor</th>
                  <th colspan="2">Objektvärde</th>
                  <th colspan="2">Risk</th>
                  <th colspan="2">Avgift</th>
                  <th colspan="2">Årsfaktor</th>
                  <th>Premie</th>
                  <th>Kontroll</th>
                  <th>Bonusgr.</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(p, index) in payments" :key="index">
                  <td>{{ p.product }}</td>
                  <td>
                    {{ p.title }}
                  </td>
                  <td>
                    {{
                      p.startDate.date.substring(5, 10) == "01-01" &&
                      p.endDate.date.substring(5, 10) == "12-31"
                        ? "Helår"
                        : p.startDate.date.substring(0, 10)
                    }}
                  </td>
                  <td>
                    {{
                      p.startDate.date.substring(5, 10) == "01-01" &&
                      p.endDate.date.substring(5, 10) == "12-31"
                        ? ""
                        : " -- " + p.endDate.date.substring(0, 10)
                    }}
                  </td>
                  <td>
                    {{
                      p.factorValue ? p.factorValue.toLocaleString("sv") : ""
                    }}
                  </td>
                  <td>({{ p.factor }}</td>
                  <td>*</td>
                  <td>
                    {{
                      p.objectValue != null
                        ? p.objectValue.toLocaleString("sv")
                        : ""
                    }}
                  </td>
                  <td>*</td>
                  <td>{{ p.risk / 100 }}</td>
                  <td>+</td>
                  <td>{{ p.fee }})</td>
                  <td>*</td>
                  <td>{{ p.yf.toFixed(3) }}</td>
                  <td>=</td>
                  <td>{{ p.premie }}</td>
                  <td>
                    {{
                      p.smallestPremie != null &&
                      p.smallestPremie > 0 &&
                      p.smallestPremie >
                        p.factor * p.objectValue * (p.risk / 100) + p.fee
                        ? Math.round(p.smallestPremie * p.yf)
                        : Math.round(
                            (p.factor * p.objectValue * (p.risk / 100) +
                              p.fee) *
                              p.yf
                          )
                    }}
                  </td>
                  <td>
                    {{ p.bonus ? p.premie : 0 }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td><b>Summa</b></td>
                  <td colspan="14">&nbsp;</td>
                  <td>
                    <b>{{ balance.toLocaleString("sv") }}</b>
                  </td>
                  <td>
                    <b>{{ sum.toLocaleString("sv") }}</b>
                  </td>
                  <td>
                    <b>{{ bonusSum.toLocaleString("sv") }}</b>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-12">
            <h3>Föregående år</h3>
            <table
              class="table"
              v-if="lastPayments != null && lastPayments.length > 0"
            >
              <thead>
                <tr>
                  <th>Produkt</th>
                  <th>Objekt</th>
                  <th colspan="2">Giltighetstid</th>
                  <th>Faktorvärde</th>
                  <th colspan="2">Faktor</th>
                  <th colspan="2">Objektvärde</th>
                  <th colspan="2">Risk</th>
                  <th colspan="2">Avgift</th>
                  <th colspan="2">Årsfaktor</th>
                  <th>Premie</th>
                  <th>Kontroll</th>
                  <th>Bonusgr.</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(p, index) in lastPayments" :key="index">
                  <td>{{ p.product }}</td>
                  <td>
                    {{ p.title }}
                  </td>
                  <td>
                    {{
                      p.startDate.date.substring(5, 10) == "01-01" &&
                      p.endDate.date.substring(5, 10) == "12-31"
                        ? "Helår"
                        : p.startDate.date.substring(0, 10)
                    }}
                  </td>
                  <td>
                    {{
                      p.startDate.date.substring(5, 10) == "01-01" &&
                      p.endDate.date.substring(5, 10) == "12-31"
                        ? ""
                        : " -- " + p.endDate.date.substring(0, 10)
                    }}
                  </td>
                  <td>
                    {{
                      p.factorValue ? p.factorValue.toLocaleString("sv") : ""
                    }}
                  </td>
                  <td>({{ p.factor }}</td>
                  <td>*</td>
                  <td>
                    {{
                      p.objectValue != null
                        ? p.objectValue.toLocaleString("sv")
                        : ""
                    }}
                  </td>
                  <td>*</td>
                  <td>{{ p.risk / 100 }}</td>
                  <td>+</td>
                  <td>{{ p.fee }})</td>
                  <td>*</td>
                  <td>{{ p.yf.toFixed(3) }}</td>
                  <td>=</td>
                  <td>{{ p.premie }}</td>
                  <td>
                    {{
                      p.smallestPremie != null &&
                      p.smallestPremie > 0 &&
                      p.smallestPremie >
                        p.factor * p.objectValue * (p.risk / 100) + p.fee
                        ? Math.round(p.smallestPremie * p.yf)
                        : Math.round(
                            (p.factor * p.objectValue * (p.risk / 100) +
                              p.fee) *
                              p.yf
                          )
                    }}
                  </td>
                  <td>
                    {{ p.bonus ? p.premie : 0 }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td><b>Summa</b></td>
                  <td colspan="14">&nbsp;</td>
                  <td>
                    <b>{{ lastBalance.toLocaleString("sv") }}</b>
                  </td>
                  <td>
                    <b>{{ lastSum.toLocaleString("sv") }}</b>
                  </td>
                  <td>
                    <b>{{ lastBonusSum.toLocaleString("sv") }}</b>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-12">
            <h3>Bonus och återbäring</h3>
            <ul>
              <li>
                Bonusgrundande bruttopremie föregående år:
                {{ lastBonusSum.toLocaleString("sv") }}
              </li>
              <li>
                Försäkringen äldre än 3 år: {{ bonusTime ? "ja" : "nej" }}
              </li>
              <li>3 skadefria år: {{ bonusDamage ? "ja" : "nej" }}</li>
              <li v-if="bonusTime && bonusDamage">
                Bonus: 0,1 * {{ lastBonusSum.toLocaleString("sv") }} =
                {{ Math.round(lastBonusSum * 0.1).toLocaleString("sv") }}
              </li>
              <li v-else>Ej bonusberättigad.</li>
              <li v-if="lastBonusSum > 0">
                Återbäring: 0,9 * {{ lastBonusSum.toLocaleString("sv") }} =
                {{ Math.round(lastBonusSum * 0.9).toLocaleString("sv") }}
              </li>
              <li v-else>
                Ingen bruttopremie föregående år, ingen återbäring.
              </li>
              <li v-if="bonusTime && bonusDamage && lastBonusSum > 0">
                Totalt: {{ currentBonus.toLocaleString("sv") }}
              </li>
              <li>
                Bonusen får inte vara större än årets bonusgrundande belopp
                {{ bonusSum.toLocaleString("sv") }}
              </li>
              <li v-if="currentBonus > bonusSum">
                Bonusen justeras ner till {{ bonusSum.toLocaleString("sv") }}
              </li>
              <li>
                Årets bruttopremie minus bonus måste vara större än 250:
                {{ balance.toLocaleString("sv") }} -
                {{
                  (currentBonus > bonusSum
                    ? bonusSum
                    : currentBonus
                  ).toLocaleString("sv")
                }}
                =
                {{
                  (
                    balance -
                    (currentBonus > bonusSum ? bonusSum : currentBonus)
                  ).toLocaleString("sv")
                }}
              </li>
              <li v-if="bonusChanged">
                Total bonus och återbäring justerades ner till:
                {{ actualBonus }}
              </li>
              <li>
                Att betala: {{ balance.toLocaleString("sv") }} -
                {{ actualBonus.toLocaleString("sv") }} =
                {{ (balance - actualBonus).toLocaleString("sv") }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import CustomerHeader from "@/components/CustomerHeader.vue";
import { mapState } from "vuex";

var moment = require("moment");
moment.locale("sv");

export default {
  name: "economy-payment",
  components: {
    Navbar,
    CustomerHeader,
  },
  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      hidden: (state) => state.sidebar.hidden,
      apiKey: (state) => state.auth.apiKey,
      accesslevel: (state) => state.auth.accesslevel,
    }),
  },

  data() {
    return {
      customer: null,
      amount: null,
      wrongAmount: true,
      wrongOcr: true,
      sum: 0,
      currentBonus: 0,
      bonusChanged: false,
      actualBonus: 0,
      bonusSum: 0,
      lastSum: 0,
      lastBonusSum: 0,
      lastBalance: 0,
      insuranceNr: null,
      eventType: "addATB",
      date: moment().format("YYYY-MM-DD"),
      payments: [],
      lastPayments: [],
      balance: 0,
      bonusTime: false,
      bonusDamage: false,
      year: moment().format("YYYY"),
      searching: false,
      buttonText: "Spara",
    };
  },

  mounted() {
    if (this.$route.query.insuranceNr != null) {
      this.insuranceNr = this.$route.query.insuranceNr;
      this.getAccount();
    }
  },

  methods: {
    getAccount() {
      this.searching = true;
      this.$axios
        .get(
          this.baseUrl +
            "api/get.php?type=explainPremie&insuranceNr=" +
            this.insuranceNr +
            "&year=" +
            this.year +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.sum = 0;
            this.bonusSum = 0;
            this.bonusChanged = false;
            //            this.customer = response.data.data.customer;
            this.payments = response.data.data.explained;
            this.lastPayments = response.data.data.lastYear;
            this.balance = response.data.data.premie;
            this.lastBalance = response.data.data.lastPremie;
            this.lastSum = 0;
            this.lastBonusSum = 0;
            this.bonusSum = 0;
            this.bonusTime = response.data.data.bonusTime;
            this.bonusDamage = response.data.data.bonusDamage;
            for (var i = 0; i < this.payments.length; i++) {
              var p = this.payments[i];
              var psum = p.factor * p.objectValue * (p.risk / 100) + p.fee;
              if (
                p.smallestPremie != null &&
                p.smallestPremie > 0 &&
                p.smallestPremie > psum
              ) {
                this.sum += Math.round(p.smallestPremie * p.yf);
                if (p.bonus) {
                  this.bonusSum += Math.round(p.smallestPremie * p.yf);
                }
              } else {
                this.sum += Math.round(psum * p.yf);
                if (p.bonus) {
                  this.bonusSum += Math.round(psum * p.yf);
                }
              }
            }
            for (i = 0; i < this.lastPayments.length; i++) {
              p = this.lastPayments[i];
              psum = p.factor * p.objectValue * (p.risk / 100) + p.fee;
              if (
                p.smallestPremie != null &&
                p.smallestPremie > 0 &&
                p.smallestPremie > psum
              ) {
                this.lastSum += Math.round(p.smallestPremie * p.yf);
                if (p.bonus) {
                  this.lastBonusSum += Math.round(p.smallestPremie * p.yf);
                }
              } else {
                this.lastSum += Math.round(psum * p.yf);
                if (p.bonus) {
                  this.lastBonusSum += Math.round(psum * p.yf);
                }
              }
            }
            this.currentBonus = this.lastBonusSum * 0.9;
            if (this.bonusTime && this.bonusDamage) {
              this.currentBonus += this.lastBonusSum * 0.1;
            }
            if (this.currentBonus > this.bonusSum) {
              this.actualBonus = this.bonusSum;
            } else {
              this.actualBonus = this.currentBonus;
            }
            if (this.balance - this.actualBonus < 250) {
              this.actualBonus = this.balance - 250;
              this.bonusChanged = true;
            }
            this.searching = false;
          } else {
            this.wrongOcr = true;
          }
        })
        .catch((error) => {
          this.searching = false;
          this.wrongOcr = true;
          console.log(error);
        });
    },

    checkInsuranceNr() {
      var n = this.insuranceNr;
      this.customer = null;
      if (n.length >= 4 && n.length <= 6) {
        this.getAccount();
      }
    },
  },
};
</script>

<style scoped>
.red {
  background-color: #ffaaaa;
}
.green {
  background-color: lightgreen;
}
.white {
  background-color: white;
}
input {
  margin-bottom: 8px;
}
</style>