<template>
  <div class="terms">
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-12">
            <template v-if="insurances.length > 0">
              <ul class="termlist">
                <li class="term" v-for="(obj, objKey) in objects" :key="objKey">
                  <div class="form-row">
                    <div class="col">
                      <p class="title">
                        {{
                          obj.title == null || obj.title == ""
                            ? "(Inget namn)"
                            : obj.title
                        }}
                      </p>
                    </div>
                    <div class="col">
                      <p class="product-name">
                        {{ obj.productName }} {{ obj.dragging }}
                      </p>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <select
                          :disabled="status !== 'draft'"
                          class="form-control"
                          @change="onTermChanged($event, obj.objectID)"
                          :style="(obj.objectID === objectID) ? warningStyle : ''"
                        >
                          <option value="" selected>Välj villkor</option>
                          <template v-if="terms.length > 0">
                            <option
                              :value="term.termID"
                              v-for="(term, index) in terms"
                              :key="index"
                              :selected="(homeVilla.includes(4) && homeVilla.includes(5)) ? (term.termID == 1 ): (term.termID == obj.termID)"
                            >
                              {{ term.term }}
                            </option>
                          </template>
                        </select>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </template>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <h3>Extra villkor</h3>
        <div class="form-group">
          <textarea
            :disabled="status !== 'draft'"
            class="form-control"
            rows="2"
            placeholder=""
            v-model="extraTerms"
            @keydown="inputChanged('ev')"
            :style="extraTermChanged ? warningStyle : ''"
          ></textarea>
        </div>
        <!-- <div class="form-group" v-if="!isUser">
          <button type="button" class="btn btn-secondary btnGr float-right" :disabled="!extraTermChanged">Spara</button>
        </div> -->
        <h3>Fastighetsbeteckning</h3>
        <div class="form-group">
          <input
            :disabled="status !== 'draft'"
            type="text"
            class="form-control"
            :class="{ 'border-warning': property == null || property.length == 0 }"
            style="border-radius: 4px; width: 274px"
            v-model="property"
            placeholder=""
            @keydown="inputChanged('fb')"
            :style="propertyChanged ? warningStyle : ''"
          />
        </div>
        <div class="col-md-2">
          <button
            :disabled="status !== 'draft' || property == null || property.length == 0 && !(propertyChanged || extraTermChanged)"
            type="button"
            style="width: 274px; margin-left: -15px; margin-top:-5px;"
            class="btn btn-secondary btn-block btnGr"
            @click="saveTerms()"
          >
            Spara
          </button>
        </div>
        <hr />
        <div class="row">
          <div class="col-md-6">
            <h3>Självrisk</h3>
            <!-- :class="{ st: sr != null && sr.length > 0 }" -->
            <p>
              {{
                insurances != null && insurances.length > 0
                  ? insurances[0].sr
                  : ""
              }}
            </p>
          </div>
          <div class="col-md-6">
            <h3>Särskilda självrisker och begränsningar</h3>
            <textarea
              :disabled="status !== 'draft'"
              class="form-control"
              type="text"
              placeholder=""
              v-model="sr"
              @keydown="inputChanged('sr')"
              :style="srChanged ? warningStyle : ''"
            ></textarea>
            <button
              :disabled="status !== 'draft' || !srChanged"
              type="button"
              class="btn btn-secondary btn-block btnGr"
              style="margin-top: 10px; "
              @click="saveSr()"
            >
              Spara
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
export default {
  name: "insurance-terms",
  props: ["insuranceid", "status"],
  data() {
    return {
      firstName: null,
      lastName: null,
      loading: true,
      showModal: false,
      property: null,
      extraTerms: null,
      sr: null,
      homeVilla: [],
      isSaved: false,
      objectID: null,
      warningStyle: {
        background: "#FADBD8",
        borderColor: "#E74C3C"
      },
      extraTermChanged: false,
      propertyChanged: false,
      srChanged: false,
    };
  },

  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      apiKey: (state) => state.auth.apiKey,
      insurances: "insurances",
      terms: "terms"
    }),

    ...mapGetters("auth", ["isUser"]),

    objects() {
      var objs = [];
      if (this.insurances.length > 0) {
        this.insurances.map((insurance) => {
          insurance.objects.map((obj) => {
            obj.productName = insurance.name;
            obj.productID = insurance.productID;
            objs.push(obj);
          });
        });
      }
      return objs;
    },
  },

  mounted() {
    this.loading = false;
    this.$store.dispatch("fetchTerms");
    this.fetchExtraTerms();

    this.checkHomeVilla();
  },


  methods: {
    inputChanged(val) {
      if (val === 'ev') {
        this.extraTermChanged = true;
      } if (val === 'fb') {
        this.propertyChanged = true;
      } else if (val === 'sr') {
        this.srChanged = true;
      }
    },

    checkHomeVilla() {
      this.homeVilla = [];
      if (this.insurances.length > 0) {
        this.insurances.map((insurance) => {
          insurance.objects.map((obj) => {
            this.homeVilla.push(obj.productID)
          });
        });
      }
    },

    getTerm(termID) {
      let term = this.terms.find((term) => term.termID == termID);
      return term ? term.term : "(tom)";
    },

    onTermChanged(event, objectID) {
      this.objectID = objectID;
      const termID = event.target.value;
      this.$axios
        .get(
          this.baseUrl +
            "api/draft.php?type=addTerm&objectID=" +
            objectID +
            "&termID=" +
            termID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.$store.dispatch("fetchAllObjects", this.insuranceid);
            this.objectID = null;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    selected(termID) {
      return this.terms.find((term) => term.termID == termID);
    },

    saveSr() {
      if (this.sr != null) {
        this.loading = true;
        var formData = new FormData();
        formData.append("sr", this.sr);
        this.$axios
          .post(
            this.baseUrl +
              "api/draft.php?type=addSr&insuranceID=" +
              this.insuranceid +
              "&key=" +
              this.apiKey,
            formData
          )
          .then((response) => {
            this.srChanged = false;
            this.loading = false;
            if (response.data.status == "ok") {
              this.fetchExtraTerms();
            }
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
          });
      }
    },

    saveTerms() {
      if (this.extraTerms != null || this.property != null) {
        this.loading = true;
        var formData = new FormData();
        if (this.extraTerms != null) {
          formData.append("extraTerms", this.extraTerms);
        }
        if (this.property != null) {
          formData.append("property", this.property);
        }
        this.$axios
          .post(
            this.baseUrl +
              "api/draft.php?type=addExtraTerms&insuranceID=" +
              this.insuranceid +
              "&key=" +
              this.apiKey,
            formData
          )
          .then((response) => {
            this.extraTermChanged = false;
            this.propertyChanged = false;
            this.loading = false;
            if (response.data.status == "ok") {
              this.isSaved = true;
              this.fetchExtraTerms();
            }
          })
          .catch((error) => {
            this.isSaved = false;
            this.loading = false;
            console.log(error);
          });
      }
    },

    fetchExtraTerms() {
      this.$axios
        .get(
          this.baseUrl +
            "api/get.php?type=extraTerms&insuranceID=" +
            this.insuranceid +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok" && response.data.data != null) {
            this.extraTerms = response.data.data.extraTerms;
            this.property = response.data.data.property;
            this.sr = response.data.data.sr;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.st {
  text-decoration: line-through;
}
.terms {
  ul {
    padding-left: 0;
    margin-bottom: 0;
    li {
      font-size: 15px;
      font-weight: 600;
      background: #f7f7f7;
      list-style: none;
      padding: 20px 25px;
    }
    li:not(:last-child) {
      border-bottom: 1px solid #ddd;
    }
  }
  .draggable-item {
    li:hover {
      border: 2px dashed #f7dc6f;
      cursor: pointer;
      background: #fef9e7;
      padding: 18px 23px;
    }
  }

  .termlist {
    border-top: 1px solid #ddd;
    padding: 0;
    margin: 0;

    .term {
      p {
        margin: 0;
      }
      .title {
        font-weight: 600;
      }
      &:hover {
        background: #f1f1f1;
        ul li {
          background: #fff;
        }
      }
      .term-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      ul {
        margin-top: 10px;
        li {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          background: #f1f1f1;
          .deleteObject {
            color: #e74c3c;
            font-size: 14px;
            cursor: pointer;
          }
        }
      }
    }
    .term.dragging {
      background: #eff9ff;
      border: 2px dashed #ddd;
      border-radius: 4px;
    }
  }
}
</style>
