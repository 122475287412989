<template>
  <div class="create-insurance w-100 fourth">
    <navbar headertext="Skapa försäkring" />
    <div class="content p-4">
      <CustomerHeader
        v-if="customer != null"
        :customer="customer"
        @toggleCustomer="toggleCustomer($event)"
      />
      <div class="row">
        <div class="col-md-4 offset-md-8">
          <NewNote
            v-if="customer != null && users.length > 0 && enableCustomer"
            @update="closeCustomerNote()"
            :enableCustomer="true"
            :customer="customer"
            :users="users"
          />
        </div>
      </div>
      <div class="box-info">
        <div class="row" style="margin: 10px 10px 20px 10px">
          <div class="col-lg-10">
            <h3>Ny försäkring</h3>
          </div>
          <div class="col-lg-2">
            <a @click="$router.go(-1)"
              ><b-button class="btn-light">Avbryt</b-button></a
            >
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mb-4">
            <div>
              <b-card no-body :key="updateKey">
                <b-tabs v-model="tabIndex" pills card>
                  <template #tabs-start>
                    <li
                      role="Utkast"
                      class="nav-item align-self-center"
                      style="color: #5aa196; font-weight: 600; font-size: 14px"
                      v-if="draftStatus === 'draft'"
                    >
                      <font-awesome-icon icon="lock-open" /> Utkast
                    </li>
                    <li
                      role="Utkast"
                      class="nav-item align-self-center"
                      style="color: #5aa196; font-weight: 600; font-size: 14px"
                      v-if="draftStatus === 'proposal'"
                    >
                      <font-awesome-icon icon="lock" /> Offert
                    </li>
                    <li
                      role="Utkast"
                      class="nav-item align-self-center"
                      style="color: #5aa196; font-weight: 600; font-size: 14px"
                      v-if="draftStatus === 'approved'"
                    >
                      <font-awesome-icon icon="lock" /> Godkänd
                    </li>
                  </template>
                  <b-tab title="Produkter" v-if="draftStatus === 'draft'">
                    <template v-slot:title>
                      1. Försäkringar
                    </template>
                    <b-card-text>
                      <products
                        :insuranceid="insuranceID"
                        :status="draftStatus"
                        v-if="hasMounted"
                      />
                    </b-card-text>
                  </b-tab>
                  <b-tab title="Tillval" v-if="draftStatus === 'draft'">
                    <template v-slot:title>
                       2. Produkter
                    </template>
                    <b-card-text>
                      <options
                        :insuranceid="insuranceID"
                        :status="draftStatus"
                        v-if="hasMounted"
                      />
                    </b-card-text>
                  </b-tab>
                  <b-tab title="Objekt" v-if="draftStatus === 'draft'">
                    <template v-slot:title>
                      3. Objekt
                    </template>
                    <b-card-text>
                      <objects
                        :insuranceid="insuranceID"
                        :status="draftStatus"
                        v-if="hasMounted"
                      />
                    </b-card-text>
                  </b-tab>
                  <b-tab
                    title="Villkor"
                    @click="checkHomeVilla()"
                    v-if="draftStatus === 'draft'"
                  >
                    <template v-slot:title>
                      4. Villkor
                    </template>
                    <b-card-text>
                      <terms
                        :insuranceid="insuranceID"
                        :status="draftStatus"
                        v-if="hasMounted"
                        ref="termsRef"
                      />
                    </b-card-text>
                  </b-tab>
                  <b-tab title="Premie" v-if="draftStatus === 'draft'">
                    <template v-slot:title>
                       5. Premie
                    </template>
                    <b-card-text>
                      <premie
                        :insuranceid="insuranceID"
                        :status="draftStatus"
                        v-if="hasMounted"
                      />
                    </b-card-text>
                  </b-tab>
                  <b-tab title="Rådgivning" v-if="draftStatus === 'draft'">
                    <template v-slot:title>
                       6. Rådgivning
                    </template>
                    <b-card-text>
                      <guidelines
                        :insuranceid="insuranceID"
                        :status="draftStatus"
                        v-if="hasMounted"
                      />
                      <!-- <guidelines-offer :insuranceid="insuranceID" :status="draftStatus" v-if="hasMounted" /> -->
                    </b-card-text>
                  </b-tab>
                  <b-tab title="Rådgivning" v-if="draftStatus !== 'draft'">
                    <template v-slot:title>
                       6. Rådgivning
                    </template>
                    <b-card-text>
                      <guidelines-offer
                        :insuranceid="insuranceID"
                        :status="draftStatus"
                        v-if="hasMounted"
                      />
                    </b-card-text>
                  </b-tab>
                  <b-tab title="Giltighet" @click="fetchDraftPremiums()">
                    <template v-slot:title>
                       7. Giltighet
                    </template>
                    <b-card-text>
                      <validity
                        :insuranceid="insuranceID"
                        :premiums="premiums"
                        v-if="hasMounted"
                      />
                    </b-card-text>
                  </b-tab>
                  <b-tab
                    title="Försäkringsbrev"
                    @click="fetchDraftPremiums()"
                    v-if="draftStatus === 'draft'"
                  >
                    <template v-slot:title>
                     8. Försäkringsbrev
                    </template>
                    <b-card-text>
                      <insurance-mail
                        :insuranceid="insuranceID"
                        :premiums="premiums"
                        v-if="hasMounted"
                        :status="draftStatus"
                        @refresh="loadDraftStatus"
                      />
                    </b-card-text>
                  </b-tab>
                  <b-tab
                    title="Försäkringsbrev"
                    @click="fetchDraftPremiums()"
                    v-if="draftStatus !== 'draft'"
                  >
                    <template v-slot:title>
                      <font-awesome-icon icon="envelope" /> Försäkringsbrev
                    </template>
                    <b-card-text>
                      <insurance-mail-offer
                        :insuranceid="insuranceID"
                        :premiums="premiums"
                        v-if="hasMounted"
                        :status="draftStatus"
                        @click="fetchDraftStatus()"
                        @refresh="loadDraftStatus"
                      />
                    </b-card-text>
                  </b-tab>
                </b-tabs>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal värderingsformulär -->
    <div class="modal">
      <b-modal
        id="modal-1"
        size="lg"
        :title="'Värderingsformulär'"
        v-model="showModal"
      >
        <template v-if="evaluationForms.length > 0">
          <div
            class="form-group"
            v-for="(ef, efKey) in evaluationForms"
            :key="efKey"
          >
            <label>{{ ef.question }}</label>
            <template v-if="ef.options.length == 0">
              <input
                type="text"
                class="form-control"
                v-model="ef.answer"
                v-if="ef.options.length == 0"
              />
            </template>
            <template v-if="ef.options.length > 0">
              <div
                class="form-check"
                v-for="(option, optionKey) in ef.options"
                :key="optionKey"
              >
                <input
                  class="form-check-input"
                  type="radio"
                  :id="'option' + option.optionID"
                  :name="'option' + ef.formID"
                  :value="option.points"
                  @change="onOptionChanged(ef, optionKey)"
                />
                <label
                  class="form-check-label"
                  :for="'option' + option.optionID"
                  >{{ option.optionName }}</label
                >
              </div>
            </template>
          </div>
        </template>
        <template v-slot:modal-footer="{ cancel }">
          <b-button variant="secondary" @click="cancel()">Avbryt</b-button>
          <b-button variant="success" @click="saveEvaluationForms()"
            >Spara</b-button
          >
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import NewNote from "@/components/NewNote.vue";
import Navbar from "@/components/Navbar.vue";
// import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { mapActions, mapState } from "vuex";
import Guidelines from "@/views/insurance/Guidelines.vue";
import Objects from "@/views/insurance/Objects.vue";
import Options from "@/views/insurance/Options.vue";
import Premie from "@/views/insurance/Premie.vue";
import Products from "@/views/insurance/Products.vue";
import CustomerHeader from "@/components/CustomerHeader.vue";
import Terms from "@/views/insurance/Terms.vue";
import Validity from "@/views/insurance/Validity.vue";
import InsuranceMail from "@/views/insurance/InsuranceMail.vue";
import GuidelinesOffer from "@/views/insurance/GuidelinesOffer.vue";
import InsuranceMailOffer from "@/views/insurance/InsuranceMailOffer.vue";
export default {
  name: "create-insurance",
  data() {
    return {
      enableCustomer: false,
      users: [],
      modalShow: false,
      products: [],
      subproducts: [],
      product: null,
      selectedProducts: [],
      customers: [],
      customer: null,
      insurances: [],
      selectedProductKey: null,
      selectedParentKey: null,
      objectName: null,
      selectOption: "products",
      productObjectName: null,
      dragging: false,
      extensions: ["png", "jpg", "jpeg", "pdf"],
      files: [],
      errorMessage: null,
      dropzoneOptions: {
        url: "/api/upload.php",
        thumbnailWidth: 150,
        maxFilesize: 5.0,
      },
      insuranceID: null,
      objectLists: [
        {
          itemID: 1,
          name: "Hus",
          selected: false,
        },
        {
          itemID: 2,
          name: "Person",
          selected: false,
        },
        {
          itemID: 3,
          name: "Föremål",
          selected: false,
        },
      ],
      draggable: false,
      terms: [],
      guidelines: [
        {
          guidelineID: 1,
          guideline: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
        },
        {
          guidelineID: 2,
          guideline: "Soluta aliquam ipsum excepturi quaerat accusantium ex",
        },
        { guidelineID: 3, guideline: "Impedit necessitatibus fugit" },
        {
          guidelineID: 4,
          guideline: "Nemo facilis iure numquam tempore minus",
        },
      ],
      selectedGuidelines: [],
      guideline: null,
      evaluationForms: [],
      showModal: false,
      hasMounted: false,
      premiums: [],
      draftStatus: null,
      updateKey: 0,
      tabIndex: 0,
    };
  },

  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      apiKey: (state) => state.auth.apiKey,
    }),

    groupProducts() {
      return this.products.reduce((accumulator, obj) => {
        let key = obj["category"];
        if (!accumulator[key]) {
          accumulator[key] = [];
        }
        this.$set(accumulator[key], "selected", false);
        accumulator[key].push(obj);
        return accumulator;
      }, {});
    },

    objects() {
      var objs = [];
      this.insurances.map((insurance) => {
        insurance.objects.map((obj) => {
          obj.productName = insurance.name;
          obj.productID = insurance.productID;
          objs.push(obj);
        });
      });
      return objs;
    },
  },

  watch: {
    // selectOption: function () {
    //   this.sortSelectedProducts();
    // },

    evaluationForms: function () {
      this.evaluationForms.map((efs) => {
        efs.answer = null;
        efs.options.map((option) => (option.selected = null));
      });
    },
  },

  mounted() {
    this.fetchUsers();
    this.fetchProducts();
    this.fetchSubProducts();

    const insuranceID = this.$route.query.insuranceID;
    if (insuranceID) {
      this.insuranceID = parseInt(insuranceID);
      this.fetchDraftStatus();
      this.fetchCustomer();
      this.$store.dispatch("fetchInsuranceGuidelines", this.insuranceID);
      this.$store.dispatch("fetchExtraGuidelines", this.insuranceID);
      this.hasMounted = true;
    }

    this.fetchSelectedProducts(this.insuranceID);
  },

  components: {
    NewNote,
    Navbar,
    Guidelines,
    Objects,
    Options,
    Premie,
    Products,
    Terms,
    CustomerHeader,
    Validity,
    InsuranceMail,
    GuidelinesOffer,
    InsuranceMailOffer,
  },

  methods: {
    ...mapActions("product", ["fetchProducts", "fetchSubProducts", "fetchSelectedProducts"]),

    toggleCustomer(value) {
      this.enableCustomer = value;
    },
    closeCustomerNote() {
      this.enableCustomer = false;
      this.fetchCustomer();
    },
    fetchUsers() {
      this.$axios
        .get(this.baseUrl + "api/get.php?type=users&key=" + this.apiKey)
        .then((response) => {
          if (response.data.status == "ok") {
            this.users = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    startDrag(event, ol) {
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.setData("itemID", ol.itemID);
    },
    onDrop(event, insurance) {
      const itemID = event.dataTransfer.getData("itemID");
      const item = this.objectLists.find((item) => item.itemID == itemID);
      item.selected = true;

      let draftProductID = insurance.draftProductID;

      this.$axios
        .get(
          this.baseUrl +
            "api/draft.php?type=createObject&draftProductID=" +
            draftProductID +
            "&key=" +
            this.apiKey
        )
        .then(() => {
          this.fetchAllObjects();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onDragging(insurance) {
      let selectedIndex = this.insurances.indexOf(insurance);
      this.insurances[selectedIndex].dragging = true;
    },

    onLeaving(insurance) {
      let selectedIndex = this.insurances.indexOf(insurance);
      this.insurances[selectedIndex].dragging = false;
    },

    fetchDraftStatus() {
      this.$axios
        .get(
          this.baseUrl +
            "api/get.php?type=draftEditable&insuranceID=" +
            this.insuranceID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.draftStatus = response.data.data.draftStatus;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deleteObject(obj) {
      this.$axios
        .get(
          this.baseUrl +
            "api/draft.php?type=deleteObject&objectID=" +
            obj.objectID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.fetchAllObjects();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    updateObject(obj) {
      const objID = obj.objectID;
      const title = obj.title;
      console.log(obj);
      this.$axios
        .get(
          this.baseUrl +
            "api/draft.php?type=updateObject&objectID=" +
            objID +
            "&title=" +
            title +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    fetchAllObjects() {
      this.$axios
        .get(
          this.baseUrl +
            "api/get.php?type=draftObjects&insuranceID=" +
            this.insuranceID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.parseObjects(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    parseObjects(products) {
      this.insurances = [];
      products.forEach((product) => {
        this.insurances.push({
          productID: product.productID,
          name: product.name,
          draftProductID: product.draftProductID,
          objects: product.objects,
          evaluationTypeID: product.evaluationTypeID,
          evaluationFormID: product.evaluationFormID,
          dragging: false,
        });
        if (product.subProducts.length > 0) {
          product.subProducts.forEach((sp) => {
            this.insurances.push({
              productID: sp.productID,
              parentID: product.productID,
              name: sp.name,
              draftProductID: sp.draftProductID,
              objects: sp.objects,
              evaluationTypeID: product.evaluationTypeID,
              evaluationFormID: product.evaluationFormID,
              dragging: false,
            });
          });
        }
      });
      this.insurances.map((insurance) => insurance.objects.unshift());

      console.log(this.insurances);
    },

    sortSelectedProducts() {
      this.selectedProducts.forEach((sp) => {
        this.$set(sp, "children", []);
        this.$set(sp, "files", []);
        this.$set(sp, "draggable", false);
        this.subproducts.forEach((product) => {
          if (sp.productID == product.parentID) {
            this.$set(product, "selected", false);
            this.$set(product, "draggable", true);
            sp.children.push(product);
          }
        });
      });
    },

    groupBy(objectArray, property) {
      return objectArray.reduce((accumulator, obj) => {
        let key = obj[property];
        if (!accumulator[key]) {
          accumulator[key] = [];
        }
        this.$set(accumulator[key], "selected", false);
        accumulator[key].push(obj);
        return accumulator;
      }, {});
    },

    selectProduct(parentKey, productKey) {
      let childProduct = this.selectedProducts[parentKey].products[productKey];
      this.$set(childProduct, "selected", true);
    },

    isProductSelected(product) {
      return this.selectedProducts.find(
        (sp) => sp.productID === product.productID
      );
    },

    isSubProductSelected(sp) {
      sp.children.forEach((child) => {
        sp.subProducts.forEach((sPro) => {
          if (sPro.productID == child.productID) {
            child.selected = true;
          }
        });
      });
    },

    selectedProduct(parentIndex, productIndex) {
      let product = this.products[parentIndex].products[productIndex];

      if (!this.selectedProducts.includes(product)) {
        this.$set(product, "productObjects", []);
        this.selectedProducts.push(product);
        product.selected = true;
      }
    },

    removeSelectedProduct(parentIndex, productIndex) {
      let product = this.products[parentIndex].products[productIndex];

      let indexOf = this.selectedProducts.indexOf(product);

      if (indexOf !== -1) {
        this.$delete(this.selectedProducts, indexOf);
        product.selected = false;
      }
    },

    addProduct(parentKey, productKey) {
      this.selectedProducts[parentKey].products[productKey].selected = true;
      this.$set(
        this.selectedProducts[parentKey].products[productKey],
        "productObjects",
        []
      );
    },

    removeProduct(parentKey, productKey) {
      this.selectedProducts[parentKey].products[productKey].selected = false;
    },

    createObject(parentKey, productKey) {
      this.selectedParentKey = parentKey;
      this.selectedProductKey = productKey;
    },

    saveObject() {
      let product = null;
      if (this.selectedProductKey === null) {
        product = this.selectedProducts[this.selectedParentKey];
      } else {
        product =
          this.selectedProducts[this.selectedParentKey].products[
            this.selectedProductKey
          ];
      }
      product.productObjects.push({
        parentKey: this.selectedParentKey,
        productKey: this.selectedProductKey,
        name: this.productObjectName,
        category: product.name,
        files: [],
      });
      this.productObjectName = null;
    },

    closeObject() {
      this.selectedParentKey = null;
      this.selectedProductKey = null;
    },

    fetchTerms() {
      this.$axios
        .get(this.baseUrl + "api/get.php?type=draftTerms&key=" + this.apiKey)
        .then((response) => {
          if (response.data.status == "ok") {
            this.terms = response.data.data;
          }
        });
    },

    objectStartDragging(event, term) {
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.setData("termID", term.termID);
    },

    onObjectDrop(event, obj) {
      const termID = event.dataTransfer.getData("termID");
      const term = this.terms.find((term) => term.termID == termID);

      this.$axios
        .get(
          this.baseUrl +
            "api/draft.php?type=addTerm&objectID=" +
            obj.objectID +
            "&termID=" +
            term.termID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.fetchAllObjects();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getTerm(termID) {
      let term = this.terms.find((term) => term.termID == termID);
      return term.term;
    },

    deleteTerm(obj, termID) {
      this.$swal
        .fire({
          title: "Vill du verkligen radera?",
          text: "Du kommer inte att kunna återställa detta!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ta bort",
          cancelButtonText: "Avbryt",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .get(
                this.baseUrl +
                  "api/draft.php?type=deleteTerm&objectID=" +
                  obj.objectID +
                  "&termID=" +
                  termID +
                  "&key=" +
                  this.apiKey
              )
              .then((response) => {
                if (response.data.status == "ok") {
                  this.fetchAllObjects();
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
    },

    onGuidelinesDragging(event, guideline) {
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.setData("guidelineID", guideline.guidelineID);
    },

    onDropGuideline(event) {
      const guidelineID = event.dataTransfer.getData("guidelineID");
      const guideline = this.guidelines.find(
        (guideline) => guideline.guidelineID == guidelineID
      );

      this.selectedGuidelines.push(guideline);
      this.dragging = false;
    },

    deleteGuideline(guideline) {
      const selectedIndex = this.selectedGuidelines.indexOf(guideline);
      this.$delete(this.selectedGuidelines, selectedIndex);
    },

    addGuideline() {
      if (this.guideline != null) {
        this.selectedGuidelines.push({
          guidelineID: Math.floor(Math.random() * 101),
          guideline: this.guideline,
        });
        this.guideline = null;
      }
    },

    onOptionChanged(evaluationForm, optionIndex) {
      const selectedIndex = this.evaluationForms.indexOf(evaluationForm);
      this.evaluationForms[selectedIndex].options.map((option, index) => {
        if (index === optionIndex) {
          option.selected = true;
        } else {
          option.selected = false;
        }
      });
    },

    saveEvaluationForms() {
      this.evaluationForms.map((efs) => {
        if (efs.options.length > 0) {
          efs.options.map((option) => {
            if (option.selected) {
              console.log(option);
            }
          });
        } else {
          console.log(efs.answer);
        }
      });
    },

    fetchCustomer() {
      this.loading = true;
      let id = this.$route.query.customerID;
      this.$axios
        .get(
          this.baseUrl + "api/getCustomer.php?id=" + id + "&key=" + this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.customer = response.data.customer;
            this.loading = false;
          }
        });
    },

    fetchDraftPremiums() {
      this.$axios
        .get(
          this.baseUrl +
            "api/get.php?type=draftPremie&insuranceID=" +
            this.insuranceID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          //   console.log(response.data.data)
          this.premiums = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    loadDraftStatus(event) {
      this.updateKey++;
      if (event) {
        this.fetchDraftStatus();
      }
      this.tabIndex = 2;
    },

    checkHomeVilla() {
      this.$refs.termsRef.checkHomeVilla();
    },
  },
};
</script>
