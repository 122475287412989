<template>
  <div class="economy w-100" style="background-color: #fafafa">
    <navbar headertext="Transaktioner" />
    <div class="content p-4">
      <CustomerHeader
        :customer="customer"
        v-if="customer != null"
        @toggleCustomer="toggleCustomer($event)"
      />
      <div class="box-info">
        <div class="row mt-3">
          <div class="col-lg-12">
            <div class="row">
              <div class="col-md-10">
                <h3>Ekonomi</h3>
              </div>
              <div class="col-md-2">
                <a
                  type="button"
                  class="btn btn-light float-right"
                  @click="$router.go(-1)"
                  >Avbryt</a
                >
              </div>
            </div>
            <div class="card mt-3">
              <div class="row">
                <div class="col-lg-4 4-offset">
                  <div class="card-body">
                    <div class="form-group">
                      <label for="insuranceNr"
                        ><strong>Försäkringsnr</strong></label
                      >
                      <input
                        type="number"
                        ref="am"
                        placeholder="Försäkringsnr"
                        v-model="insuranceNr"
                        @keyup="checkInsuranceNr()"
                        class="form-control"
                        id="insuranceNr"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 offset-2">
                  <NewNote
                    class="card"
                    style="margin: 10px"
                    v-if="customer && users.length > 0 && enableCustomer"
                    @update="closeCustomerNote()"
                    :enableCustomer="true"
                    :customer="customer"
                    :users="users"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-lg-6">
            <div class="card">
              <div class="card-body">
                <div
                  class="alert alert-danger"
                  role="alert"
                  v-if="this.anledning && this.amount && !this.customer"
                >
                  <p class="m-0">Välj ett försäkringsnummer</p>
                </div>
                <div class="form-group">
                  <label for="anledning">Saldojustering</label>
                  <input
                    type="text"
                    id="anledning"
                    v-model="anledning"
                    class="form-control"
                    placeholder="Anledning"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="number"
                    min="0"
                    v-model="amount"
                    class="form-control"
                    @keyup.13="save()"
                    placeholder="Belopp"
                    id="amount"
                  />
                </div>
                <b-button
                  id="saveBalance"
                  class="btn-block"
                  @click="save()"
                  :disabled="!this.anledning || !this.amount || !this.customer"
                >
                  {{ buttonText }}
                </b-button>
                <div
                  class="mt-2"
                  v-if="payments != null && payments.length > 0"
                >
                  <hr />
                  <table class="table" id="payments">
                    <thead>
                      <tr>
                        <th>Datum</th>
                        <th style="text-align: right">Belopp</th>
                        <th>Text</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(p, index) in payments" :key="index">
                        <td>{{ p.logDate }}</td>
                        <td style="text-align: right">
                          {{ p.amount.toLocaleString("sv") }}
                        </td>
                        <td>{{ p.notes }}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td><b>Saldo</b></td>
                        <td style="text-align: right">
                          <b>{{ balance.toLocaleString("sv") }}</b>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card">
              <div class="card-body">
                <div class="form-group">
                  <label for="selectedDate">Välj datum</label>
                  <datepicker
                    format="yyyy-MM-dd"
                    input-class="form-control"
                    :bootstrap-styling="true"
                    v-model="selectedDate"
                    :monday-first="true"
                    :language="sv"
                    placeholder="Välj datum"
                  ></datepicker>
                </div>
                <div class="form-group">
                  <label for="paymentInterval">Antal gånger per år</label>
                  <select class="form-control" v-model="paymentInterval">
                    <option :value="null">Välj antal gånger per år</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="6">6</option>
                    <option value="12">12</option>
                  </select>
                </div>
                <button
                  class="btn btn-secondary btn-block"
                  id="savePayments"
                  @click="savePaymentsPerYear"
                  :disabled="
                    !this.selectedDate ||
                    !this.insuranceNr ||
                    !this.paymentInterval
                  "
                >
                  Spara
                </button>
                <div class="payments mt-2" v-if="paymentsPerYear.length > 0">
                  <hr />
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Start datum</th>
                        <th>Slut datum</th>
                        <th>Antal gånger per år</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template>
                        <tr>
                          <td>{{ filterPayments.startDate }}</td>
                          <td>{{ filterPayments.endDate }}</td>
                          <td>{{ filterPayments.value }}</td>
                          <td class="actions">
                            <button
                              title="Visa alla"
                              type="button"
                              class="btn btn-link btn-sm"
                              @click="showItems = !showItems"
                            >
                              <font-awesome-icon
                                icon="angle-down"
                                v-if="!showItems"
                              />
                              <font-awesome-icon
                                icon="angle-up"
                                v-if="showItems"
                              />
                            </button>
                          </td>
                        </tr>
                        <template
                          v-if="filterPayments.data.length > 0 && showItems"
                        >
                          <tr
                            v-for="(data, key) in filterPayments.data"
                            :key="key"
                          >
                            <td>{{ data.startDate }}</td>
                            <td>{{ data.endDate }}</td>
                            <td>{{ data.value }}</td>
                            <td>&nbsp;</td>
                          </tr>
                        </template>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import CustomerHeader from "@/components/CustomerHeader.vue";
import { mapState } from "vuex";
import Datepicker from "vuejs-datepicker";
import NewNote from "@/components/NewNote.vue";
import { sv } from "vuejs-datepicker/dist/locale";
// import moment from "moment";

var moment = require("moment");
moment.locale("sv");

export default {
  name: "economy-payment",
  components: {
    Navbar,
    CustomerHeader,
    Datepicker,
    NewNote,
  },

  data() {
    return {
      customer: null,
      amount: null,
      wrongAmount: true,
      users: [],
      wrongOcr: true,
      insuranceNr: "",
      anledning: null,
      date: moment().format("YYYY-MM-DD"),
      payments: [],
      enableCustomer: false,
      balance: 0,
      searching: false,
      buttonText: "Spara",
      sv: sv,
      selectedDate: moment().format("YYYY-MM-DD"),
      paymentInterval: null,
      insuranceNumber: null,
      paymentsPerYear: [],
      showItems: false,
    };
  },

  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      hidden: (state) => state.sidebar.hidden,
      apiKey: (state) => state.auth.apiKey,
      accesslevel: (state) => state.auth.accesslevel,
    }),

    filterPayments() {
      let payment = this.paymentsPerYear[this.paymentsPerYear.length - 1];
      let data = [];
      this.paymentsPerYear.map((ppy, index) => {
        if (this.paymentsPerYear.length - 1 !== index) {
          data.push(ppy);
        }
      });
      payment.data = data;

      return payment;
    },
  },

  mounted() {
    this.setFocus();
    this.fetchUsers();
    if (this.$route.query.insuranceNr != null) {
      this.insuranceNr = this.$route.query.insuranceNr;
      this.getAccount();
    }
  },

  methods: {
    fetchUsers() {
      this.$axios
        .get(this.baseUrl + "api/get.php?type=users&key=" + this.apiKey)
        .then((response) => {
          if (response.data.status == "ok") {
            this.users = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    toggleCustomer(value) {
      this.enableCustomer = value;
    },

    closeCustomerNote() {
      this.enableCustomer = false;
      this.fetchDamage();
    },

    setFocus() {
      this.$refs.am.focus();
    },
    getAccount() {
      this.searching = true;
      this.$axios
        .get(
          this.baseUrl +
            "api/get.php?type=account&insuranceID=" +
            this.insuranceNr +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.customer = response.data.data.customer;
            this.payments = response.data.data.account;
            this.balance = response.data.data.balance;
            this.paymentsPerYear = response.data.data.paymentsPerYear;
            this.searching = false;
          } else {
            this.wrongOcr = true;
          }
        })
        .catch((error) => {
          this.searching = false;
          this.wrongOcr = true;
          console.log(error);
        });
    },

    save() {
      if (this.anledning && this.amount && this.customer) {
        this.buttonText = "Sparar...";
        this.$axios
          .get(
            this.baseUrl +
              "api/edit.php?type=accountAdjustment&insuranceID=" +
              this.customer.insuranceID +
              "&amount=" +
              this.amount +
              "&note=" +
              this.anledning +
              "&key=" +
              this.apiKey
          )
          .then((response) => {
            if (response.data.status == "ok") {
              this.buttonText = "Spara";
              this.anledning = null;
              this.amount = null;
              this.getAccount();
            } else {
              this.buttonText = "Spara";
            }
          })
          .catch((error) => {
            this.searching = false;
            this.wrongOcr = true;
            console.log(error);
          });
      }
    },

    checkInsuranceNr() {
      var n = this.insuranceNr;
      this.customer = null;
      if (n.length >= 4) {
        this.getAccount();
      }
    },

    // api/edit.php?type=paymentsPerYear
    savePaymentsPerYear() {
      if (this.selectedDate && this.insuranceNr && this.paymentInterval) {
        let selectedDate = moment(this.selectedDate).format("YYYY-MM-DD");
        this.$axios
          .get(
            this.baseUrl +
              "api/edit.php?type=paymentsPerYear&date=" +
              selectedDate +
              "&insuranceNr=" +
              this.insuranceNr +
              "&paymentsPerYear=" +
              this.paymentInterval +
              "&key=" +
              this.apiKey
          )
          .then((response) => {
            if (response.data.status == "ok") {
              this.selectedDate = moment().format("YYYY-MM-DD");
              this.paymentInterval = null;
              this.getAccount();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.red {
  background-color: #ffaaaa;
}
.green {
  background-color: lightgreen;
}
.white {
  background-color: white;
}
input {
  margin-bottom: 8px;
}

.table {
  .actions button {
    margin: 0;
    padding: 1px 7px;
    svg {
      font-size: 20px;
    }
  }
}
</style>
