<template>
  <div class="row custheader align-items-center">
    <div class="col-lg-12">
      <div class="row">
        <div class="col-md-2">
          <h4>
            <router-link
              :to="{ name: 'customer-show', params: { id: customer.id } }"
            >
              {{
                customer.category == "co"
                  ? customer.companyName
                  : customer.firstName
              }}
              {{ customer.category == "co" ? "" : customer.lastName }}
              {{
                customer.category == "co" && customer.lastName.length
                  ? "/ " + customer.firstName + " " + customer.lastName
                  : ""
              }}</router-link
            >
          </h4>
        </div>
        <div class="col-md-3">
          <div class="col-md-12">
            <span>
              {{ customer.street }},
              {{ nicePostcode(customer.postcode) }}
              {{ customer.city }}
            </span>
          </div>
          <div class="col-md-12">
            <span>
              Tel:
              {{
                customer.mobilePhone
                  ? nicePhone(customer.mobilePhone) + ", "
                  : ""
              }}{{
                customer.homePhone ? nicePhone(customer.homePhone) + ", " : ""
              }}
            </span>
          </div>
        </div>
        <div class="col-md-3">Kundnummer: {{ customer.customerNr }}</div>
        <div class="col-md-4">
          <div class="row" style="float: right">
            <b-dropdown
              style="margin-left: 10px; margin-right: 4px"
              variant="secondary"
            >
              <template #button-content>
                Hantera kundnr {{ customer.customerNr }}
              </template>
              <b-dropdown-item @click="editCustomer()"
                >Redigera kunduppgifter</b-dropdown-item
              >
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item @click="createInsurance()"
                >Ny försäkring</b-dropdown-item
              >
              <b-dropdown-item @click="toggleMessage()"
                >Skicka meddelande</b-dropdown-item
              >
              <b-dropdown-item @click="toggleNote()"
                >Visa / dölj anteckningar</b-dropdown-item
              >
              <b-dropdown-item @click="deleteCustomer()"
                >Radera kund</b-dropdown-item
              >
            </b-dropdown>
            <b-dropdown
              v-if="insuranceNr"
              variant="outline-button"
              style="
                border: 2px solid #6c757d;
                border-radius: 4px;
                background-color: whitesmoke;
                margin-right: 8px;
              "
            >
              <template #button-content>
                Hantera försäkring
                <strong style="color: #5aa196">{{ insuranceNr }}</strong>
              </template>
              <b-dropdown-item @click="editInsurance()" v-if="!editing"
                >Redigera försäkring</b-dropdown-item
              >
              <b-dropdown-divider></b-dropdown-divider>

              <b-dropdown-item @click="damageManagement(insuranceNr)"
                >Anmäl skada</b-dropdown-item
              >
              <b-dropdown-item @click="transactions()">Ekonomi</b-dropdown-item>
              <b-dropdown-item @click="printInvoice(insuranceNr)"
                >Faktura</b-dropdown-item
              >
              <b-dropdown-item
                target="_blank"
                :href="
                  '/print/rad.php?insuranceNr=' + insuranceNr + '&key=' + apiKey
                "
                >Rådgivning</b-dropdown-item
              >
              <b-dropdown-item @click="$emit('sjalvrisk')"
                >Självrisk</b-dropdown-item
              >
              <b-dropdown-item @click="$emit('letter')">
                Brevtext
              </b-dropdown-item>
              <b-dropdown-item @click="$emit('discount')">
                Rabatt
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { parsePostcode, parsePhone } from "@/_helpers/parser";
import { mapState } from "vuex";

export default {
  name: "customer-header",
  props: ["customer", "insuranceNr", "editing", "damageID"],
  data() {
    return {
      isOpen: false,
      enableCustomer: false,
      enableNote: false,
    };
  },
  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      hidden: (state) => state.sidebar.hidden,
      visible: (state) => state.sidebar.visible,
      apiKey: (state) => state.auth.apiKey,
      accesslevel: (state) => state.auth.accesslevel,
    }),
  },

  watch: {
    visible() {
      if (!this.visible) {
        this.enableNote = false;
      }
    },
  },

  methods: {
    editCustomer() {
      this.$router
        .push({
          name: "customer-edit",
          params: { id: this.customer.id },
        })
        .catch(() => {});
    },

    editDamage() {
      this.$router.push({
        name: "edit-damage",
        params: { damageID: this.damageID },
      });
    },

    deleteCustomer() {
      this.$swal
        .fire({
          icon: "warning",
          title: "Vill du verkligen radera kunden?",
          input: "textarea",
          inputPlaceholder: "Anledning",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Radera",
          cancelButtonText: "Avbryt",
          showLoaderOnConfirm: true,
          preConfirm: (message) => {
            if (message === "" || message === null) {
              this.$swal.showValidationMessage("Du måste ange anledning");
            } else {
              var postdata = new FormData();
              postdata.append("id", this.customer.id);

              return this.$axios
                .post(
                  this.baseUrl + "api/deleteCustomer.php?key=" + this.apiKey,
                  postdata
                )
                .then((response) => {
                  if (response.data.status == "ok") {
                    this.saveNote("Orsak radering av kund: " + message);
                    this.$router.push({ name: "customers" });
                  } else {
                    throw new Error(response.statusText);
                  }
                })
                .catch((error) => {
                  this.$swal.showValidationMessage(
                    `Det gick inte att radera kunden: ${error}`
                  );
                });
            }
          },
          allowOutsideClick: () => !this.$swal.isLoading(),
        })
        .then((result) => {
          if (result.value) {
            this.$swal.fire("Klart!", "Kunden är raderad.", "success");
          }
        });
    },

    createInsurance() {
      this.$axios
        .post(
          this.baseUrl +
            "api/draft.php?type=create&customerID=" +
            this.customer.id +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            let insuranceID = response.data.data.insuranceID;
            this.$router
              .push({
                name: "create-insurance",
                query: {
                  insuranceID: insuranceID,
                  customerID: this.customer.id,
                },
              })
              .catch(() => {});
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    transactions() {
      this.$router
        .push({
          name: "economy-account",
          query: {
            insuranceNr: this.insuranceNr,
          },
        })
        .catch(() => {});
    },

    selectedCustomer() {
      this.isOpen = !this.isOpen;
    },

    toggleMessage() {
      this.enableCustomer = !this.enableCustomer;
      this.$emit("toggleCustomer", this.enableCustomer);
    },

    toggleNote() {
      this.enableNote = !this.enableNote;
      this.$emit("note", this.enableNote);
    },

    nicePostcode(pc) {
      return parsePostcode(pc);
    },

    nicePhone(tfn) {
      return parsePhone(tfn);
    },
    editInsurance() {
      this.$emit("editInsurance", true);
    },
    closeEdit() {
      this.$emit("close", true);
    },
    printInvoice(insuranceNr) {
      this.$emit("invoice", insuranceNr);
    },
    damageManagement(insuranceNr) {
      let id = this.customer.id;
      this.$router.push({
        name: "create-damage",
        params: { insuranceID: insuranceNr, customerID: id },
      });
    },
    saveNote(message) {
      var postdata = new FormData();
      postdata.append("id", this.$route.params.id);
      postdata.append("note", message);
      this.$axios
        .post(this.baseUrl + "api/saveNote.php?key=" + this.apiKey, postdata)
        .then((response) => {
          if (response.data.status == "ok") {
            console.log(response);
          }
        });
    },
  },
};
</script>

<style lang="scss">
.custheader {
  margin: -24px -24px 10px -24px;
  padding: 8px;
  border-bottom: 1px solid #fafafa;
  background-color: white;
}
.vertical-divider {
  border-left: 2px solid #5d5d5d;
}
.divbg {
  background: white;
}
.toolbar {
  img {
    margin: -5px;
    height: 35px;
  }
  button {
    font-size: 15px;
    font-weight: 600;
    border-radius: 25px;
    padding: 3px;
    margin: -8px;
    background-color: white;
  }
}
.btn-icon {
  margin-bottom: 3px;
}
</style>