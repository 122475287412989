<template>
  <div class="customer-show w-100">
    <navbar />
    <div class="content p-4">
      <div class="row">
        <div class="col-md-12">
          <div class="card mb-3 header">
            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control form-control-lg"
                    id="keyword"
                    placeholder="Kund"
                    v-model="keyword"
                    @keyup="searchNotes"
                    ref="keyword"
                  />
                </div>
                <div class="col-md-4">
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control form-control-lg"
                    id="user"
                    placeholder="Antecknat av"
                    v-model="user"
                    @keyup="searchNotes"
                  />
                </div>
                <div class="col-md-4">
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control form-control-lg"
                    id="note"
                    placeholder="Anteckning"
                    v-model="note"
                    @keyup="searchNotes"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <table class="table table-striped" v-if="notes != null && notes.length > 0">
            <thead>
              <tr>
                <th scope="col">Tidpunkt</th>
                <th scope="col">Kund</th>
                <th scope="col">Antecknat av</th>
                <th scope="col">Anteckning</th>
              </tr>
            </thead>
            <tbody>
              <tr id="note" v-for="(message, index) in notes" :key="index">
                <td>{{ message.ts }}</td>
                <td>
                  <!-- <button class="btn btn-link">
                    {{ message.companyName }}{{ message.firstName }} {{ message.lastName }}
                  </button> -->
                  <router-link :to="{ name: 'customer-show', params: {id: message.id}}">
                    {{ message.companyName }}{{ message.firstName }} {{ message.lastName }}
                  </router-link>
                </td>
                <td>{{ message.realname }}</td>
                <td>{{ message.note }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <pulse-loader :loading="loading"></pulse-loader>
    </div>
  </div>
</template>

<script>
import { parsePostcode, parsePhone } from '@/_helpers/parser'
import Navbar from "@/components/Navbar.vue";
import { mapState } from "vuex";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
var moment = require("moment");
moment.locale("sv");

export default {
  name: "customer-notes",
  data() {
    return {
      loading: false,
      error: null,
      keyword: "",
      limit: 100,
      user: "",
      note: ""
    };
  },

  components: {
    Navbar,
    PulseLoader
  },

  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      apiKey: (state) => state.auth.apiKey,
      hidden: (state) => state.sidebar.hidden,
      notes: "notes"
    })
  },

  mounted() {
    this.checkQuery();
    this.fetchNotes();

    this.$refs.keyword.focus();
  },

  methods: {
    checkQuery() {
      var searchQuery = this.$route.query;
      if (Object.keys(searchQuery).length > 0) {
        this.keyword = searchQuery.search ?? "";
        this.user = searchQuery.user ?? "";
        this.note = searchQuery.note ?? "";
      }
    },

    fetchNotes() {
      this.loading = true;
      if (
        this.keyword.length > 0 ||
        this.note.length > 0 ||
        this.user.length > 0
      ) {
        this.$router.push({
          query: { search: this.keyword, note: this.note, user: this.user }
        });
      }
      this.$store
        .dispatch("fetchNotes", {
          keyword: this.keyword,
          user: this.user,
          note: this.note,
          limit: this.limit
        })
        .then(() => {
          this.loading = false;
        })
        .catch(error => {
          console.log(error)
          this.loading = false;
          this.$store.dispatch("auth/logout");
          this.$router.push({ name: "login" });
        });
    },

    convertDate(date) {
      return date.format("D MMM YYYY, HH:mm");
    },

    searchNotes() {
      if (event.keyCode == 38 && this.selectedIndex > 0) {
        this.selectedIndex--;
      } else if (
        event.keyCode == 40 &&
        this.selectedIndex < this.customers.length - 1
      ) {
        this.selectedIndex++;
      } else if (event.keyCode == 13) {
        if (
          this.selectedIndex == undefined ||
          this.customers == undefined ||
          this.customers[this.selectedIndex] == undefined
        ) {
          return;
        }
        this.$router.push({
          name: "customer-show",
          params: {
            id: this.customers[this.selectedIndex].id,
            index: this.selectedInde
          }
        });
      } else if (this.keyword != null && this.keyword != "") {
        var keyword = this.keyword.toLowerCase();
        var count = 100;
        // this.$router.push({
        //   query: { search: keyword, note: this.note, user: this.user }
        // });
        this.$store.dispatch("fetchNotes", {
          keyword: keyword,
          user: this.user,
          note: this.note,
          limit: count
        });
      } else if (this.keyword.length == 0) {
        // this.$router.push({ query: {} });
        this.fetchNotes();
      }
    },

    nicePostcode(pc) {
      return parsePostcode(pc)
    },

    nicePhone(tfn) {
      return parsePhone(tfn)
    }
  }
};
</script>

<style lang="scss" scoped>
.customer-show {
  .header {
    background: #f1f1f1;
  }
  .table {
    td {
      font-size: .96rem;
      a {
        color: #4c4c4c;
        font-weight: 600;
        font-size: .9rem;
      }
    }
    tr:hover {
      td a {
        color: #3498DB;
      }
    }
  }
}
</style>
