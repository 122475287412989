import axios from "../../axios"

const state = {
    guidlines: [],
    guidelineDate: null,
    insuranceGuidelines: [],
    selectedGuidelines: [],
    optionalGuidelines: [],
}

const mutations = {
    FETCH_GUIDELINES(state, payload) {
        state.guidlines = payload
    },

    GUIDELINE_DATE(state, payload) {
        state.guidelineDate = payload
    },

    FILTER_GUIDELINES(state) {
        state.selectedGuidelines = []
        state.optionalGuidelines = []
        state.insuranceGuidelines.forEach((gl) => {
            if (gl.optional == 'n' || gl.selected == 'y') {
                state.selectedGuidelines.push(gl)
            } else if (gl.optional == 'y') {
                state.optionalGuidelines.push(gl)
            }
        })
    },

    FETCH_INSURANCE_GUIDELINES(state, payload) {
        state.insuranceGuidelines = payload
    },

    FETCH_EXTRA_GUIDELINES(state, payload) {
        payload.forEach((egl) => {
            state.selectedGuidelines.push({
                extraGuidelineID: egl.extraGuidelineID,
                insuranceID: egl.insuranceID,
                name: egl.name,
                optional: 'y',
                selected: 'y'
            })
        })
    },
}

const actions = {
    fetchGuideLines({ rootState, commit }, productID) {
        return new Promise((resolve, reject) => {
            axios.get(rootState.baseUrl + 'api/get.php?type=guidelines&productID=' + productID + '&key=' + rootState.auth.apiKey)
                .then((response) => {
                    if (response.data.status == 'ok') {
                        commit('FETCH_GUIDELINES', response.data)
                        resolve(response.data)
                    }
                })
                .catch((error) => {
                    commit('ERRORS', error)
                    reject(error)
                })
        })
    },

    fetchInsuranceGuidelines({ rootState, commit }, insuranceID) {
        return new Promise((resolve, reject) => {
            axios.get(rootState.baseUrl + 'api/get.php?type=guidelines&insuranceID=' + encodeURIComponent(insuranceID) + '&key=' + encodeURIComponent(rootState.auth.apiKey))
                .then((response) => {
                    if (response.data.status == 'ok') {
                        commit('FETCH_INSURANCE_GUIDELINES', response.data.data)
                        commit('GUIDELINE_DATE', response.data.guidelineDate)
                        commit('FILTER_GUIDELINES')
                        resolve(response.data.data)
                    }
                })
                .catch((error) => {
                    commit('ERRORS', error)
                    reject(error)
                })
        })
    },

    fetchExtraGuidelines({ rootState, commit }, insuranceID) {
        return new Promise((resolve, reject) => {
            axios.get(rootState.baseUrl + 'api/get.php?type=extraGuidelines&insuranceID=' + insuranceID + '&key=' + rootState.auth.apiKey)
                .then((response) => {
                    if (response.data.status == 'ok') {
                        commit('FETCH_EXTRA_GUIDELINES', response.data.data)
                        resolve(response.data.data)
                    }
                })
                .catch((error) => {
                    commit('ERRORS', error)
                    reject(error)
                })
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}