<template>
	<div class="about w-100">
		<navbar />
		<div class="content p-4">
			<h3>This is an about page</h3>
			<p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tempora, eum. Eligendi possimus saepe impedit illum, perspiciatis magni debitis vel esse molestiae optio dignissimos inventore beatae repellat. Reiciendis quaerat vero animi.</p>
		</div>
	</div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
export default {
  name: 'about',

  components: {
    Navbar
  }
}
</script>
