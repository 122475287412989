const subscribe = (store) => {
    store.subscribe((mutation) => {
        if (mutation.type == 'auth/AUTH_LOGIN') {
            localStorage.setItem('_fsfe_apiKey', JSON.stringify(mutation.payload.key))
            localStorage.setItem('_fsfe_realname', JSON.stringify(mutation.payload.realname))
            localStorage.setItem('_fsfe_accesslevel', JSON.stringify(mutation.payload.accesslevel))
            localStorage.setItem('_fsfe_editable', JSON.stringify(mutation.payload.editable))
        }

        if (mutation.type == 'auth/AUTH_LOGOUT') {
            localStorage.removeItem('_fsfe_apiKey')
            localStorage.removeItem('_fsfe_realname')
            localStorage.removeItem('_fsfe_accesslevel')
            localStorage.removeItem('_fsfe_editable')
        }
    })
};

export default subscribe;
