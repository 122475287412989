<template>
  <div class="guidelines">
    <div class="row">
      <div class="col-md-8">
        <div class="guidelines-ls">
          <ul class="gl" v-if="selectedGuidelines.length > 0">
            <li v-for="(sgl, sglKey) in selectedGuidelines" :key="sglKey">
              <p class="title">{{ sgl.name }}</p>
            </li>
          </ul>
          <div class="alert alert-secondary" v-else>
            <p>Ingen rådgivning hittades</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "guideline",
  props: ["insuranceid"],
  data() {
    return {
      dragging: false,
      guideline: null,
    };
  },

  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      apiKey: (state) => state.auth.apiKey,
      selectedGuidelines: "selectedGuidelines",
      optionalGuidelines: "optionalGuidelines"
    }),    
  },
};
</script>

<style lang="scss" scoped>
.guidelines {
  ul {
    padding-left: 0;
    margin-bottom: 0;
    li {
      font-size: 15px;
      font-weight: 600;
      background: #f7f7f7;
      list-style: none;
      padding: 20px 25px;
    }
    li:not(:last-child) {
      border-bottom: 1px solid #ddd;
    }
  }
  .draggable-item {
    li:hover {
      border: 2px dashed #f7dc6f;
      cursor: pointer;
      background: #fef9e7;
      padding: 18px 23px;
    }
  }
  .guidelines-ls {
    .gl-dropzone {
      border: 2px dashed #8e8e8e;
      padding: 20px 0;
      background: #f1f1f1;
      text-align: center;
      border-radius: 4px;
      margin-bottom: 15px;
      h3 {
        font-size: 15px;
        font-weight: 600;
        color: #afafaf;
        margin: 0;
        text-transform: uppercase;
      }
    }
    .gl-dropzone.dragging {
      background: #eff9ff;
    }
    .gl {
      li {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &:hover {
          background: #f1f1f1;
        }
        .title {
          font-size: 15px;
          margin: 0;
        }
        .remove {
          margin: 0;
          font-size: 13px;
          color: #e74c3c;
          cursor: pointer;
          &:hover {
            color: #a11708;
          }
        }
      }
    }
    .alert p {
      margin: 0;
    }
  }
}
</style>