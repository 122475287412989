<template>
  <div id="app" :class="[(isVisible & apiKey !== null) ? 'visible' : '']">
    <sidebar />
    <router-view />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Sidebar from "@/components/Sidebar.vue";
export default {
  computed: {
    ...mapState({
      apiKey: (state) => state.auth.apiKey,
      isVisible: (state) => state.sidebar.visible,
    }),
  },

  components: {
    Sidebar,
  },
};
</script>

<style lang="scss">
@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/bootstrap-vue/src/index.scss";
@import "./assets/style.scss";
@import "./assets/style-createInsurance.scss";

#app {
  padding-left: 0;
  transition: all 0.5s ease;
}

#app.visible {
  padding-left: 250px;
}

#app.visible .sidebar {
  width: 250px;
}
</style>
