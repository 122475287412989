<template>
  <div class="staff w-100">
    <navbar />
    <div class="content p-4 box-info">
      <div class="row">
        <div class="col-lg-6">
          <div class="card">
            <div class="card-body">
              <div class="form-group">
                <label for="address">Adress</label>
                <input
                  type="text"
                  class="form-control"
                  id="address"
                  placeholder="Adress"
                  v-model="address"
                />
              </div>
              <div class="form-group">
                <label for="postal_address">Postadress</label>
                <input
                  type="email"
                  class="form-control"
                  id="postal_address"
                  placeholder="Postadress"
                  v-model="postalAddress"
                />
              </div>
              <div class="form-group">
                <label for="org_nr">Organisationsnummer</label>
                <input
                  type="text"
                  class="form-control"
                  id="org_nr"
                  placeholder="Organisationsnummer"
                  v-model="orgNr"
                />
              </div>
              <div class="form-group">
                <label for="phone">Telefonnummer</label>
                <input
                  type="text"
                  class="form-control"
                  id="phone"
                  placeholder="Telefonnummer"
                  v-model="phone"
                />
              </div>
              <div class="form-group">
                <label for="email">E-post</label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  placeholder="E-post"
                  v-model="email"
                />
              </div>
              <div class="form-group">
                <label for="bankgironummer">Bankgironummer</label>
                <input
                  type="text"
                  class="form-control"
                  id="bankgironummer"
                  placeholder="Bankgironummer"
                  v-model="bankgironummer"
                />
              </div>
              <div class="form-group">
                <label for="insurance_letter">Försäkringsbrevstext</label>
                <input
                  type="email"
                  class="form-control"
                  id="insurance_letter"
                  placeholder="Försäkringsbrevstext"
                  v-model="insuranceLetter"
                />
              </div>
              <div class="custom-file">
                <input
                  type="file"
                  class="custom-file-input"
                  id="select-file"
                  aria-describedby="fileHelp"
                  accept="image/*"
                  @change="handleFileUpload"
                />
                <label
                  class="custom-file-label"
                  for="select-file"
                  data-browse="Välj"
                  >{{ file ? file.name : "Välj fil" }}</label
                >
                <small id="fileHelp" class="form-text text-muted ml-1"
                  >Välj och ladda upp din logotyp</small
                >
              </div>

              <button
                type="button"
                class="btn btn-success btn-block btnLargeGr mt-3"
                @click="submit()"
              >
                Spara
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
export default {
  name: "insurance-company",
  data() {
    return {
      address: null,
      postalAddress: null,
      orgNr: null,
      phone: null,
      email: null,
      bankgironummer: null,
      insuranceLetter: null,
      file: null,
    };
  },

  components: {
    Navbar,
  },

  methods: {
    handleFileUpload(e) {
      this.file = e.target.files[0];
      console.log(this.file);
    },

    submit() {
      let formData = new FormData();
      formData.append("file", this.file);

      console.log(this.file);

    //   this.$axios
    //     .post("/file-upload", formData, {
    //       headers: { "Content-Type": "multipart/form-data" },
    //     })
    //     .then((response) => {
    //       console.log(response);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    },
  },
};
</script>