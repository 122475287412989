<template>
    <div class="create-user w-100">
        <navbar />
        <div class="content p-4">
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatem non perferendis aliquid. Quis modi nam impedit sequi voluptate eaque veniam sit odit hic. Dignissimos illo obcaecati, asperiores accusamus dolorum accusantium!</p>
        </div>
    </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
export default {
    name: 'create-user',
    components: {
        Navbar
    },
}
</script>