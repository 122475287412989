<template>
  <div class="w-100">
    <navbar />
    <div class="content p-4 box-info">
      <ol>
        <li v-for="(link, index) in links" :key="index">
          <a :href="link">pdf</a>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import { mapState } from "vuex";

export default {
  components: { Navbar },
  name: "Batch",
  data() {
    return {
      links: [],
    };
  },
  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      apiKey: (state) => state.auth.apiKey,
      hidden: (state) => state.sidebar.hidden,
    }),
  },

  mounted() {
    this.fetchLinks();
  },
  methods: {
    fetchLinks() {
      this.$axios
        .get(this.baseUrl + "api/batch.php?key=" + this.apiKey)
        .then((response) => {
          if (response.data.status == "ok") {
            this.links = response.data.data;
          } else {
            this.$router.push({ name: "login" });
          }
        });
    },
  },
};
</script>

<style>
</style>