import axios from "../../axios";

const state = {
    customers: [],
    customer: null,
    insurances: [],
    loading: false,
    brevtexts: [],
    discounts: [],
    date: null,
    insuranceID: null,
    originalDate: null,
    sr: null,
    standardSr: null
}

const mutations = {
    LOADING(state, payload) {
        state.loading = payload
    },

    FETCH_CUSTOMER(state, payload) {
        state.insuranceID = payload.insuranceID
        state.customer = payload.customer
        state.insurances = payload.data
        state.brevtexts = payload.brevtext
        state.discounts = payload.rabatt
        state.date = payload.date
        state.originalDate = payload.originalDate
        state.sr = payload.sr
        state.standardSr = payload.standardSr
    },

    FETCH_CUSTOMERS(state, payload) {
        state.customers = payload
    }
}

const actions = {
    fetchCustomer({ rootState, commit }, params) {
        return new Promise((resolve, reject) => {
            commit('LOADING', true)
            axios
                .get(
                    rootState.baseUrl +
                    "api/get.php?type=insuranceInfo&customerID=" +
                    params.customerID +
                    "&insuranceID=" +
                    params.insuranceID +
                    "&key=" +
                    rootState.auth.apiKey
                )
                .then((response) => {
                    commit('LOADING', false)
                    if (response.data.status == "ok") {
                        commit('FETCH_CUSTOMER', response.data)
                        resolve(response)
                    }
                })
                .catch((error) => {
                    commit('LOADING', false)
                    reject(error)
                });
        })
    },

    fetchCustomers({ rootState, commit }, data) {
        return new Promise((resolve, reject) => {
            commit('LOADING', true)
            axios.get(rootState.baseUrl + 'api/getCustomers.php?search=' + encodeURIComponent(data.keyword) + '&limit=' + encodeURIComponent(data.limit) + '&key=' + encodeURIComponent(rootState.auth.apiKey))
                .then(response => {
                    if (response.data.status == "ok") {
                        commit('LOADING', false)
                        commit('FETCH_CUSTOMERS', response.data.customers)
                        resolve(response)
                    }
                })
                .catch((error) => {
                    commit('LOADING', false)
                    reject(error)
                })
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}