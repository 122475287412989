<template>
  <div class="damages mt-3" v-if="damages.length > 0">
    <div class="table-responsive content whitebox">
      <table class="table table-striped">
        <thead>
          <tr>
            <th style="border-top: none">Skadenr</th>
            <th style="border-top: none">Försäkring</th>
            <th style="border-top: none">Datum</th>
            <th style="border-top: none">Kategori</th>
          </tr>
        </thead>
        <tbody class="t-dam">
          <tr v-for="(dmg, index) in damages" :key="index" class="td-dam">
            <td>
              <button
                type="button"
                class="btn btn-linK"
                @click="$emit('navigate', dmg)"
              >
                {{ dmg.damageNr }}
              </button>
            </td>
            <td>
              <button
                type="button"
                class="btn btn-linK"
                @click="$emit('show', dmg)"
              >
                {{ dmg.insuranceNr }}
              </button>
            </td>
            <td>{{ dmg.damageDate }}</td>
            <td>{{ dmg.category }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    damages: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.damages {
  background: #fff;
  .btn {
    color: #5aa196;
    font-weight: 600;
    &:hover {
      color: inherit;
    }
    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }
}
.btn {
  padding: 0px;
}
.t-dam {
  font-size: 13px;
}
</style>