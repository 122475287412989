<template>
  <div class="customer-show w-100">
    <navbar v-if="popup == null" />
    <div class="content p-4">
      <div class="row">
        <div class="col-md-10">
          <h3 v-if="customer != null">
            {{
              customer.category == "co"
                ? customer.companyName
                : customer.firstName
            }}
            {{ customer.category == "co" ? "" : customer.lastName }}
            {{
              customer.category == "co" && customer.lastName.length
                ? "/ " + customer.firstName + " " + customer.lastName
                : ""
            }}
          </h3>
        </div>
        <div class="col-md-2">
          <button
            style="float: right"
            type="button"
            class="btn btn-secondary btn-sm"
            @click="$router.go(-1)"
          >
            <font-awesome-icon icon="times" />
          </button>
          <button
            type="button"
            style="float: right; margin-right: 24px"
            class="btn btn-secondary btn-sm btn-dark"
            @click="editInsurance()"
          >
            <font-awesome-icon icon="edit" class="edit" />
          </button>
        </div>
      </div>
      <div class="row" v-if="customer != null && !loading">
        <div class="col-md-6">
          <div class="profile">
            <div class="content">
              <address class="text-muted">
                {{ customer.street }}, {{ nicePostcode(customer.postcode) }}
                {{ customer.city }}
              </address>
              <span class="text-muted contact-info"
                >{{
                  customer.mobilePhone != null &&
                  customer.mobilePhone.length > 0
                    ? nicePhone(customer.mobilePhone)
                    : ""
                }}{{
                  customer.homePhone != null && customer.homePhone.length > 0
                    ? nicePhone(customer.homePhone)
                    : ""
                }}, kundnummer {{ customer.customerNr }}</span
              >
              <p>försäkringsnummer {{ insuranceNr }}, ombud {{ ombudsnr }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div v-if="insurance != null && insurance.length > 0">
            <div class="content">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>Produkt</th>
                    <th style="text-align: right">Värde</th>
                    <th style="text-align: right">Avgift</th>
                    <th style="text-align: right">Premie</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="ins in insurance">
                    <tr :key="ins.productID">
                      <td>{{ ins.name }}</td>
                      <td style="text-align: right">
                        {{
                          ins.objectValue == null
                            ? ""
                            : formatValue(ins.objectValue)
                        }}
                      </td>
                      <td style="text-align: right">
                        {{
                          ins.flatFee == null
                            ? ""
                            : ins.flatFee.toLocaleString("sv")
                        }}
                      </td>
                      <td style="text-align: right">
                        {{
                          ins.premie == null
                            ? ""
                            : ins.premie.toLocaleString("sv")
                        }}
                      </td>
                    </tr>
                    <tr v-for="object in ins.objects" :key="object.objectID">
                      <td style="padding-left: 24px">
                        {{ object.title }}
                        {{
                          object.property != null && object.property.length > 0
                            ? "(" + object.property + ")"
                            : ""
                        }}
                      </td>
                      <td style="text-align: right">
                        {{
                          object.objectValue == null
                            ? ""
                            : formatValue(object.objectValue)
                        }}
                      </td>
                      <td style="text-align: right">
                        {{
                          object.flatFee == null
                            ? ""
                            : object.flatFee.toLocaleString("sv")
                        }}
                      </td>
                      <td style="text-align: right">
                        {{
                          object.premie == null
                            ? ""
                            : object.premie.toLocaleString("sv")
                        }}
                      </td>
                    </tr>
                    <template v-for="sp in ins.subProducts">
                      <tr :key="sp.productID">
                        <td style="padding-left: 24px">{{ sp.name }}</td>
                        <td style="text-align: right">
                          {{
                            sp.objectValue == null
                              ? ""
                              : formatValue(sp.objectValue)
                          }}
                        </td>
                        <td style="text-align: right">
                          {{
                            sp.flatFee == null
                              ? ""
                              : sp.flatFee.toLocaleString("sv")
                          }}
                        </td>
                        <td style="text-align: right">
                          {{
                            sp.premie == null
                              ? ""
                              : sp.premie.toLocaleString("sv")
                          }}
                        </td>
                      </tr>
                      <tr v-for="(object, index) in sp.objects" :key="index">
                        <td style="padding-left: 48px">{{ object.title }}</td>
                        <td style="text-align: right">
                          {{
                            object.objectValue == null
                              ? ""
                              : formatValue(object.objectValue)
                          }}
                        </td>
                        <td style="text-align: right">
                          {{
                            object.flatFee == null
                              ? ""
                              : object.flatFee.toLocaleString("sv")
                          }}
                        </td>
                        <td style="text-align: right">
                          {{
                            object.premie == null
                              ? ""
                              : object.premie.toLocaleString("sv")
                          }}
                        </td>
                      </tr>
                    </template>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <pulse-loader :loading="loading"></pulse-loader>
    </div>
  </div>
</template>

<script>
import { parsePostcode, parsePhone } from "@/_helpers/parser";
import Navbar from "@/components/Navbar.vue";
import { mapState } from "vuex";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { required } from "vuelidate/lib/validators";
var moment = require("moment");
moment.locale("sv");

export default {
  name: "customer-insurance",
  data() {
    return {
      loading: false,
      customer: null,
      insurance: null,
      insuranceNr: "",
      insuranceID: null,
      message: null,
      messages: [],
      error: null,
      submitStatus: null,
      users: [],
      user: null,
      ombudsnr: "",
      popup: null,
    };
  },

  validations: {
    message: { required },
  },

  components: {
    Navbar,
    PulseLoader,
  },

  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      apiKey: (state) => state.auth.apiKey,
      hidden: (state) => state.sidebar.hidden,
    }),
  },

  mounted() {
    this.fetchCustomer();
    this.popup = this.$route.params.popup ?? null;
  },

  methods: {
    fetchCustomer() {
      this.loading = true;
      let customerID = this.$route.params.customerID;
      let insuranceNr = this.$route.params.insuranceNr;
      this.$axios
        .get(
          this.baseUrl +
            "api/get.php?type=insuranceInfo&customerID=" +
            customerID +
            "&insuranceNr=" +
            insuranceNr +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.customer = response.data.customer;
            this.insurance = response.data.data;
            this.insuranceID = response.data.insuranceID;
            this.loading = false;
            if (this.insurance.length > 0) {
              this.insuranceNr = this.insurance[0].insuranceNr;
              this.ombudsnr = this.insurance[0].ombudsnr;
            }
          } else {
            this.$router.push({ name: "login" });
          }
        });
    },

    convertDate(date) {
      return date.format("D MMM YYYY, HH:mm");
    },

    nicePostcode(pc) {
      return parsePostcode(pc);
    },

    nicePhone(tfn) {
      return parsePhone(tfn);
    },
    formatValue(value) {
      if (value > 999999999) {
        return (
          value.toString().substring(0, 6) + "-" + value.toString().substring(6)
        );
      } else {
        return value.toLocaleString("sv");
      }
    },

    editInsurance() {
      this.$router.push({
        name: "insurance-show",
        query: { insuranceID: this.insuranceID },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
pre {
  white-space: pre-wrap;
}
.customer-show {
  button {
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1px;
  }
  .profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #f1f1f1;
    padding: 10px 15px;
    border-radius: 5px;
    .content {
      margin-left: 10px;
      .name {
        font-weight: 600;
      }
      address,
      .contact-info {
        font-size: 14px;
        margin: 0;
      }
    }
    p {
      margin: 0;
      padding: 0;
    }
  }
  .events {
    .event {
      padding: 10px 15px;
      background: #f7f7f7;
      border-radius: 5px;
      .event-text {
        // font-weight: 600;
        font-size: 16px;
        // letter-spacing: 1px;
      }
      .author {
        font-size: 12px;
        font-weight: 600;
        color: #797979;
        // letter-spacing: 0.05rem;
        margin: 0;
        span:not(:last-child) {
          padding-right: 8px;
        }
        svg {
          font-size: 14px;
        }
      }
      .separator {
        color: #000;
      }
    }
  }
}
</style>
