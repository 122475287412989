<template>
  <div class="validity">
    <div class="row">
      <div class="col">
        <h3>Försäkringsobjekt</h3>
        <table class="table table-borderless none">
          <thead>
            <tr>
              <th>Försäkringsobjekt</th>
              <th>Försäkringbelopp</th>
              <th>Startdatum</th>
              <th>Slutdatum</th>
            </tr>
            <tr class="select-all">
              <th>&nbsp;</th>
              <th>&nbsp;</th>
              <th>
                <datepicker
                  format="yyyy-MM-dd"
                  input-class="form-control"
                  :bootstrap-styling="true"
                  v-model="startDate"
                  :monday-first="true"
                  :language="sv"
                  placeholder="Välj alla"
                  @input="selectAllStartDates()"
                ></datepicker>
              </th>
              <th>
                <datepicker
                  format="yyyy-MM-dd"
                  input-class="form-control"
                  :bootstrap-styling="true"
                  v-model="endDate"
                  :monday-first="true"
                  :language="sv"
                  placeholder="Välj alla"
                  @input="selectAllEndDates()"
                  :disabled="!startDate"
                ></datepicker>
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="premium in premiums">
              <tr :key="premium.draftProductID" v-if="premium.parentID == null">
                <td>{{ premium.name }}</td>
                <td>&nbsp;</td>
                <td>
                  <datepicker
                    format="yyyy-MM-dd"
                    input-class="form-control"
                    :bootstrap-styling="true"
                    v-model="premium.startDate"
                    :monday-first="true"
                    :language="sv"
                    placeholder="Startdatum"
                    @input="validateProduct(premium)"
                  ></datepicker>
                </td>
                <td>
                  <datepicker
                    format="yyyy-MM-dd"
                    input-class="form-control"
                    :bootstrap-styling="true"
                    v-model="premium.endDate"
                    :monday-first="true"
                    :language="sv"
                    placeholder="Tills vidare"
                    :disabled="!premium.startDate"
                    @input="validateProduct(premium)"
                  ></datepicker>
                </td>
              </tr>
              <template v-if="premium.objects.length > 0">
                <tr
                  class="product-child"
                  v-for="object in premium.objects"
                  :key="object.objectID"
                >
                  <td class="productName" v-if="object.title != null">
                    {{ object.title }} {{ getTerm(object.termID) }}
                  </td>
                  <td class="productName" v-else>(tömt)</td>
                  <td v-if="object.premie">
                    {{
                      object.objectValue
                        ? object.objectValue.toLocaleString("sv-SE", {
                            style: "currency",
                            currency: "SEK",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })
                        : 0 + " kr"
                    }}
                  </td>
                  <td v-else>0 kr</td>
                  <td>
                    <datepicker
                      format="yyyy-MM-dd"
                      input-class="form-control"
                      :bootstrap-styling="true"
                      v-model="object.startDate"
                      :monday-first="true"
                      :language="sv"
                      placeholder="Startdatum"
                      @input="validateObject(object)"
                    ></datepicker>
                  </td>
                  <td>
                    <datepicker
                      format="yyyy-MM-dd"
                      input-class="form-control"
                      :bootstrap-styling="true"
                      v-model="object.endDate"
                      :monday-first="true"
                      :language="sv"
                      placeholder="Tills vidare"
                      :disabled="!object.startDate"
                      @input="validateObject(object)"
                    ></datepicker>
                  </td>
                </tr>
              </template>
              <template v-if="premium.subProducts.length > 0">
                <template v-for="product in premium.subProducts">
                  <tr class="product-child" :key="product.productID">
                    <td class="productName">{{ product.name }}</td>
                    <td>&nbsp;</td>
                    <td>
                      <datepicker
                        format="yyyy-MM-dd"
                        input-class="form-control"
                        :bootstrap-styling="true"
                        v-model="product.startDate"
                        :monday-first="true"
                        :language="sv"
                        placeholder="Startdatum"
                        @input="validateProduct(product)"
                      ></datepicker>
                    </td>
                    <td>
                      <datepicker
                        format="yyyy-MM-dd"
                        input-class="form-control"
                        :bootstrap-styling="true"
                        v-model="product.endDate"
                        :monday-first="true"
                        :language="sv"
                        placeholder="Tills vidare"
                        :disabled="!product.startDate"
                        @input="validateProduct(product)"
                      ></datepicker>
                    </td>
                  </tr>
                  <template v-for="obj in product.objects">
                    <tr class="object-child" :key="obj.objectID">
                      <td class="objectTitle">
                        {{ obj.title }} {{ getTerm(obj.termID) }}
                      </td>
                      <td>
                        {{
                          obj.objectValue
                            ? obj.objectValue.toLocaleString("sv-SE", {
                                style: "currency",
                                currency: "SEK",
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              })
                            : 0 + " kr"
                        }}
                      </td>
                      <td>
                        <datepicker
                          format="yyyy-MM-dd"
                          input-class="form-control"
                          :bootstrap-styling="true"
                          v-model="obj.startDate"
                          :monday-first="true"
                          :language="sv"
                          placeholder="Startdatum"
                          :disabled="!obj.startDate"
                          @input="validateObject(obj)"
                        ></datepicker>
                      </td>
                      <td>
                        <datepicker
                          format="yyyy-MM-dd"
                          input-class="form-control"
                          :bootstrap-styling="true"
                          v-model="obj.endDate"
                          :monday-first="true"
                          :language="sv"
                          placeholder="Tills vidare"
                          :disabled="!obj.startDate"
                          @input="validateObject(obj)"
                        ></datepicker>
                      </td>
                    </tr>
                  </template>
                </template>
              </template>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Datepicker from "vuejs-datepicker";
import { sv } from "vuejs-datepicker/dist/locale";
import moment from "moment";
export default {
  name: "validity",
  props: ["insuranceid", "premiums"],
  data() {
    return {
      startDate: null,
      endDate: null,
      sv: sv,
    };
  },

  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      apiKey: (state) => state.auth.apiKey,
      terms: "terms",
    }),
  },

  components: { Datepicker },

  methods: {
    getTerm(termID) {
      let term = this.terms.find((term) => term.termID == termID);
      return term ? "(" + term.term + ")" : "(tom)";
    },

    // api/draft.php?type=setProductDate&startDate=&endDate=&draftProductID=
    validateProduct(product) {
      let startDate = product.startDate
        ? moment(product.startDate).format("YYYY-MM-DD")
        : "";
      let endDate = product.endDate
        ? moment(product.endDate).format("YYYY-MM-DD")
        : "";

      this.$axios
        .get(
          this.baseUrl +
            "api/draft.php?type=setProductDate&startDate=" +
            startDate +
            "&endDate=" +
            endDate +
            "&draftProductID=" +
            product.draftProductID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // api/draft.php?type=setObjectDate&startDate=&endDate=&objectID=
    validateObject(object) {
      let startDate = object.startDate
        ? moment(object.startDate).format("YYYY-MM-DD")
        : "";
      let endDate = object.endDate
        ? moment(object.endDate).format("YYYY-MM-DD")
        : "";

      this.$axios
        .get(
          this.baseUrl +
            "api/draft.php?type=setObjectDate&startDate=" +
            startDate +
            "&endDate=" +
            endDate +
            "&objectID=" +
            object.objectID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    selectAllStartDates() {
      this.premiums.forEach((premium) => {
        premium.startDate = this.startDate;
        this.validateProduct(premium);
        if (premium.objects.length > 0) {
          premium.objects.map((po) => {
            po.startDate = this.startDate;
            this.validateObject(po);
          });
        }
        if (premium.subProducts.length > 0) {
          premium.subProducts.map((sp) => {
            sp.startDate = this.startDate;
            this.validateProduct(sp);
            sp.objects.map((spObj) => {
              spObj.startDate = this.startDate;
              this.validateObject(spObj);
            });
          });
        }
      });
    },

    selectAllEndDates() {
      this.premiums.forEach((premium) => {
        premium.endDate = this.endDate;
        this.validateProduct(premium);
        if (premium.objects.length > 0) {
          premium.objects.map((po) => {
            po.endDate = this.endDate;
            this.validateObject(po);
          });
        }
        if (premium.subProducts.length > 0) {
          premium.subProducts.map((sp) => {
            sp.endDate = this.endDate;
            this.validateProduct(sp);
            sp.objects.map((spObj) => {
              spObj.endDate = this.endDate;
              this.validateObject(spObj);
            });
          });
        }
      });
    },
  },
};
</script>
