<template>
  <div class="building-types">
    <div class="building" v-if="buildings.length > 0">
      <figure
        class="figure"
        v-for="(building, index) in buildings"
        :key="index"
        @click="houseType(building)"
        :class="{ selected: building.selected }"
      >
        <img
          :src="require('@/' + building.url)"
          class="figure-img img-fluid rounded"
        />
        <div class="checked">
          <font-awesome-icon icon="check-circle" />
        </div>
      </figure>
    </div>
    <button type="button" class="btn btn-info btn-lg btn-block btnGr" style="background-color:#5aa196; color:white;" :disabled="!isSelected()" @click="selected">
      Nästa
    </button>
  </div>
</template>

<script>
export default {
  name: "building-types",
  props: ["buildings"],

  methods: {
    isSelected() {
        return this.buildings.find((building) => building.selected)
    },

    houseType(building) {
      this.$emit("select-house", building);
    },

    selected() {
      this.$emit("building-selected");
    },
  },
};
</script>

<style lang="scss" scoped>
.building-types {
  margin: 15px 20px 30px;
  padding: 15px 10px;
  .building {
    text-align: center;
  }
  .figure {
    width: 20%;
    height: 120px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    margin-right: 15px;
    position: relative;
    &:hover {
      background: #f1f1f1;
      cursor: pointer;
    }
    img {
      width: 90px;
      height: 100%;
    }
    .checked {
      position: absolute;
      top: 0;
      right: 5px;
    }
  }
  .selected {
    border: 2px solid #28b463;
    background: transparent;
    .checked svg {
      color: #28b463;
    }
  }
  button {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}
</style>