<template>
  <div class="discount mb-3">
    <div class="boxheadline">
      <h5 class="hl-box">Rabatt</h5>
      <a @click="$emit('close')"
        ><button class="closebtn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            class="bi bi-x"
            viewBox="0 0 16 16"
          >
            <path
              d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
            />
          </svg></button
      ></a>
    </div>
    <div class="content p-3">
      <table class="table" v-if="discounts.length > 0">
        <thead>
          <tr>
            <th scope="col">Giltig</th>
            <th scope="col"></th>
            <th scope="col">Rabatt (kr)</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(discount, index) in discounts" :key="index">
            <td>{{ discount.startDate }} --</td>
            <td>{{ discount.endDate == "9999-12-31" ? "t.v." : discount.endDate }}</td>
            <td>{{ discount.value }}</td>
          </tr>
        </tbody>
      </table>
      <div class="alert alert-secondary" v-else>
        <p class="m-0">Ingen rabatt hittades</p>
      </div>
      <div class="form-inline">
        <div class="form-group mb-2">
          <datepicker
            format="yyyy-MM-dd"
            input-class="form-control"
            :bootstrap-styling="true"
            v-model="selectedDate"
            :language="sv"
            :monday-first="true"
            placeholder="Välj datum"
          ></datepicker>
        </div>
        <div class="form-group mx-sm-3 mb-2">
          <input
            type="number"
            class="form-control"
            id="discount"
            placeholder="Rabatt"
            v-model="discount"
          />
        </div>
        <button
          type="submit"
          class="btn btn-primary mb-2 btnSmGr"
          @click="$emit('save', { selectedDate, discount })"
        >
          Spara
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { sv } from "vuejs-datepicker/dist/locale";
import moment from "moment";
export default {
  name: "discount",
  props: ["discounts", "date", "resetkey"],
  data() {
    return {
      sv: sv,
      selectedDate: null,
      discount: null,
    };
  },

  watch: {
    date() {
      console.log(this.date);
      this.selectedDate = this.date;
    },

    resetkey() {
      this.discount = null
    }
  },

  components: {
    Datepicker,
  },

  mounted() {
    this.selectedDate = this.date ? this.date : moment().format("YYYY-MM-DD");
  },
};
</script>

<style lang="scss" scoped>
.discount {
  .boxheadline {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .content {
    background: #fff;
  }
}
</style>