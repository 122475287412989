<template>
	<div class="reports w-100">
		<navbar />
		<div class="content p-4">
			<div class="row">
				<div class="col-md-10">
					<h3>Rapporter</h3>
					<p class="text-muted">Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum unde fuga et vitae minus praesentium tempore dolor officiis. Quis harum vero necessitatibus possimus facilis adipisci temporibus ut mollitia cum ab.</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'

export default {
  name: 'reports',
  components: {
	Navbar
  }
}
</script>