<template>
  <div class="events py-3" v-if="messages.length > 0">
    <div
      :class="
        message.customerMsg == 'sms'
          ? customerEvent
          : message.customerMsg == 'mail'
          ? mailEvent
          : message.customerMsg == 'incoming'
          ? incomingEvent
          : normalEvent
      "
      v-for="(message, index) in messages"
      :key="index"
    >
      <pre class="event-text text-muted">{{ message.note }}</pre>
      <p class="author">
        <span class="date">{{
          message.customerMsg == "sms" || message.customerMsg == "mail"
            ? message.customerMsg
            : ""
        }}</span>
        <span class="date">{{ convertDate(message.ts) }}</span>
        <span class="separator">-</span>
        <span class="name">{{
          message.customerMsg == "incoming"
            ? customer.category == "co"
              ? customer.companyName
              : customer.firstName + " " + customer.lastName
            : message.realname
        }}</span>
        <span class="separator">-</span>
        <span class="name">
          <font-awesome-icon
            icon="flag"
            :style="{ color: '#E74C3C' }"
            v-if="message.status != null && message.status == 'open'"
          />
          <font-awesome-icon
            icon="flag"
            :style="{ color: '#2ECC71' }"
            v-if="message.status != null && message.status == 'closed'"
          />
          {{ message.assignee != null ? message.assignee : "" }}
        </span>
        <b-button
          style="margin-left: 8px"
          v-if="message.status == 'open'"
          variant="success"
          @click="closeAlert(message.alertID)"
          >Klar</b-button
        >
      </p>
    </div>
  </div>
</template>

<script>
import { parsePostcode, parsePhone } from "@/_helpers/parser";
import { mapState } from "vuex";
var moment = require("moment");
moment.locale("sv");

export default {
  name: "notes",
  props: ["customer", "messages"],
  data() {
    return {
      // client: null,
      normalEvent: "event mb-3",
      customerEvent: "event mb-3 customer-event",
      mailEvent: "event mb-3 mail-event",
      incomingEvent: "event mb-3 incoming-event",

      isOpen: false,
    };
  },
  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      hidden: (state) => state.sidebar.hidden,
      apiKey: (state) => state.auth.apiKey,
      accesslevel: (state) => state.auth.accesslevel,
    }),
  },

  methods: {
    convertDate(date) {
      return moment(date).format("D MMM YYYY, HH:mm");
    },

    nicePostcode(pc) {
      return parsePostcode(pc);
    },

    nicePhone(tfn) {
      return parsePhone(tfn);
    },
    closeAlert(id) {
      const answer = window.confirm("Är du säker på att du vill klarmarkera?");
      if (answer) {
        this.$axios
          .get(
            this.baseUrl + "api/closeAlert.php?key=" + this.apiKey + "&id=" + id
          )
          .then((response) => {
            if (response.data.status == "ok") {
              this.$emit("update", true);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style lang="scss">
.events {
  .customer-event {
    border-right: 8px solid #ffff9992 !important;
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    border-left: 1px solid #f3f3f3;
  }
  .mail-event {
    border-right: 8px solid #ccccff !important;
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    border-left: 1px solid #f3f3f3;
  }
  .incoming-event {
    border-right: 8px solid #ffcccc !important;
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    border-left: 1px solid #f3f3f3;
  }
}

.event {
  padding: 10px 15px;
  background: white;
  border-radius: 3px;
  border: 1px solid #f3f3f3;
  //border: 1px solid #dde3eb;
  pre {
    white-space: pre-wrap;
  }

  .event-text {
    // font-weight: 600;
    font-size: 16px;
    // letter-spacing: 1px;
    font-family: inherit;
  }
  .author {
    font-size: 12px;
    font-weight: 600;
    color: #797979;
    // letter-spacing: 0.05rem;
    margin: 0;
    span:not(:last-child) {
      padding-right: 8px;
    }
    svg {
      font-size: 14px;
    }
  }
  .separator {
    color: #000;
  }
}
</style>