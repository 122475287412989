var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"insurances w-100"},[_c('navbar'),_c('div',{staticClass:"content p-4 box-info"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-md-4 text-right"},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.approved),expression:"approved"}],staticClass:"form-check-input",staticStyle:{"margin-top":"10px","font-weight":"medium","zoom":"1.5"},attrs:{"type":"checkbox","id":"approved"},domProps:{"checked":Array.isArray(_vm.approved)?_vm._i(_vm.approved,null)>-1:(_vm.approved)},on:{"change":function($event){var $$a=_vm.approved,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.approved=$$a.concat([$$v]))}else{$$i>-1&&(_vm.approved=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.approved=$$c}}}}),_vm._m(1)])])])]),_c('hr'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"table-responsive"},[(_vm.filterdInsurances.length > 0)?_c('table',{staticClass:"table table-striped"},[_vm._m(2),_c('tbody',_vm._l((_vm.filterdInsurances),function(insurance,index){return _c('tr',{key:index},[_c('th',{attrs:{"scope":"row"}},[_c('a',{attrs:{"id":"insurance-number","href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.editInsurance(
                        insurance.insuranceID,
                        insurance.customerID
                      )}}},[_vm._v(_vm._s(insurance.insuranceID))])]),_c('td',[_c('router-link',{attrs:{"id":"customer-show","to":{
                      name: 'customer-show',
                      params: { id: insurance.customerID },
                    }}},[_vm._v(" "+_vm._s(insurance.category == "co" ? insurance.companyName : insurance.firstName + " " + insurance.lastName))])],1),_c('td',[_vm._v(_vm._s(insurance.realname))]),_c('td',[_vm._v(_vm._s(insurance.ts))]),_c('td',{staticClass:"approval"},[(insurance.draftStatus == 'draft')?_c('button',{staticClass:"btn btn-warning btn-sm",attrs:{"type":"button","id":"approv-draft"},on:{"click":function($event){return _vm.saveApproval(insurance)}}},[_c('font-awesome-icon',{attrs:{"icon":"edit"}}),_vm._v(" Godkänn ")],1):_vm._e(),(insurance.draftStatus == 'draft')?_c('button',{staticClass:"btn btn-danger btn-sm ml-2",attrs:{"type":"button","id":"delete-draft"},on:{"click":function($event){return _vm.deleteDraftInsurance(insurance.insuranceID)}}},[_c('font-awesome-icon',{attrs:{"icon":"trash-alt"}}),_vm._v(" Ta bort ")],1):_vm._e(),(insurance.draftStatus == 'approved')?_c('button',{staticClass:"btn btn-success btn-sm",attrs:{"type":"button","disabled":"","id":"approved"}},[_c('font-awesome-icon',{attrs:{"icon":"check-double"}}),_vm._v(" Godkänd ")],1):_vm._e()])])}),0)]):_vm._e()])])]),_c('b-modal',{attrs:{"content-class":"customer-modal","id":"modal-1","size":"lg","title":_vm.title},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
                    var cancel = ref.cancel;
return [_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return cancel()}}},[_vm._v("Avbryt")])]}}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.keyword),expression:"keyword"},{name:"focus",rawName:"v-focus"}],staticClass:"form-control form-control-lg",attrs:{"autocomplete":"off","type":"text","placeholder":"Sök kund..."},domProps:{"value":(_vm.keyword)},on:{"keyup":_vm.searchCustomer,"input":function($event){if($event.target.composing){ return; }_vm.keyword=$event.target.value}}})]),_c('div',{staticClass:"form-group"},[(_vm.customers.length > 0)?_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-striped"},[_c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Namn")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Adress")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("PostNr")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Ort")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" ")])])]),_c('tbody',_vm._l((_vm.customers),function(customer,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(customer.category == "co" ? customer.companyName : customer.firstName)+" "+_vm._s(customer.category == "co" ? "" : customer.lastName)+" ")]),_c('td',[_vm._v(_vm._s(customer.street))]),_c('td',[_vm._v(_vm._s(customer.postcode))]),_c('td',[_vm._v(_vm._s(customer.city))]),_c('td',[_c('button',{staticClass:"btn btn-info btn-sm btnSmGr",attrs:{"type":"button"},on:{"click":function($event){return _vm.createInsurance(customer.id)}}},[_c('font-awesome-icon',{attrs:{"icon":"external-link-alt"}}),_vm._v(" Skapa försäkring ")],1)])])}),0)])]):_vm._e(),(_vm.customers.length == 0)?_c('div',{staticClass:"alert alert-secondary",attrs:{"role":"alert"}},[_c('p',[_vm._v("Ingen kund hittades")])]):_vm._e()])])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-8"},[_c('h3',[_vm._v("Nya försäkringar (utkast)")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"form-check-label btn-cutom",staticStyle:{"font-size":"1em","margin-top":"11px"},attrs:{"for":"approved"}},[_c('h4',[_vm._v("Visa godkända")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Utkastnummer")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Kund")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Skapad av")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Skapades")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" ")])])])}]

export { render, staticRenderFns }