import { Role } from './role'

const _fsfe_apiKey = JSON.parse(localStorage.getItem('_fsfe_apiKey'));
const accessLevel = JSON.parse(localStorage.getItem('_fsfe_accesslevel'));

export function apiKey() {
    return (_fsfe_apiKey) ? _fsfe_apiKey : null
}

export function isAdmin() {
    return accessLevel == Role.Admin
}

export function isStaff() {
    return accessLevel == Role.Office
}

export function isUser() {
    return accessLevel == Role.User
}

export function isPartner() {
    return accessLevel == Role.LF || accessLevel == Role.Anticimex
}

const isAuthenticated = (_fsfe_apiKey) ? true : false;

export { isAuthenticated, accessLevel };