const mutations = {
    // sidebar.store.js
    // SIDEBAR_VISIBLE(state, payload) {
    //     state.visible = payload
    // },

    // sidebar.store.js
    // ['HIDE_SIDEBAR'](state, payload) {
    //     state.hidden = payload
    // },

    // auth.store.js
    // AUTH_LOGIN(state, payload) {
    //     state.authenticated = true
    //     state.apiKey = payload.key
    //     state.realname = payload.realname
    //     state.accesslevel = payload.accesslevel
    // },

    // auth.store.js
    // SET_AUTHENTICATION(state, payload = false) {
    //     state.authenticated = payload;
    // },

    // auth.store.js
    // AUTH_LOGOUT(state) {
    //     state.authenticated = false
    //     state.realname = null
    //     state.accesslevel = null
    // },

    ERRORS(state, payload) {
        state.error = payload
    },

    FETCH_NOTES(state, payload) {
        state.notes = payload
    },

    FETCH_CATEGORIES(state, payload) {
        state.categories = payload
    },

    CATEGORIES(state, payload) {
        state.categories = payload
    },

    CATEGORY(state, payload) {
        state.category = payload
    },

    STANDARDS(state, payload) {
        state.standards = payload
    },

    ADD_STANDARD(state, payload) {
        state.standards.push(payload)
    },

    STANDARD(state, payload) {
        state.standard = payload
    },

    // evaluation.store.js
    FETCH_EVALUATIONTYPES(state, payload) {
        state.evaluationTypes = payload
    },

    FETCH_GUIDELINES(state, payload) {
        state.guidlines = payload
    },

    EDITABLE_MODE(state, payload) {
        state.editable = payload;
    },

    FETCH_INSURANCE_INFO(state, payload) {
        state.insuranceInfo = []
        payload.data.forEach((product) => {
            product.objects.forEach((object) => {
                if (!object.premie) {
                    object.premie = 0
                }
            })
            if (product.subProducts.length > 0) {
                product.subProducts.forEach((sp) => {
                    sp.objects.forEach((object) => {
                        if (!object.premie) {
                            object.premie = 0
                        }
                    })
                })
            }
        })
        state.insuranceInfo = payload.data
        state.customer = payload.customer
        state.dateDiff = payload.originalDate != payload.date ? payload.date : ''
        state.standardSr = payload.standardSr
        state.sr = payload.sr
        state.brevtexts = payload.brevtext
        state.discounts = payload.rabatt
    },

    FETCH_ALL_OBJECTS(state, payload) {
        state.insurances = []
        var sr = ''
        payload.forEach((product) => {
            if (sr.length == 0 && product.sr != undefined) {
                sr = product.sr
            }
            product.objects.forEach((object) => {
                if (!object.premie) {
                    object.premie = 0
                }
            })
            state.insurances.push({
                productID: product.productID,
                name: product.name,
                draftProductID: product.draftProductID,
                objects: product.objects,
                evaluationTypeID: product.evaluationTypeID,
                evaluationFormID: product.evaluationFormID,
                flatFee: product.flatFee,
                dragging: false,
                sr: sr,
                unit: product.unit
            })
            if (product.subProducts.length > 0) {
                product.subProducts.forEach((sp) => {
                    sp.objects.forEach((object) => {
                        if (!object.premie) {
                            object.premie = 0
                        }
                    })
                    state.insurances.push({
                        productID: sp.productID,
                        parentID: product.productID,
                        name: sp.name,
                        draftProductID: sp.draftProductID,
                        objects: sp.objects,
                        flatFee: sp.flatFee,
                        premie: sp.premie,
                        evaluationTypeID: sp.evaluationTypeID,
                        evaluationFormID: sp.evaluationFormID,
                        dragging: false,
                    });
                });
            }
        })
        state.insurances.map((io) => io.objects.unshift())
        // console.log(state.insurances)
    },

    INSURANCE_ON_DROP(state, payload) {
        const selectedIndex = state.insurances.indexOf(payload);
        state.insurances[selectedIndex].dragging = true;
    },

    INSURANCE_ON_LEAVING(state, payload) {
        const selectedIndex = state.insurances.indexOf(payload);
        state.insurances[selectedIndex].dragging = false;
    },

    FETCH_TERMS(state, payload) {
        state.terms = payload
    },

    // guideline.store.js
    GUIDELINE_DATE(state, payload) {
        state.guidelineDate = payload
    },

    // guideline.store.js
    FETCH_INSURANCE_GUIDELINES(state, payload) {
        state.insuranceGuidelines = payload
    },

    // guideline.store.js
    FILTER_GUIDELINES(state) {
        state.selectedGuidelines = []
        state.optionalGuidelines = []
        state.insuranceGuidelines.forEach((gl) => {
            if (gl.optional == 'n' || gl.selected == 'y') {
                state.selectedGuidelines.push(gl)
            } else if (gl.optional == 'y') {
                state.optionalGuidelines.push(gl)
            }
        })
    },

    // guideline.store.js
    FETCH_EXTRA_GUIDELINES(state, payload) {
        payload.forEach((egl) => {
            state.selectedGuidelines.push({
                extraGuidelineID: egl.extraGuidelineID,
                insuranceID: egl.insuranceID,
                name: egl.name,
                optional: 'y',
                selected: 'y'
            })
        })
    },

    ADD_PREMIE_VALUE(state) {
        state.insurances.forEach((insurance) => {
            insurance.objects.forEach((object) => {
                if (!object.premie) {
                    object.premie = 0
                }
            })
        })
    }
}

export default mutations;