<template>
  <div class="customer-show w-100">
    <navbar />
    <div class="content p-4">
      <div class="row">
        <div class="col-md-12 mb-4">
          <h2 class="title">Att följas upp av mig</h2>
          <table
            class="table table-striped"
            v-if="openIssues != null && openIssues.length > 0"
          >
            <thead>
              <tr>
                <th scope="col">Tilldelad av</th>
                <th scope="col">Skapad</th>
                <th scope="col">Kund</th>
                <th scope="col">Anteckning</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(message, index) in openIssues" :key="index">
                <td>{{ message.createdBy }}</td>
                <td>{{ message.created }}</td>
                <td>
                  <router-link
                    :to="{ name: 'customer-show', params: { id: message.id } }"
                    >{{
                      message.category == "co"
                        ? message.companyName
                        : message.firstName
                    }}
                    {{
                      message.category == "co" ? "" : message.lastName
                    }}</router-link
                  >
                </td>
                <td>{{ message.note }}</td>
              </tr>
            </tbody>
          </table>
          <div
            class="alert alert-secondary"
            role="alert"
            v-if="openIssues == null || openIssues.length == 0"
          >
            <p>Inga ärenden</p>
          </div>
        </div>
        <div class="col-md-12 mb-4">
          <h2 class="title">Ärenden jag tilldelat andra</h2>
          <table
            class="table table-striped"
            v-if="assignedIssues != null && assignedIssues.length > 0"
          >
            <thead>
              <tr>
                <th scope="col">Tilldelad</th>
                <th scope="col">Skapad</th>
                <th scope="col">Kund</th>
                <th scope="col">Anteckning</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(message, index) in assignedIssues" :key="index">
                <td>{{ message.assignee }}</td>
                <td>{{ message.created }}</td>
                <td>
                  <router-link
                    :to="{ name: 'customer-show', params: { id: message.id } }"
                    >{{
                      message.category == "co"
                        ? message.companyName
                        : message.firstName
                    }}
                    {{
                      message.category == "co" ? "" : message.lastName
                    }}</router-link
                  >
                </td>
                <td>{{ message.note }}</td>
              </tr>
            </tbody>
          </table>
          <div
            class="alert alert-secondary"
            role="alert"
            v-if="assignedIssues == null || assignedIssues.length == 0"
          >
            <p>Inga ärenden</p>
          </div>
        </div>
        <div class="col-md-12 mb-4">
          <h2 class="title">Utförda ärenden</h2>
          <table
            class="table table-striped"
            v-if="closedIssues != null && closedIssues.length > 0"
          >
            <thead>
              <tr>
                <th scope="col">Skapad av</th>
                <th scope="col">Tidpunkt</th>
                <th scope="col">Avslutad av</th>
                <th scope="col">Kund</th>
                <th scope="col">Anteckning</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(message, index) in closedIssues" :key="index">
                <td>{{ message.createdBy }}</td>
                <td>{{ message.created }}</td>
                <td>{{ message.assignee }}</td>
                <td>
                  <router-link
                    :to="{ name: 'customer-show', params: { id: message.id } }"
                    >{{
                      message.category == "co"
                        ? message.companyName
                        : message.firstName
                    }}
                    {{
                      message.category == "co" ? "" : message.lastName
                    }}</router-link
                  >
                </td>
                <td>{{ message.note }}</td>
              </tr>
            </tbody>
          </table>
          <div
            class="alert alert-secondary"
            role="alert"
            v-if="closedIssues == null || closedIssues.length == 0"
          >
            <p>Inga ärenden</p>
          </div>
        </div>
      </div>
      <pulse-loader :loading="loading"></pulse-loader>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import { mapState } from "vuex";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

var moment = require("moment");
moment.locale("sv");

export default {
  name: "alerts",
  data() {
    return {
      loading: false,
      error: null,
      openIssues: [],
      closedIssues: [],
      assignedIssues: [],
    };
  },

  components: {
    Navbar,
    PulseLoader,
  },

  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      hidden: (state) => state.sidebar.hidden,
      apiKey: (state) => state.auth.apiKey,
    }),
  },

  mounted() {
    this.fetchAlerts();
  },

  methods: {
    fetchAlerts() {
      this.loading = true;
      this.$axios
        .get(this.baseUrl + "api/getAlertHistory.php?key=" + this.apiKey)
        .then((response) => {
          if (response.data.status == "ok") {
            this.openIssues = response.data.openIssues;
            this.closedIssues = response.data.closedIssues;
            this.assignedIssues = response.data.assignedIssues;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },

    convertDate(date) {
      return date.format("D MMM YYYY, HH:mm");
    },
  },
};
</script>

<style lang="scss" scoped>
.customer-show {
  .title {
    font-size: 20px;
    font-weight: 600;
  }
  .alert p {
    margin-bottom: 0;
  }
  .table {
    th {
      font-size: 15px;
    }
    td {
      font-size: 0.96rem;
      a {
        color: #4c4c4c;
        font-weight: 600;
      }
    }
    tr:hover {
      td a {
        color: #3498db;
      }
    }
  }
}
</style>
