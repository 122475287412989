<template>
  <div class="damage-details w-100">
    <navbar headertext="Skada" />
    <div class="content p-4">
      <CustomerHeader
        v-if="customer != null"
        :customer="customer"
        :damageID="damageID"
        @toggleCustomer="toggleCustomer($event)"
      />
      <DamageHeader
        v-if="damage != null"
        :damage="damage.damage"
        @edit="editDamage"
      />

      <div class="row">
        <div class="col-md-4">
          <div class="col-md-12 whitebox">
            <NewNote
              v-if="customer != null && users.length > 0"
              @update="fetchDamage()"
              :enableCustomer="false"
              :damageID="damageID"
              :customer="customer"
              :users="users"
            />
            <br />
          </div>
          <div class="col-md-12 whitebox">
            <Notes
              v-if="damage != null && damage.notes != null"
              @update="fetchDamage()"
              :customer="customer"
              :messages="damage.notes"
            />
          </div>
        </div>

        <div class="col-md-4">
          <div class="col-md-12 whitebox pb-4 pt-2">
            <h4>Kostnader</h4>
            <div class="form-group">
              <select
                class="form-control"
                id="cost-category"
                v-model="subCategory"
              >
                <option :value="null">Välj kategori</option>
                <option
                  v-for="(sc, index) in subCategories"
                  :key="index"
                  :value="sc.categoryID"
                >
                  {{ sc.category }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <input
                type="number"
                min="0"
                @keyup.13="saveCost()"
                class="form-control"
                id="cost"
                v-model="amount"
                placeholder="Ange belopp"
              />
            </div>
            <div class="form-group">
              <datepicker
                format="yyyy-MM-dd"
                input-class="form-control form-control-lg"
                :bootstrap-styling="true"
                v-model="dueDate"
                :language="sv"
                placeholder="Fakturans förfallodatum"
              ></datepicker>
            </div>

            <button
              type="button"
              class="btn btn-secondary btn-block float-right btn-size btnGr"
              :disabled="!subCategory || !amount"
              @click="saveCost()"
            >
              Lägg till
            </button>
            <br />
          </div>
          <div class="col-md-12 whitebox mt-3 pb-5 pt-3">
            <h4>Självrisk</h4>
            <select
              v-model="autoSr"
              class="form-control"
              :style="srChanged && !btnDisabled ? warningStyle : ''"
              @change="srSelectChanged()"
            >
              <option value="0">Manuell självrisk</option>
              <option value="1200">Grundsjälvrisk</option>
              <option value="3000">Högre självrisk</option>
              <option value="10">10% av PBB</option>
              <option value="20">20% av PBB</option>
              <option value="25">25% av PBB</option>
              <option value="50">50% av PBB</option>
              <option value="75">75% av PBB</option>
              <option value="100">Ett PBB</option>
            </select>
            <input
              class="form-control mt-3"
              type="number"
              placeholder="Självrisk"
              v-model="manSr"
              @keyup="updatePayback()"
              :disabled="autoSr > 0"
            />
            <p class="mt-3">
              <b>Ersättning:</b>
              {{ payback.toLocaleString("sv") }}
            </p>
            <button
              class="btn-size float-right btnGr"
              type="button"
              :class="btnClass"
              :disabled="btnDisabled"
              @click="saveSr()"
            >
              {{ btnText }}
            </button>
          </div>
          <br />
          <div class="col-md-12 whitebox" style="padding:10px;">
            <FileUpload
              uploadUrl="api/upload.php?"
              :docs="docs"
              @update="fetchFiles()"
            />
          </div>
        </div>
        <div class="col-md-4 whitebox">
          <NewNote style="border:1px solid #eaebed; margin-top:10px;"
            v-if="customer != null && users.length > 0 && enableCustomer"
            @update="closeCustomerNote()"
            :enableCustomer="true"
            :customer="customer"
            :damageID="damageID"
            :users="users"
          />

          <div class="table-responsive costs mt-4">
            <b-table :fields="fields" :items="damageCosts" foot-clone>
              <template #cell(cost)="data">
                {{ data.value.toLocaleString("sv") }}
              </template>
              <template #cell(Andel)="data">
                {{ Math.round((data.item.cost / costSum) * 100) }}%
              </template>
              <template #cell(link)="data">
                <a href="#" @click.prevent="deleteCost(data.item.costID)">x</a>
              </template>
              <template #foot(category)>
                <b>Summa</b>
              </template>
              <template #foot(cost)>
                {{ costSum.toLocaleString("sv") }}
              </template>
              <template #foot(Andel)> {{}} </template>
            </b-table>
          </div>
          <div class="col-md-12">
            <div class="row">
              <h5 style="margin-left: 10px" v-if="damage !== null">
                Prognostiserad kostnad: {{ damage.damage.prognosis }}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Datepicker from "vuejs-datepicker";
import CustomerHeader from "@/components/CustomerHeader.vue";
import { sv } from "vuejs-datepicker/dist/locale";
import Navbar from "@/components/Navbar.vue";
import Notes from "@/components/Notes.vue";
import DamageHeader from "@/components/DamageHeader.vue";
import NewNote from "@/components/NewNote.vue";
import FileUpload from "@/components/FileUpload.vue";
import { required } from "vuelidate/lib/validators";
import { parsePostcode, parsePhone } from "@/_helpers/parser";
var moment = require("moment");
moment.locale("sv");

export default {
  name: "damage-details",
  data() {
    return {
      sv: sv,
      damage: null,
      note: null,
      submitStatus: null,
      subCategories: [],
      users: [],
      user: null,
      notification: "mail",
      successMassage: null,
      btnDisabled: true,
      btnText: "Sparad",
      subCategory: null,
      normalEvent: "event mb-3",
      customerEvent: "event mb-3 customer-event",
      mailEvent: "event mb-3 mail-event",
      btnClass: "btn btn-secondary btn-block",
      amount: null,
      costSum: 0,
      damageCosts: [],
      dueDate: null,
      docs: [],
      damageID: 0,
      autoSr: 0,
      manSr: null,
      payback: 0,
      enableCustomer: false,
      customer: null,
      customerMsg: null,
      error: null,
      fields: [
        { key: "category", label: "Kategori" },
        { key: "cost", label: "Kostnad" },
        { key: "Andel" },
        { key: "link", label: "" },
      ],
      srChanged: false,
      warningStyle: {
        background: "#FADBD8",
        borderColor: "#E74C3C",
      },
    };
  },

  validations: {
    note: { required },
  },

  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      apiKey: (state) => state.auth.apiKey,
    }),
  },

  components: {
    Navbar,
    Datepicker,
    CustomerHeader,
    NewNote,
    Notes,
    DamageHeader,
    FileUpload,
  },

  mounted() {
    this.fetchDamageCosts();
    this.fetchDamage();
    this.fetchUsers();
    this.fetchFiles();

    this.srChanged = false;
  },

  beforeRouteLeave(to, from, next) {
    if (this.files != null && this.files.length > 0) {
      const answer = window.confirm(
        "Du har inte sparat filerna. Vill du radera dem?"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else if (this.note != null && this.note.length > 0) {
      const answer = window.confirm(
        "Du har inte sparat anteckningen. Vill du radera den?"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else if (this.amount != null && this.amount.length > 0) {
      const answer = window.confirm(
        "Du har inte sparat kostnaden. Vill du radera den?"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else if (this.btnDisabled == false) {
      const answer = window.confirm(
        "Du har inte sparat självrisken. Vill du radera den?"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },

  methods: {
    toggleCustomer(value) {
      this.enableCustomer = value;
    },

    closeCustomerNote() {
      this.enableCustomer = false;
      this.fetchDamage();
    },

    updatePayback() {
      if (this.damage == null) {
        return;
      }
      this.payback = this.costSum - this.manSr;
      if (this.damage.damage.srtyp == 0) {
        if (this.damage.damage.srman == this.manSr) {
          this.btnClass = "btn btn-secondary btn-block";
          this.btnDisabled = true;
          this.btnText = "Sparad";
        } else {
          this.btnClass = "btn btn-danger btn-block";
          this.btnDisabled = false;
          this.btnText = "Spara";
        }
      }
    },

    srSelectChanged() {
      this.srChanged = true;
      this.updateSr();
    },

    updateSr() {
      var thePbb = this.damage.damage.pbb;
      if (this.autoSr > 0 && this.autoSr <= 100) {
        this.manSr = Math.round((thePbb * this.autoSr) / 100);
      } else if (this.autoSr > 100) {
        this.manSr = this.autoSr;
      }
      this.updatePayback();
      if (this.damage.damage.srtyp != this.autoSr) {
        this.btnClass = "btn btn-danger btn-block";
        this.btnDisabled = false;
        this.btnText = "Spara";
      } else {
        this.btnClass = "btn btn-secondary btn-block";
        this.btnDisabled = true;
        this.btnText = "Sparad";
      }
    },
    fetchDamage() {
      this.damageID = this.$route.params.damageID;
      this.$axios
        .get(
          this.baseUrl +
            "api/getDamage.php?id=" +
            this.damageID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.damage = response.data;
            if (this.damage.damage.srtyp == null) {
              this.damage.damage.srtyp = 0;
            }
            this.autoSr = this.damage.damage.srtyp;
            if (this.autoSr == 0) {
              if (this.damage.damage.srman == null) {
                this.damage.damage.srman = 0;
              }
              this.manSr = this.damage.damage.srman;
            }
            this.customer = this.damage.customer;
            this.updateSr();
            this.damage.damage.fastighet = this.damage.insurance.fastighet;
            this.fetchSubCategories(this.damage.damage.categoryID);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    fetchSubCategories() {
      this.$axios
        .get(this.baseUrl + "api/get.php?type=subCategories&key=" + this.apiKey)
        .then((response) => {
          if (response.data.status == "ok") {
            this.subCategories = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    fetchDamageCosts() {
      let damageID = this.$route.params.damageID;
      this.$axios
        .get(
          this.baseUrl +
            "api/getDamageCosts.php?id=" +
            damageID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.damageCosts = response.data.data;
            this.costSum = 0;
            for (var i = 0; i < this.damageCosts.length; i++) {
              this.costSum += this.damageCosts[i].cost;
            }
            this.updatePayback();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    convertDate(date) {
      return moment(date).format("D MMM YYYY, HH:mm");
    },

    fetchUsers() {
      this.$axios
        .get(this.baseUrl + "api/get.php?type=users&key=" + this.apiKey)
        .then((response) => {
          if (response.data.status == "ok") {
            this.users = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    custMsg(val) {
      if (!val) {
        this.customerMsg = null;
      }
    },

    deleteCost(costID) {
      this.$swal
        .fire({
          icon: "warning",
          title: "Vill du verkligen ta bort kostnaden?",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Radera",
          cancelButtonText: "Avbryt",
        })
        .then((result) => {
          if (result.isConfirmed) {
            var formData = new FormData();
            formData.append("id", costID);
            this.$axios
              .post(
                this.baseUrl + "api/deleteCost.php?key=" + this.apiKey,
                formData
              )
              .then(() => {
                this.fetchDamageCosts();
              });
          }
        });
    },

    saveCost() {
      let damageID = this.$route.params.damageID;

      if (this.amount < 0) {
        this.amount = null;
        return;
      }

      var formData = new FormData();
      formData.append("damageID", damageID);
      formData.append("categoryID", this.subCategory);
      formData.append(
        "dueDate",
        this.dueDate != null ? moment(this.dueDate).format("YYYY-MM-DD") : ""
      );
      formData.append("amount", this.amount);
      this.$axios
        .post(this.baseUrl + "api/damageCost.php?key=" + this.apiKey, formData)
        .then((response) => {
          if (response.data.status == "ok") {
            this.amount = null;
            this.fetchDamageCosts();
          }
        });
    },

    saveSr() {
      let damageID = this.$route.params.damageID;
      var formData = new FormData();
      formData.append("id", damageID);
      formData.append("srtyp", this.autoSr);
      if (this.autoSr == 0) {
        formData.append("srman", this.manSr);
      }
      this.$axios
        .post(this.baseUrl + "api/sr.php?key=" + this.apiKey, formData)
        .then((response) => {
          if (response.data.status == "ok") {
            this.srChanged = false;
            this.fetchDamage();
          }
        });
    },

    closeAlert(id) {
      const answer = window.confirm("Är du säker på att du vill klarmarkera?");
      if (answer) {
        this.$axios
          .get(
            this.baseUrl + "api/closeAlert.php?key=" + this.apiKey + "&id=" + id
          )
          .then((response) => {
            if (response.data.status == "ok") {
              this.fetchDamage();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    nicePostcode(pc) {
      return parsePostcode(pc);
    },

    nicePhone(tfn) {
      return parsePhone(tfn);
    },

    fetchFiles() {
      this.loading = true;
      this.docs = [];
      let damageID = this.$route.params.damageID;
      this.$axios
        .get(
          this.baseUrl +
            "api/get.php?type=files&damageID=" +
            damageID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.docs = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    editDamage() {
      const damageID = this.$route.params.damageID;
      this.$router.push({ name: "edit-damage", params: { damageID } });
    },
  },
};
</script>

<style lang="scss">
.btn-size {
  width: 100px;
  margin-bottom: 10px;
}

.damage-details {
  background-color: #fafafa;
  .damages {
    display: flex;
    flex-direction: row;
    padding: 20px;
    background: #f1f1f1;
    .card {
      flex: 1;
      // max-width: 500px;
    }
    .insurance {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .customer {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    table {
      th {
        font-size: 14px;
        padding-right: 10px;
      }
    }
  }
  .events {
    margin-top: 16px;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
  .customer-event {
    background: #ffff99 !important;
  }
  .mail-event {
    background: #ccccff !important;
  }

  .event {
    padding: 10px 15px;
    background: #f7f7f7;
    border-radius: 5px;
    .event-text {
      font-size: 16px;
    }
    .author {
      font-size: 12px;
      font-weight: 600;
      color: #797979;
      margin: 0;
      span:not(:last-child) {
        padding-right: 8px;
      }
      svg {
        font-size: 14px;
      }
    }
    .separator {
      color: #000;
    }
  }

  .costs {
    table {
      th {
        font-size: 15px;
      }
    }
  }

  @media (max-width: 1725.98px) {
    .damages {
      flex-direction: column;
      .card {
        width: 100%;
      }
    }
  }
}
</style>