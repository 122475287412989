<template>
  <nav
    class="sidebar"
    :class="{ hidden: hidden, visible: visible }"
    v-if="auth() && !isPartner"
  >
    <div class="side-header">
      <b-navbar-brand :to="{ name: 'home' }">
        <img
          class="nav-logo"
          src="@/assets/f-logo.svg"
          alt
          width="25"
          style="margin-bottom: 3px"
        />
      </b-navbar-brand>
      <h5>Fourth 2.10.6</h5>
    </div>
    <ul class="list-unstyled">
      <template v-if="menues.length > 0">
        <li
          v-for="(menu, index) in menues"
          :key="index"
          :class="{ active: menu.collapsed }"
        >
          <template
            v-if="
              (menu.name == 'users' ||
                menu.name == 'system' ||
                menu.name == 'backup' ||
                menu.name == 'article-admin') &&
              isAdmin
            "
          >
            <router-link
              class="menu"
              :to="{ name: menu.name }"
              @click.native="activeBar()"
            >
              <span class="menu-icon">
                <font-awesome-icon :icon="menu.icon" />
              </span>
              <span class="menu-text">{{ menu.title }}</span>
              <span v-if="menu.badge != null">
                &nbsp;
                <b-badge>{{ totalAlerts }}</b-badge>
              </span>
              <span
                class="caret-up"
                v-show="menu.children.length > 0 && menu.collapsed"
              >
                <font-awesome-icon
                  icon="caret-up"
                  :style="{ color: 'white' }"
                />
              </span>
              <span
                class="caret-down"
                v-show="menu.children.length > 0 && !menu.collapsed"
              >
                <font-awesome-icon icon="caret-down" />
              </span>
            </router-link>
            <ul class="list-unstyled" id="pageSubmenu">
              <li v-for="(child, key) in menu.children" :key="key">
                <router-link
                  :to="{ name: child.name, params: { userId: 123 } }"
                  >{{ child.title }}</router-link
                >
              </li>
            </ul>
          </template>
          <template
            v-if="
              menu.name != 'users' &&
              menu.name != 'system' &&
              menu.name != 'backup' &&
              menu.name != 'article-admin'
            "
          >
            <router-link
              class="menu"
              :to="{ name: menu.name }"
              @click.native="activeBar()"
            >
              <span class="menu-icon">
                <font-awesome-icon :icon="menu.icon" />
              </span>
              <span class="menu-text">{{ menu.title }}</span>
              <span v-if="menu.badge != null">
                &nbsp;
                <b-badge>{{ totalAlerts }}</b-badge>
              </span>
              <span
                class="caret-up"
                v-show="menu.children.length > 0 && menu.collapsed"
              >
                <font-awesome-icon
                  icon="caret-up"
                  :style="{ color: 'white' }"
                />
              </span>
              <span
                class="caret-down"
                v-show="menu.children.length > 0 && !menu.collapsed"
              >
                <font-awesome-icon icon="caret-down" />
              </span>
            </router-link>
            <ul class="list-unstyled" id="pageSubmenu">
              <li v-for="(child, key) in menu.children" :key="key">
                <router-link
                  :to="{ name: child.name, params: { userId: 123 } }"
                  >{{ child.title }}</router-link
                >
              </li>
            </ul>
          </template>
        </li>
      </template>
    </ul>
  </nav>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { isAuthenticated } from "@/_helpers/auth";
import menues from "@/assets/menues.json";
export default {
  data() {
    return {
      newAlerts: 0,
      totalAlerts: 0,
      status: true,
      menues: menues,
    };
  },

  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      apiKey: (state) => state.auth.apiKey,
      authenticated: (state) => state.auth.authenticated,
      accesslevel: (state) => state.auth.accesslevel,
      hidden: (state) => state.sidebar.hidden,
      visible: (state) => state.sidebar.visible,
    }),

    ...mapGetters("auth", ["isAdmin", "isUser", "isPartner"]),
  },

  mounted() {
    this.activeBar();
    if (isAuthenticated) {
      this.checkAlerts();
    }
  },

  methods: {
    auth() {
      return this.authenticated && this.$route.name !== "login" ? true : false;
    },

    checkAlerts() {
      this.$axios
        .get(this.baseUrl + "api/getAlerts.php?key=" + this.apiKey)
        .then((response) => {
          if (response.data.status == "ok") {
            this.newAlerts = response.data.newAlerts;
            this.totalAlerts = response.data.totalAlerts;
            setTimeout(this.checkAlerts, 60000);
          } else {
            setTimeout(this.checkAlerts, 60000);
            this.$router.push({ name: "login" });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    itemClicked(selectedIndex) {
      this.menues.forEach((menu, index) => {
        if (index == selectedIndex) {
          menu.collapsed = !menu.collapsed;
        } else {
          menu.collapsed = true;
        }
      });
      this.activeBar();
    },

    activeBar() {
      let routeName = this.$route.name;
      this.selectMenu(routeName);
    },

    selectMenu(name) {
      this.menues.forEach((menu) => {
        if (menu.name == name) {
          menu.collapsed = true;
        } else {
          menu.collapsed = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  z-index: 1000;
  position: fixed;
  left: 250px;
  width: 0;
  border-right: 1px solid #ddd;
  min-height: 100vh;
  margin-left: -250px;
  padding-left: 0;
  overflow-y: auto;
  transition-duration: 600ms;
  transition-timing-function: ease-in-out;
  background: #fafafa;
  transition: all 0.5s ease;

  .side-header {
    background-color: #5aa196;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    padding: 0.35rem;
    border-bottom: 1px solid #ddd;
    margin-bottom: 25px;
    padding: 0.5rem 1rem;

    h5 {
      margin: 0;
      padding: 0;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 600;
    }
  }
  ul {
    color: #2c3e50;
    .components {
      padding: 20px 0;
      border-bottom: 1px solid #47748b;
    }
    li {
      a {
        padding: 10px 10px 10px 30px;
        font-size: 1.1em;
        display: block;
        position: relative;
        color: #5a5a5a;
        font-weight: 500;
        letter-spacing: 0.5px;
        &:hover {
          color: #17a2b8;
        }
        .menu-icon {
          display: inline-block;
          width: 1.25em;
          margin-right: 0.8em;
          text-align: center;
          font-size: 1.125rem;
          color: #a6abbd;
        }
        .menu-text {
          font-size: 15px;
        }
        .caret-up,
        .caret-down {
          position: absolute;
          right: 20px;
          opacity: 0.8;
        }
      }
      ul {
        display: none;
        background: #f1f1f1;
        li {
          a {
            font-size: 0.85em !important;
            padding-left: 72px !important;
            font-weight: 600;
          }
          &:hover {
            a {
              font-weight: 600;
            }
          }
        }
        li.active {
          a {
            font-weight: 600;
          }
        }
      }
    }
    li.active {
      background: #5aa196;
      .menu-icon,
      .menu-text {
        color: #fff;
      }
      ul {
        display: block;
      }
    }
  }

  a[data-toggle="collapse"] {
    position: relative;
  }

  .dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
}
.sidebar.hidden {
  margin-left: -250px;
}
.menu-text {
  color: #5a5a5a;
  font-weight: 500;
  letter-spacing: 0.5px;
}
</style>