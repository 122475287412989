<template>
  <div class="row">
    <div class="col-md-12">
      <pulse-loader :loading="loading"></pulse-loader>
      <div v-if="insurances.length > 0">
        <div class="table-responsive content whitebox">
          <table class="table table-striped">
            <thead>
              <tr>
                <th class="border-top-0">Produkt</th>
                <th class="border-top-0 text-right">Värde</th>
                <th class="border-top-0 text-right">Avgift</th>
                <th class="border-top-0 text-right">Premie</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="ins in insurances">
                <tr :key="'prod' + ins.productID">
                  <td>
                    <strong>{{ ins.name }}</strong>
                  </td>
                  <td class="text-right">
                    {{
                      ins.objectValue == null
                        ? ""
                        : formatValue(ins.objectValue)
                    }}
                  </td>
                  <td class="text-right">
                    {{
                      ins.flatFee == null
                        ? ""
                        : ins.flatFee.toLocaleString("sv")
                    }}
                  </td>
                  <td class="text-right">
                    {{
                      ins.premie == null ? "" : ins.premie.toLocaleString("sv")
                    }}
                  </td>
                </tr>
                <tr
                  v-for="object in ins.objects"
                  :key="'obj' + object.objectID"
                >
                  <td class="pl-4">
                    {{ object.title }}
                    {{
                      object.property != null && object.property.length > 0
                        ? "(" + object.property + ")"
                        : ""
                    }}
                  </td>
                  <td class="text-right">
                    {{
                      object.objectValue == null
                        ? ""
                        : formatValue(object.objectValue)
                    }}
                  </td>
                  <td class="text-right">
                    {{
                      object.flatFee == null
                        ? ""
                        : object.flatFee.toLocaleString("sv")
                    }}
                  </td>
                  <td class="text-right">
                    {{
                      object.premie == null
                        ? ""
                        : object.premie.toLocaleString("sv")
                    }}
                  </td>
                </tr>
                <template v-for="sp in ins.subProducts">
                  <tr :key="'sp' + sp.productID">
                    <td class="pl-4">
                      <strong>{{ sp.name }}</strong>
                    </td>
                    <td class="text-right">
                      {{
                        sp.objectValue == null
                          ? ""
                          : formatValue(sp.objectValue)
                      }}
                    </td>
                    <td class="text-right">
                      {{
                        sp.flatFee == null
                          ? ""
                          : sp.flatFee.toLocaleString("sv")
                      }}
                    </td>
                    <td class="text-right">
                      {{
                        sp.premie == null ? "" : sp.premie.toLocaleString("sv")
                      }}
                    </td>
                  </tr>
                  <tr
                    v-for="(object, index) in sp.objects"
                    :key="'spobj' + sp.productID + index"
                  >
                    <td style="padding-left: 48px">{{ object.title }}</td>
                    <td class="text-right">
                      {{
                        object.objectValue == null
                          ? ""
                          : formatValue(object.objectValue)
                      }}
                    </td>
                    <td class="text-right">
                      {{
                        object.flatFee == null
                          ? ""
                          : object.flatFee.toLocaleString("sv")
                      }}
                    </td>
                    <td class="text-right">
                      {{
                        object.premie == null
                          ? ""
                          : object.premie.toLocaleString("sv")
                      }}
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { parsePostcode, parsePhone } from "@/_helpers/parser";
import { mapState } from "vuex";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  name: "customer-insurance",

  components: {
    PulseLoader,
  },

  computed: {
    ...mapState({
      loading: (state) => state.client.loading,
      insurances: (state) => state.client.insurances
    }),
  },

  methods: {
    convertDate(date) {
      return date.format("D MMM YYYY, HH:mm");
    },

    nicePostcode(pc) {
      return parsePostcode(pc);
    },

    nicePhone(tfn) {
      return parsePhone(tfn);
    },
    formatValue(value) {
      if (value > 999999999) {
        return (
          value.toString().substring(0, 6) + "-" + value.toString().substring(6)
        );
      } else {
        return value.toLocaleString("sv");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
pre {
  white-space: pre-wrap;
}
.customer-show {
  button {
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1px;
  }
  .profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #f1f1f1;
    padding: 10px 15px;
    border-radius: 5px;
    .content {
      margin-left: 10px;
      .name {
        font-weight: 600;
      }
      address,
      .contact-info {
        font-size: 14px;
        margin: 0;
      }
    }
    p {
      margin: 0;
      padding: 0;
    }
  }
  .events {
    .event {
      padding: 10px 15px;
      background: #f7f7f7;
      border-radius: 5px;
      .event-text {
        font-size: 16px;
      }
      .author {
        font-size: 12px;
        font-weight: 600;
        color: #797979;
        margin: 0;
        span:not(:last-child) {
          padding-right: 8px;
        }
        svg {
          font-size: 14px;
        }
      }
      .separator {
        color: #000;
      }
    }
  }
}
</style>
