<template>
  <div class="staff w-100">
    <navbar />
    <div class="content p-4">
      <div class="row">
        <div class="col-md-8 box-info">
          <h3>Systeminställningar</h3>
          <h6>
            Automatisk radering av gamla kunduppgifter efter
            {{ months }} månader
          </h6>
          <vue-slider
            v-model="months"
            :min="13"
            :max="60"
            :interval="1"
            :adsorb="true"
            :marks="marks"
          />
          <button
            style="margin-top: 32px"
            type="button"
            class="btn btn-secondary"
            @click="saveSettings"
          >
            Spara
          </button>
        </div>
      </div>
      <div class="row" style="margin-top: 16px">
        <div class="col-md-8 box-info">
          <h3>Prisbasbelopp</h3>
          <ul v-if="pbb.length > 0">
            <li v-for="(p, index) in pbb" :key="index">
              {{ p.start }} -- {{ p.slut }}:
              {{
                p.pbb.toLocaleString("sv-SE", {
                  style: "currency",
                  currency: "SEK",
                })
              }}
              <a href="#" @click.prevent="deletePbb(p.start)">x</a>
            </li>
          </ul>
          <h6>Nytt prisbasbelopp</h6>
          <b-form inline class="my-3">
            <b-form-group>
              <datepicker
                format="yyyy-MM-dd"
                input-class="form-control mr-3"
                :bootstrap-styling="true"
                :monday-first="true"
                v-model="start"
                :language="sv"
                placeholder="Välj startdatum"
              ></datepicker>
            </b-form-group>
            <b-form-group>
              <datepicker
                format="yyyy-MM-dd"
                input-class="form-control mr-3"
                :bootstrap-styling="true"
                :monday-first="true"
                v-model="slut"
                :language="sv"
                placeholder="Välj slutdatum"
              ></datepicker>
            </b-form-group>
            <b-form-group>
              <input
                type="number"
                class="form-control"
                name="pbbval"
                v-model="pbbval"
                placeholder="Prisbasbelopp"
              />
            </b-form-group>
          </b-form>
          <b-alert
            variant="danger"
            dismissible
            show
            v-if="errorMessage !== null"
            >{{ errorMessage }}</b-alert
          >
          <b-alert
            variant="success"
            dismissible
            show
            v-if="successMessage !== null"
            >{{ successMessage }}</b-alert
          >
          <button class="btn mb-3 btn-secondary" @click="savePbb">Spara</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import { mapState } from "vuex";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";
import Datepicker from "vuejs-datepicker";
import { sv } from "vuejs-datepicker/dist/locale";
import moment from "moment";
export default {
  name: "system",
  data() {
    return {
      autodelete: "0",
      months: 13,
      marks: [13, 20, 30, 40, 50, 60],
      pbb: [],
      start: null,
      slut: null,
      pbbval: null,
      sv: sv,
      errorMessage: null,
      successMessage: null,
    };
  },

  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      apiKey: (state) => state.auth.apiKey,
    }),
  },

  components: {
    Navbar,
    VueSlider,
    Datepicker,
  },

  mounted() {
    this.getSettings();
  },

  methods: {
    savePbb() {
      this.errorMessage = null;

      if (!this.start || !this.slut) {
        this.errorMessage = "Alla fält är obligatoriska";
      } else {
        var postdata = new FormData();
        postdata.append("start", moment(this.start).format("YYYY-MM-DD"));
        postdata.append("slut", moment(this.slut).format("YYYY-MM-DD"));
        postdata.append("pbb", this.pbbval);
        this.$axios
          .post(this.baseUrl + "api/pbb.php?key=" + this.apiKey, postdata)
          .then((response) => {
            if (response.data.status === "ok") {
              this.successMessage = "Ny prisbasbelopp har sparat";
              this.start = null;
              this.slut = null;
              this.pbbval = null;
              this.getSettings();
            } else if (response.data.status === "error") {
              this.errorMessage = "Något går fel, försök igen!";
            }
          });
      }
    },

    deletePbb(id) {
      var postdata = new FormData();
      postdata.append("id", id);
      this.$axios
        .post(this.baseUrl + "api/pbb.php?key=" + this.apiKey, postdata)
        .then(() => {
          this.getSettings();
        });
    },
    getSettings() {
      this.$axios
        .get(
          this.baseUrl + "api/get.php?type=systemSettings&key=" + this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok" && response.data.data != null) {
            this.autodelete = response.data.data[0].autoDelete;
            this.months = this.autodelete / 30;
          }
        });
      this.$axios
        .get(this.baseUrl + "api/get.php?type=pbb&key=" + this.apiKey)
        .then((response) => {
          if (response.data.status == "ok") {
            this.pbb = response.data.data;
          }
        });
    },
    saveSettings() {
      var formData = new FormData();
      formData.append("autoDelete", this.months * 30);
      this.$axios
        .post(
          this.baseUrl + "api/systemsettings.php?key=" + this.apiKey,
          formData
        )
        .then((response) => {
          if (response.data.status != "ok") {
            this.submitStatus = "ERROR";
            this.successMessage = null;
            this.errorMessage = response.data.error;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>