const state = {
    baseUrl: process.env.VUE_APP_URL,
    // authenticated: JSON.parse(localStorage.getItem('_fsfe_apiKey')) ? true : false, // // auth.store.js
    // hidden: false, // sidebar.store.js
    // apiKey: JSON.parse(localStorage.getItem('_fsfe_apiKey')) || null, // auth.store.js
    // realname: JSON.parse(localStorage.getItem('_fsfe_realname')) || null, // auth.store.js
    // accesslevel: JSON.parse(localStorage.getItem('_fsfe_accesslevel')) || null, // auth.store.js
    error: null,
    customer: null,
    customers: [],
    // categories: [], // product.store.js
    dateDiff: '',
    notes: [],
    products: [], // product.store.js
    category: null,
    standards: [],
    standard: null,
    // evaluationTypes: [], // evaluation.store.js
    guidelines: [], // guideline.store.js
    // selectedProducts: [], // product.store.js
    // subproducts: [], // product.store.js
    insurances: [],
    insuranceInfo: [],
    terms: [],
    guidelineDate: null, // guideline.store.js
    insuranceGuidelines: [], // guideline.store.js
    selectedGuidelines: [], // guideline.store.js
    optionalGuidelines: [], // guideline.store.js
    sr: '',
    standardSr: '',
    // visible: true, // sidebar.store.js
    brevtexts: [],
    discounts: []
}

export default state;