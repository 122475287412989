var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth"},[_vm._m(0),_vm._m(1),_c('form',{staticClass:"auth-form",attrs:{"action":"#","onsubmit":"return false;"}},[(_vm.loginAlert != null)?_c('div',{staticClass:"alert",class:{
        'alert-info': _vm.submitStatus == 'INFO',
        'alert-danger': _vm.submitStatus == 'ERROR',
      },attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.loginAlert)+" ")]):_vm._e(),(_vm.loginStatus !== 'sms' && _vm.loginStatus !== 'pass')?_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],ref:"username",staticClass:"form-control form-control-lg",class:{ 'is-invalid': _vm.submitStatus == 'ERROR' && _vm.$v.email.$error },attrs:{"type":"text","id":"username","autocomplete":"off","placeholder":"Användarnamn","name":"username"},domProps:{"value":(_vm.email)},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==9){ return null; }_vm.loginStatus == null ? _vm.auth() : _vm.passAuth()},function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==13){ return null; }_vm.loginStatus == null ? _vm.auth() : _vm.passAuth()}],"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),(!_vm.$v.email.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Du måste ange e-postadress ")]):_vm._e(),(!_vm.$v.email.email)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Ange giltig e-postadress ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"form-group"},[(_vm.loginStatus == null)?_c('button',{staticClass:"btn btn-secondary btn-lg btn-block",attrs:{"type":"button","id":"send"},on:{"click":function($event){return _vm.auth()}}},[_vm._v(" Skicka ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[(_vm.loginStatus == 'pass' || _vm.loginStatus == 'sms')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],ref:"password",staticClass:"form-control form-control-lg",class:{ 'is-invalid': _vm.submitStatus == 'ERROR' && _vm.passError },attrs:{"placeholder":"Lösenord","type":"password","id":"password","name":"password"},domProps:{"value":(_vm.password)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==13){ return null; }_vm.loginStatus == null
            ? _vm.auth()
            : _vm.loginStatus == 'sms'
            ? this.$refs.sms.focus()
            : _vm.passAuth()},"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}):_vm._e(),(_vm.passError)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Du måste ange lösenord ")]):_vm._e(),(_vm.loginStatus == 'sms')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sms),expression:"sms"}],staticClass:"form-control form-control-lg",attrs:{"placeholder":"SMS-kod","type":"number","id":"sms","name":"sms"},domProps:{"value":(_vm.sms)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==13){ return null; }return _vm.passAuth()},"input":function($event){if($event.target.composing){ return; }_vm.sms=$event.target.value}}}):_vm._e()]),((_vm.loginStatus == 'sms' || _vm.loginStatus == 'pass') && !_vm.loading)?_c('button',{staticClass:"btn btn-secondary btn-lg btn-block",attrs:{"type":"button","id":"login"},on:{"click":function($event){return _vm.passAuth()}}},[_vm._v(" Logga in ")]):_vm._e(),(_vm.loading)?_c('button',{staticClass:"btn btn-secondary btn-lg btn-block",attrs:{"type":"button"}},[_c('pulse-loader',{attrs:{"loading":_vm.loading}})],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.error != null),expression:"error != null"}],staticClass:"alert alert-danger mt-2",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.error)+" ")])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"brand text-center mb-2"},[_c('img',{staticStyle:{"padding-bottom":"32px"},attrs:{"src":require("../assets/f-logo.svg"),"alt":"Fouth","width":"70px"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center mb-4"},[_c('h3',{staticStyle:{"color":"white"}},[_vm._v("Logga in")])])}]

export { render, staticRenderFns }