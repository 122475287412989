import axios from "../../axios"

const state = {
    categories: [],
    standards: [],
    products: [],
    subproducts: [],
    selectedProducts: []
}

const mutations = {
    FETCH_CATEGORIES(state, payload) {
        state.categories = payload
    },

    FETCH_STANDARDS(state, payload) {
        state.standards = payload
    },

    FETCH_PRODUCTS(state, payload) {
        state.products = payload
    },

    FETCH_SUBPRODUCTS(state, payload) {
        state.subproducts = payload
    },

    FETCH_SELECTED_PRODUCTS(state, payload) {
        if (payload.length > 0) {
            payload.map((pl) => {
                pl.children = []
                pl.files = []
                pl.draggable = false
                state.subproducts.map((product) => {
                    if (pl.productID == product.parentID) {
                        product.selected = false
                        product.draggable = true
                        pl.children.push(product);
                    }
                });
            });
        }
        state.selectedProducts = payload
    },
}

const actions = {
    fetchCategories({ rootState, commit }) {
        return new Promise((resolve, reject) => {
            axios.get(rootState.baseUrl + "api/getCategories.php?key=" + rootState.auth.apiKey)
                .then((response) => {
                    if (response.data.status == 'ok') {
                        commit('FETCH_CATEGORIES', response.data.data)
                        resolve(response)
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    fetchStandards({ rootState, commit }) {
        return new Promise((resolve, reject) => {
            axios.get(rootState.baseUrl + "api/get.php?type=forms&key=" + encodeURIComponent(rootState.auth.apiKey))
                .then((response) => {
                    if (response.data.status == 'ok') {
                        commit('FETCH_STANDARDS', response.data.data)
                        resolve(response)
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    fetchProducts({ rootState, commit }) {
        return new Promise((resolve, reject) => {
            axios.get(rootState.baseUrl + 'api/get.php?type=products&key=' + encodeURIComponent(rootState.auth.apiKey))
                .then((response) => {
                    var products = []
                    if (response.data.data.length > 0) {
                        response.data.data.map((data) => {
                            data.selected = false
                            data.products = []
                            products.push(data)
                        })
                    }
                    commit('FETCH_PRODUCTS', response.data.data)
                    resolve(response.data)
                })
                .catch((error) => {
                    commit('ERRORS', error)
                    reject(error)
                })
        })
    },

    fetchSubProducts({ rootState, commit }) {
        return new Promise((resolve, reject) => {
            axios.get(rootState.baseUrl + 'api/get.php?type=subProducts&key=' + encodeURIComponent(rootState.auth.apiKey))
                .then((response) => {
                    if (response.data.status == 'ok') {
                        commit('FETCH_SUBPRODUCTS', response.data.data)
                        resolve(response.data.data)
                    }
                })
                .catch((error) => {
                    commit('ERRORS', error)
                    reject(error)
                })
        })
    },

    fetchSelectedProducts({ rootState, commit }, insuranceID) {
        return new Promise((resolve, reject) => {
            axios.get(rootState.baseUrl + 'api/get.php?type=draftProducts&insuranceID=' + encodeURIComponent(insuranceID) + '&key=' + encodeURIComponent(rootState.auth.apiKey))
                .then((response) => {
                    console.log(response.data)
                    if (response.data.status == 'ok') {
                        commit('FETCH_SELECTED_PRODUCTS', response.data.data)
                        resolve(response.data.data)
                    }
                })
                .catch((error) => {
                    commit('ERRORS', error)
                    reject(error)
                })
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
