<template>
  <div class="economy w-100">
    <navbar />
    <div class="content p-4 fourth">
      <div class="row">
        <div class="box-info col-md-12">
          <div class="col-md-10">
            <p>Kundfordringar: {{ totalBalance.toLocaleString("sv") }} SEK</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import { mapState } from "vuex";

export default {
  name: "economy",
  components: {
    Navbar,
  },
  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      hidden: (state) => state.sidebar.hidden,
      apiKey: (state) => state.auth.apiKey,
      accesslevel: (state) => state.auth.accesslevel,
    }),
  },

  data() {
    return {
      totalBalance: 0,
    };
  },

  mounted() {
    this.getBalance();
  },

  methods: {
    getBalance() {
      this.$axios
        .get(this.baseUrl + "api/get.php?type=totalBalance&key=" + this.apiKey)
        .then((response) => {
          if (response.data.status == "ok") {
            this.totalBalance = response.data.data.balance;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
</style>