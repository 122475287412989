<template>
    <div class="card" :class="{'selected': selected }">
        <div class="card-body">
            <div class="product">
                <font-awesome-icon icon="check-circle" v-if="selected" :style="{ color: '#17a2b8' }" />
                <!-- <router-link :to="{ name: 'home' }">{{ product.name }}</router-link> -->
                <button v-if="hasChildren" type="button" class="btn btn-link" @click="navigateRoute(product)">{{ product.name }}</button>
                <span v-else>{{ product.name }}</span>
            </div>
            <div class="actions">
                <button type="button" class="btn btn-link edit" @click="editProduct(product)">
                    <font-awesome-icon icon="edit" />
                </button>
                <button type="button" class="btn btn-link show" @click="showProductContent(product)">
                    <font-awesome-icon icon="file-alt" />
                </button>
            </div>
            <!-- <font-awesome-icon icon="angle-right" v-if="product.products && product.products.length > 0" /> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'card',
    props: {
        product: {
            type: Object,
            required: true
        },
        hasChildren: {
            type: Boolean,
            required: true
        },
        selected: {
            type: Boolean,
            required: true
        }
    },

    methods: {
        editProduct(product) {
            this.$emit('edit-product', product)
        },

        showProductContent(product) {
            this.$emit('show-product', product)
        },

        navigateRoute(product) {
            this.$emit('navigate', product.productID)
        }
    }
}
</script>

<style lang="scss" scoped>
.card {
    border-radius: 0;
    &:not(:last-child) {
        border-bottom: 0;
    }
    &:hover {
        background: #f1f1f1;
    }

    .card-body {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .product {
            background: transparent;
            svg {
                margin-right: 15px;
            }
            button {
                font-size: 15px;
                font-weight: 600;
                color: #636363;
                padding-left: 10px;
                &:hover {
                    color: #17a2b8;
                }
                background-color: transparent;
            }
        }
        .actions {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .edit svg, .show svg {
                font-size: 15px;
                color: #797979;
                &:hover {
                    color: #17a2b8;
                }
            }
        }
        p {
            margin: 0;
            padding: 0;
        }
    }
}
.card.selected {
    background: #17a2b814;
}
</style>
