<template>
  <div class="accounting w-100">
    <navbar headertext="Autogiro" />
    <div class="content p-4">
      <CustomerHeader :customer="customer" v-if="customer != null" />
      <div class="row">
        <div class="col-lg-12">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 box-info">
                <h3>Autogiro</h3>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Försäkringsnr</th>
                      <th>Kund</th>
                      <th>Antal betalningstillfällen per år</th>
                    </tr>
                  </thead>
                  <tbody v-if="autogiro">
                    <tr v-for="(r, index) in autogiro" :key="index">
                      <td><router-link :to="{}">{{ r.insuranceNr }}</router-link></td>
                      <td>{{ r.lastName }} {{ r.firstName }}</td>
                      <td>{{ r.ppy }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import CustomerHeader from "@/components/CustomerHeader.vue";
import { mapState } from "vuex";

var moment = require("moment");
moment.locale("sv");

export default {
  name: "economy-autogiro",
  components: {
    Navbar,
    CustomerHeader,
  },
  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      hidden: (state) => state.sidebar.hidden,
      apiKey: (state) => state.auth.apiKey,
      accesslevel: (state) => state.auth.accesslevel,
    }),
  },

  data() {
    return {
      customer: null,
      date: moment().format("YYYY-MM-DD"),
      autogiro: [],
    };
  },

  mounted() {
    this.getAutogiro();
  },

  methods: {
    getAutogiro() {
      this.$axios
        .get(this.baseUrl + "api/get.php?type=autogiro&key=" + this.apiKey)
        .then((response) => {
          console.log(response);
          if (response.data.status == "ok") {
            this.autogiro = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.red {
  background-color: #ffaaaa;
}
.green {
  background-color: lightgreen;
}
.white {
  background-color: white;
}
input {
  margin-bottom: 8px;
}
</style>