<template>
  <div class="customer-show w-100">
    <navbar />
    <div class="content p-4">
      <div class="row">
        <div class="col-md-10">
          <h3 v-if="customer != null">
            {{
              customer.category == "co"
                ? customer.companyName
                : customer.firstName
            }}
            {{ customer.category == "co" ? "" : customer.lastName }}
            {{
              customer.category == "co" && customer.lastName.length
                ? "/ " + customer.firstName + " " + customer.lastName
                : ""
            }}
          </h3>
        </div>
        <div class="col-md-2">
          <b-button
            v-if="accesslevel == 'admin'"
            variant="danger"
            style="float: right; margin-bottom: 12px"
            @click="deleteCustomer()"
            >Radera kund</b-button
          >
        </div>
      </div>
      <div class="row" v-if="customer != null && !loading">
        <div class="col-md-6">
          <div class="profile">
            <div class="content">
              <address class="text-muted">
                {{ customer.street }}, {{ nicePostcode(customer.postcode) }}
                {{ customer.city }}
              </address>
              <span class="text-muted contact-info"
                >{{ nicePhone(customer.mobilePhone)
                }}{{ nicePhone(customer.homePhone) }}, kundnummer
                {{ customer.customerNr }}</span
              >
              <router-link
                :to="{ name: 'customer-edit', params: { id: customer.id } }"
              >
                <font-awesome-icon
                  icon="pen"
                  style="color: black; margin-left: 12px"
                />
              </router-link>
            </div>
          </div>
          <div
            class="alert alert-danger"
            v-if="!customer.mobilePhone && customer.email"
          >
            Mobilnummer saknas!
          </div>
          <div
            class="alert alert-danger"
            v-if="customer.mobilePhone && !customer.email"
          >
            E-mail saknas!
          </div>
          <div
            class="alert alert-danger"
            v-if="!customer.mobilePhone && !customer.email"
          >
            Mobilnummer och e-mail saknas!
          </div>
          <hr />
          <div class="events" v-if="messages.length > 0">
            <div
              :class="
                message.customerMsg == 'sms'
                  ? customerEvent
                  : message.customerMsg == 'mail'
                  ? mailEvent
                  : normalEvent
              "
              v-for="(message, index) in messages"
              :key="index"
            >
              <pre class="event-text text-muted">{{ message.message }}</pre>
              <p class="author">
                <span class="date">{{ message.customerMsg }}</span>
                <span class="date">{{ convertDate(message.date) }}</span>
                <span class="separator">-</span>
                <span class="name">{{ message.author }}</span>
                <span class="separator">-</span>
                <span class="name">
                  <font-awesome-icon
                    icon="flag"
                    :style="{ color: '#E74C3C' }"
                    v-if="message.status != null && message.status == 'open'"
                  />
                  <font-awesome-icon
                    icon="flag"
                    :style="{ color: '#2ECC71' }"
                    v-if="message.status != null && message.status == 'closed'"
                  />
                  {{ message.assignee != null ? message.assignee : "" }}
                </span>
                <b-button
                  style="margin-left: 8px"
                  v-if="message.status == 'open'"
                  variant="success"
                  @click="closeAlert(message.alertID)"
                  >Klar</b-button
                >
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <textarea
              v-focus
              class="form-control"
              id="message"
              rows="5"
              placeholder="Ny anteckning..."
              v-model="message"
              :class="{
                'is-invalid': submitStatus == 'ERROR' && !$v.message.required,
              }"
            ></textarea>
            <div class="invalid-feedback" v-if="!$v.message.required">
              Du måste fylla i en anteckning.
            </div>
          </div>
          <div class="form-group">
            Aktivera meddelande till kund:
            <input
              type="checkbox"
              v-model="enableCustomer"
              @change="custMsg()"
            />
            <select
              class="form-control"
              name="customerMsg"
              id="customerMsg"
              v-model="customerMsg"
              :disabled="!enableCustomer"
            >
              <option :value="null" selected>Skicka inget till kunden</option>
              <option value="mail">Skicka anteckningen som e-mail</option>
              <option value="sms">Skicka anteckningen som sms</option>
            </select>
          </div>
          <div class="form-group">
            <label for="user">Ska följas upp av:</label>
            <select class="form-control" name="user" id="user" v-model="user">
              <option :value="null" selected>Ingen uppföljning</option>
              <template v-if="users.length > 0">
                <option
                  v-for="(user, index) in users"
                  :key="index"
                  :value="user"
                >
                  {{ user.realname }}
                </option>
              </template>
            </select>
          </div>
          <div class="form-group" v-if="user != null">
            <label for="notification" style="margin-right: 16px"
              >Notifiering:</label
            >
            <div class="form-check form-check-inline">
              <input
                type="radio"
                class="form-check-input"
                name="notification"
                v-model="notification"
                value="mail"
                id="notification-mail"
              />
              <label class="form-check-label" for="notification-mail"
                >e-mail</label
              >
            </div>
            <div class="form-check form-check-inline">
              <input
                type="radio"
                class="form-check-input"
                name="notification"
                v-model="notification"
                value="sms"
                id="notification-sms"
              />
              <label class="form-check-label" for="notification-sms"
                >sms och e-mail</label
              >
            </div>
          </div>
          <button
            type="button"
            class="btn btn-secondary btn-lg btn-block"
            @click="saveMessage"
          >
            Spara
          </button>
          <div
            class="alert alert-danger mt-2"
            role="alert"
            v-if="error != null"
          >
            {{ error }}
          </div>
          <div
            class="profile"
            style="margin-top: 24px"
            v-if="insurance.length > 0"
          >
            <div class="table-responsive content">
              <table class="table">
                <thead>
                  <tr>
                    <th>Försäkringsnummer</th>
                    <th>Fastighet</th>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(ins, index) in insurance" :key="index">
                    <td>
                      <router-link
                        :to="{
                          name: 'customer-insurance-popup',
                          params: {
                            customerID: customer.id,
                            insuranceNr: ins.insuranceID,
                            popup: 1,
                          },
                        }"
                        >{{
                          ins.insuranceNr > 0
                            ? ins.insuranceNr
                            : "(" + ins.insuranceID + ")"
                        }}</router-link
                      ><br />Saldo: {{ ins.balance.toLocaleString("sv") }}
                    </td>
                    <td>{{ ins.property }}</td>
                    <td>
                      <b-button
                        variant="warning"
                        style="float: right; margin-bottom: 12px"
                        @click="
                          openPDF(
                            ins.insuranceNr > 0
                              ? ins.insuranceNr
                              : ins.insuranceID
                          )
                        "
                        >Faktura</b-button
                      >
                      <b-button
                        variant="danger"
                        style="float: right; margin-bottom: 12px"
                        @click="deleteInsurance(ins.insuranceNr)"
                        >Radera</b-button
                      >
                      <b-button
                        variant="success"
                        style="
                          float: right;
                          margin-bottom: 12px;
                          margin-right: 12px;
                        "
                        @click="damageManagement(ins.insuranceNr)"
                        >Skada</b-button
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="uploader">
                <div class="header">
                  <h4>Filer</h4>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    v-if="!dropzone"
                    @click="addImages()"
                  >
                    <font-awesome-icon icon="plus-circle" />&nbsp;Ladda upp
                  </button>
                  <div class="actions" v-if="dropzone">
                    <button
                      type="button"
                      class="btn btn-success mr-3"
                      :disabled="files.length == 0 || uploading"
                      @click="saveImages()"
                    >
                      <span v-if="!uploading">
                        <font-awesome-icon icon="share-square" />&nbsp;Spara
                      </span>
                      <span v-if="uploading">
                        <pulse-loader
                          :loading="uploading"
                          size="15px"
                        ></pulse-loader>
                      </span>
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger"
                      :disabled="uploading"
                      @click="resetDropzone()"
                    >
                      <font-awesome-icon icon="times-circle" />&nbsp;Avbryt
                    </button>
                  </div>
                </div>
                <div
                  class="alert alert-success"
                  role="alert"
                  v-if="alertMassage != null && !dropzone"
                >
                  {{ alertMassage }}
                </div>
                <pulse-loader
                  :loading="uploading && !dropzone"
                  size="18px"
                ></pulse-loader>
                <div
                  class="table-responsive docs"
                  v-if="docs.length > 0 && !dropzone"
                >
                  <table class="table">
                    <tbody>
                      <tr v-for="(doc, index) in docs" :key="index">
                        <td>
                          <div class="thumbnail">
                            <img
                              :src="getURL(doc.fileID)"
                              class="img-thumbnail"
                              width="60px"
                              v-if="fileType(doc.mimetype) == 'IMAGE'"
                            />
                            <img
                              :src="require('@/assets/pdf.png')"
                              class="img-thumbnail"
                              width="60px"
                              v-if="fileType(doc.mimetype) == 'PDF'"
                            />
                            <a
                              class="overlay text-center"
                              target="_blank"
                              :href="getURL(doc.fileID)"
                            >
                              <font-awesome-icon icon="share-square" />
                            </a>
                          </div>
                        </td>
                        <td>
                          <span v-if="doc.description != 'null'">{{
                            doc.description
                          }}</span>
                          <span v-if="doc.description == 'null'">&nbsp;</span>
                        </td>
                        <td>{{ doc.ts }}</td>
                        <td>
                          <span class="edit">
                            <font-awesome-icon icon="edit" />
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  class="alert alert-primary"
                  role="alert"
                  v-if="docs.length == 0 && !dropzone && !uploading"
                >
                  Inga filer uppladdade
                </div>
                <div class="image-zone" v-if="dropzone">
                  <div class="table-responsive preview" v-if="files.length > 0">
                    <table class="table">
                      <tbody>
                        <tr v-for="(file, index) in files" :key="index">
                          <td>
                            <img
                              :src="createURL(file.file)"
                              class="img-thumbnail"
                              width="60px"
                              v-if="fileType(file.file.type) == 'IMAGE'"
                            />
                            <img
                              :src="require('@/assets/pdf.png')"
                              class="img-thumbnail"
                              width="60px"
                              v-if="fileType(file.file.type) == 'PDF'"
                            />
                          </td>
                          <td>{{ file.name }}</td>
                          <td>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Ange beskrivning"
                              v-model="file.description"
                            />
                          </td>
                          <td>{{ file.uploadedAt }}</td>
                          <td>
                            <span class="remove" @click="removeImage(index)">
                              <font-awesome-icon icon="times-circle" />
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="field imageDropzone">
                    <small>Filformat som stöds är pdf, jpg och png.</small>
                    <div
                      id="dropzone"
                      :class="['dropzone', dragging ? 'dragover' : '']"
                      @drop="handleImageDrop"
                      @dragstart="dragging = true"
                      @dragover="dragging = true"
                      @dragenter="dragging = true"
                      @dragleave="dragging = false"
                      @dragend="dragging = false"
                    >
                      <h2 class="title">Dra och släpp filen här</h2>
                      <p>eller</p>
                      <div class="custom-file mb-3">
                        <input
                          type="file"
                          class="custom-file-input"
                          id="file"
                          @change="onFileChange"
                          required
                        />
                        <label
                          class="custom-file-label"
                          for="file"
                          data-browse="Välj fil"
                          >Välj fil...</label
                        >
                        <div class="invalid-feedback">
                          Example invalid custom file feedback
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="alert alert-danger"
                    role="alert"
                    v-if="errorMessage != null"
                  >
                    {{ errorMessage }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <b-button @click="createInsurance()" variant="warning"
            >Ny försäkring</b-button
          >
          <h4 style="margin-top: 24px">Skador</h4>
          <div
            class="profile"
            style="margin-top: 24px"
            v-if="damages.length > 0"
          >
            <div class="table-responsive content">
              <table class="table">
                <thead>
                  <tr>
                    <th>Skadenr</th>
                    <th>Försäkring</th>
                    <th>Datum</th>
                    <th>Kategori</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(dmg, index) in damages" :key="index">
                    <td>
                      <router-link
                        :to="{
                          name: 'damage-details',
                          params: { damageID: dmg.damageID },
                        }"
                        >{{ dmg.damageNr }}</router-link
                      >
                    </td>
                    <td>
                      <router-link
                        :to="{
                          name: 'customer-insurance-popup',
                          params: {
                            customerID: customer.id,
                            insuranceNr: dmg.insuranceNr,
                            popup: 1,
                          },
                        }"
                        >{{ dmg.insuranceNr }}</router-link
                      >
                    </td>
                    <td>{{ dmg.damageDate }}</td>
                    <td>{{ dmg.category }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <pulse-loader :loading="loading"></pulse-loader>
    </div>
    <div v-if="showModal" class="modal-route">
      <div class="modal-content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { parsePostcode, parsePhone } from "@/_helpers/parser";
import Navbar from "@/components/Navbar.vue";
import { mapState } from "vuex";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { required } from "vuelidate/lib/validators";
var moment = require("moment");
moment.locale("sv");

export default {
  name: "customer-show",
  watch: {
    $route(newVal) {
      this.showModal = newVal.meta && newVal.meta.showModal;
    },
  },
  data() {
    return {
      showModal: false,
      loading: false,
      customer: null,
      customerMsg: null,
      enableCustomer: false,
      insurance: null,
      message: null,
      messages: [],
      error: null,
      normalEvent: "event mb-3",
      customerEvent: "event mb-3 customer-event",
      mailEvent: "event mb-3 mail-event",
      submitStatus: null,
      users: [],
      user: null,
      insuranceNr: "",
      property: "",
      damages: [],
      notification: "mail",
      extensions: ["png", "jpg", "jpeg", "pdf"],
      dropzone: false,
      dragging: false,
      uploading: false,
      files: [],
      alertMassage: null,
      errorMessage: null,
      docs: [],
    };
  },

  validations: {
    message: { required },
  },

  components: {
    Navbar,
    PulseLoader,
  },

  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      hidden: (state) => state.sidebar.hidden,
      apiKey: (state) => state.auth.apiKey,
      accesslevel: (state) => state.auth.accesslevel,
    }),
  },

  mounted() {
    this.fetchUsers();
    this.fetchCustomer();
    this.fetchFiles();

    document.addEventListener("drop", function (e) {
      e.preventDefault();
    });
    document.addEventListener("dragover", function (e) {
      e.preventDefault();
    });
  },

  beforeRouteLeave(to, from, next) {
    if (this.message != null && this.message.length > 0) {
      const answer = window.confirm(
        "Du har inte sparat anteckningen. Vill du radera den?"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },

  methods: {
    openPDF(insuranceNr) {
      window.open(
        this.baseUrl +
          "print/bg.php?insuranceNr=" +
          insuranceNr +
          "&key=" +
          this.apiKey
      );
    },
    fetchUsers() {
      this.$axios
        .get(this.baseUrl + "api/get.php?type=users&key=" + this.apiKey)
        .then((response) => {
          if (response.data.status == "ok") {
            this.users = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    closeAlert(id) {
      const answer = window.confirm("Är du säker på att du vill klarmarkera?");
      if (answer) {
        this.$axios
          .get(
            this.baseUrl + "api/closeAlert.php?key=" + this.apiKey + "&id=" + id
          )
          .then((response) => {
            if (response.data.status == "ok") {
              this.fetchCustomer();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    custMsg(val) {
      if (!val) {
        this.customerMsg = null;
      }
    },

    deleteInsurance(insuranceNr) {
      this.$swal
        .fire({
          icon: "warning",
          title: "Vill du verkligen radera försäkringen?",
          input: "textarea",
          inputPlaceholder: "Anledning",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Radera",
          cancelButtonText: "Avbryt",
          showLoaderOnConfirm: true,
          preConfirm: (message) => {
            if (message === "" || message === null) {
              this.$swal.showValidationMessage("Du måste ange anledning");
            } else {
              var postdata = new FormData();
              postdata.append("id", insuranceNr);

              return this.$axios
                .post(
                  this.baseUrl + "api/deleteInsurance.php?key=" + this.apiKey,
                  postdata
                )
                .then((response) => {
                  if (response.data.status == "ok") {
                    this.saveNote("Orsak radering av försäkring: " + message);
                    this.fetchCustomer();
                  } else {
                    throw new Error(response.statusText);
                  }
                })
                .catch((error) => {
                  this.$swal.showValidationMessage(
                    `Det gick inte att radera försäkringen: ${error}`
                  );
                });
            }
          },
          allowOutsideClick: () => !this.$swal.isLoading(),
        })
        .then((result) => {
          if (result.value) {
            this.$swal.fire("Raderat!", "Försäkringen raderad.", "success");
          }
        });
    },

    damageManagement(insuranceNr) {
      let id = this.$route.params.id;
      this.$router.push({
        name: "create-damage",
        params: { insuranceID: insuranceNr, customerID: id },
      });
    },

    deleteCustomer() {
      this.$swal
        .fire({
          icon: "warning",
          title: "Vill du verkligen radera kunden?",
          input: "textarea",
          inputPlaceholder: "Anledning",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Radera",
          cancelButtonText: "Avbryt",
          showLoaderOnConfirm: true,
          preConfirm: (message) => {
            if (message === "" || message === null) {
              this.$swal.showValidationMessage("Du måste ange anledning");
            } else {
              var postdata = new FormData();
              postdata.append("id", this.$route.params.id);

              return this.$axios
                .post(
                  this.baseUrl + "api/deleteCustomer.php?key=" + this.apiKey,
                  postdata
                )
                .then((response) => {
                  if (response.data.status == "ok") {
                    this.saveNote("Orsak radering av kund: " + message);
                    this.$router.push({ name: "customers" });
                  } else {
                    throw new Error(response.statusText);
                  }
                })
                .catch((error) => {
                  this.$swal.showValidationMessage(
                    `Det gick inte att radera kunden: ${error}`
                  );
                });
            }
          },
          allowOutsideClick: () => !this.$swal.isLoading(),
        })
        .then((result) => {
          if (result.value) {
            this.$swal.fire("Klart!", "Kunden är raderad.", "success");
          }
        });
    },

    fetchCustomer() {
      this.loading = true;
      let id = this.$route.params.id;
      this.$axios
        .get(
          this.baseUrl + "api/getCustomer.php?id=" + id + "&key=" + this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.customer = response.data.customer;
            this.insurance = response.data.insurance;
            this.messages = [];
            for (var i = 0; i < response.data.notes.length; i++) {
              this.messages.push({
                message: response.data.notes[i].note,
                date: moment(response.data.notes[i].ts),
                author: response.data.notes[i].realname,
                status: response.data.notes[i].status,
                assignee: response.data.notes[i].assignee,
                alertID: response.data.notes[i].alertID,
                customerMsg: response.data.notes[i].customerMsg,
              });
            }
            this.loading = false;
          } else {
            this.$router.push({ name: "login" });
          }
        });
      this.$axios
        .get(
          this.baseUrl +
            "api/get.php?type=customerDamages&id=" +
            id +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.damages = response.data.data;
          }
        });
    },

    saveNote(message) {
      var postdata = new FormData();
      postdata.append("id", this.$route.params.id);
      postdata.append("note", message);
      this.$axios
        .post(this.baseUrl + "api/saveNote.php?key=" + this.apiKey, postdata)
        .then((response) => {
          if (response.data.status == "ok") {
            console.log(response);
          }
        });
    },

    saveMessage() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        if (this.customerMsg == "mail" && !this.customer.email) {
          this.submitStatus = "ERROR";
          this.error = "Kunden saknar e-mailadress i systemet.";
          return;
        } else if (this.customerMsg == "sms" && !this.customer.mobilePhone) {
          this.submitStatus = "ERROR";
          this.error = "Kunden saknar mobiltelefonnummer i systemet.";
          return;
        }
        let userID = this.user != null ? parseInt(this.user.userID) : 0;
        if (
          this.customerMsg != null &&
          (this.customerMsg == "mail" || this.customerMsg == "sms")
        ) {
          var result = confirm(
            'Är du säker på att du vill skicka detta meddelande till kunden? "' +
              this.message +
              '"'
          );
          if (!result) {
            return;
          }
        }
        var postdata = new FormData();
        postdata.append("key", this.apiKey);
        postdata.append("id", this.$route.params.id);
        postdata.append("note", this.message);
        postdata.append("userID", userID);
        postdata.append("notification", this.notification);
        postdata.append("customerMsg", this.customerMsg);
        this.$axios
          .post(this.baseUrl + "api/saveNote.php", postdata)
          .then((response) => {
            if (response.data.status == "ok") {
              this.submitStatus = "PENDING";
              // this.messages.unshift({
              //   message: this.message,
              //   date: moment(),
              //   author: localStorage.realname
              // });
              this.message = null;
              this.user = null;
              this.customerMsg = null;
              this.enableCustomer = false;
              this.fetchCustomer();
            }
          });
      }
    },

    createInsurance() {
      var customerID = this.$route.params.id;
      this.$axios
        .post(
          this.baseUrl +
            "api/draft.php?type=create&customerID=" +
            customerID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            console.log(response.data);
            let insuranceID = response.data.data.insuranceID;
            this.$router
              .push({
                name: "create-insurance",
                query: { insuranceID: insuranceID },
              })
              .catch(() => {});
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    convertDate(date) {
      return date.format("D MMM YYYY, HH:mm");
    },

    updateCustomer() {
      console.log(this.customer);
    },

    nicePostcode(pc) {
      return parsePostcode(pc);
    },

    nicePhone(tfn) {
      return parsePhone(tfn);
    },

    fetchFiles() {
      this.loading = true;
      this.docs = [];
      let customerID = this.$route.params.id;
      this.$axios
        .get(
          this.baseUrl +
            "api/get.php?type=customerFiles&customerID=" +
            customerID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.docs = response.data.data;
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    handleDragOver() {
      this.dragging = true;
    },

    addImages() {
      this.dropzone = !this.dropzone;
      this.alertMassage = null;
    },

    saveImages() {
      this.uploading = true;
      let customerID = this.$route.params.id;
      this.files.forEach((file, index) => {
        var formData = new FormData();
        formData.append("file", file.file);
        formData.append("description", file.description);
        formData.append("customerID", customerID);

        this.$axios
          .post(
            this.baseUrl + "api/uploadCustomer.php?key=" + this.apiKey,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            if (response.data.status == "ok") {
              if (index === this.files.length - 1) {
                this.uploading = false;
                this.alertMassage = "Filerna uppladdade";
                this.files = [];
                this.dropzone = !this.dropzone;
                setTimeout(() => {
                  this.fetchFiles();
                }, 200);
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },

    resetDropzone() {
      this.dropzone = !this.dropzone;
      this.files = [];
      this.errorMessage = null;
    },

    getURL(fileID) {
      return (
        this.baseUrl +
        "api/getFile.php?fileID=" +
        fileID +
        "&key=" +
        this.apiKey
      );
    },

    createURL(file) {
      let url = URL.createObjectURL(file);

      return url;
    },

    fileType(mimetype) {
      if (
        mimetype == "image/png" ||
        mimetype == "image/jpeg" ||
        mimetype == "image/jpg"
      ) {
        return "IMAGE";
      } else if (mimetype == "application/pdf") {
        return "PDF";
      }
    },

    removeImage(selectedIndex) {
      this.$delete(this.files, selectedIndex);
    },

    handleImageDrop(e) {
      this.errorMessage = null;
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      for (let i = 0; i < files.length; i++) {
        if (this.isImageValidity(files[i])) {
          let fileName = files[i].name.split(".");
          this.files.push({
            file: files[i],
            name: fileName[0],
            description: null,
          });
        } else {
          this.errorMessage = "En eller några filer är inte tillåtna att välja";
        }
      }
    },

    onFileChange(e) {
      this.errorMessage = null;
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }

      if (this.isImageValidity(files[0])) {
        let fileName = files[0].name.split(".");
        this.files.push({
          file: files[0],
          name: fileName[0],
          description: null,
        });
      } else {
        this.errorMessage = "En eller några filer är inte tillåtna att välja";
      }
    },

    isImageValidity(file) {
      const name = file.name.split(".");
      const extension = name[1];
      const check = this.extensions.includes(extension);

      if (check) {
        return true;
      }

      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-route {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  .modal-content {
    overflow: auto;
    width: 90%;
    height: 90%;
    top: 5%;
    left: 5%;
    position: absolute;
    background: white;
  }
}
pre {
  white-space: pre-wrap;
}
a {
  color: blue;
}
a:hover {
  text-decoration: underline;
}
.customer-show {
  button {
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1px;
  }
  .profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #f1f1f1;
    padding: 10px 15px;
    border-radius: 5px;
    .content {
      margin-left: 10px;
      .name {
        font-weight: 600;
      }
      address,
      .contact-info {
        font-size: 14px;
        margin: 0;
      }
    }
    p {
      margin: 0;
      padding: 0;
    }
    .table {
      button {
        font-size: 15px;
      }
    }
  }
  .events {
    .customer-event {
      background: #ffff99 !important;
    }
    .mail-event {
      background: #ccccff !important;
    }
    .event {
      padding: 10px 15px;
      background: #f7f7f7;
      border-radius: 5px;
      .event-text {
        // font-weight: 600;
        font-size: 16px;
        // letter-spacing: 1px;
      }
      .author {
        font-size: 12px;
        font-weight: 600;
        color: #797979;
        // letter-spacing: 0.05rem;
        margin: 0;
        span:not(:last-child) {
          padding-right: 8px;
        }
        svg {
          font-size: 14px;
        }
      }
      .separator {
        color: #000;
      }
    }
  }
  .uploader {
    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #d7d7dd;
      border-bottom: 1px solid #d7d7dd;
      padding: 15px 10px;
      background: #f1f1f1;
      margin: 20px 0;
      h4 {
        margin: 0;
        padding: 0;
      }
    }
    .docs {
      table {
        tr:hover {
          background: #f1f1f1;
          td .overlay {
            display: block;
          }
        }
        td {
          vertical-align: middle;
          .thumbnail {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            width: 60px;
            height: 60px;
            .overlay {
              display: none;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: rgba(0, 0, 0, 0.65);
              z-index: 9999;
              color: #fff;
              cursor: pointer;
              border-radius: 4px;
              svg {
                font-size: 20px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }
          .edit {
            cursor: pointer;
            &:hover {
              color: #209cee;
            }
          }
        }
        p {
          margin: 0;
          padding: 0;
        }
        img {
          border: 0;
        }
      }
    }
    .image-zone {
      .preview {
        table {
          tr:hover {
            background: #f1f1f1;
          }
          td {
            vertical-align: middle;
          }
          p {
            margin: 0;
            padding: 0;
          }
          img {
            border: 0;
          }
          svg {
            color: #e74c3c;
            font-size: 18px;
            cursor: pointer;
          }
        }
      }
      .imageDropzone {
        margin: 15px 0;
      }

      .dropzone {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 2px dashed #8e8e8e;
        padding: 35px 0;
        .custom-file {
          width: 400px;
        }
        .title {
          text-transform: uppercase;
          color: #6f6f6f;
          font-size: 22px;
        }
        .file {
          align-items: center;
          justify-content: center;
        }
      }
      .dropzone.dragover {
        border-color: #209cee;
        background: #efefef;
      }
    }
  }
}
</style>
