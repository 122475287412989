import axios from "../../axios";

const state = {
    users: [],
    allUsers: []
}

const mutations = {
    FETCH_USERS(state, payload) {
        state.users = payload.data
    },

    GET_ALL_USERS(state, payload) {
        state.allUsers = payload
    }
}

const actions = {
    fetchUsers({rootState, commit}) {
        return new Promise((resolve, reject) => {
            axios
                .get(rootState.baseUrl + "api/get.php?type=users&key=" + rootState.auth.apiKey)
                .then((response) => {
                    if (response.data.status == "ok") {
                        commit('FETCH_USERS', response.data)
                        resolve(response)
                    }
                })
                .catch((error) => {
                    reject(error)
                });
        })
    },

    getAllUsers({rootState, commit}) {
        return new Promise((resolve, reject) => {
            axios.get(rootState.baseUrl +"api/getAllUsers.php?key="+rootState.auth.apiKey)
            .then((response) => {
                if (response.data.status == 'ok') {
                    commit('GET_ALL_USERS', response.data.data)
                }
            })
            .catch((error) => {
                reject(error)
            })
        })
    }
}

const namespaced = true

export default {
    namespaced,
    state,
    mutations,
    actions
}
