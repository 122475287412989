<template>
  <div class="accounting w-100">
    <navbar headertext="Bokföring" />
    <div class="content p-4">
      <CustomerHeader :customer="customer" v-if="customer != null" />

      <div class="box-info">
        <div class="row">
          <div class="col-lg-12">
            <div class="row">
              <div class="col-md-10">
                <h3>Bokföring</h3>
              </div>
            </div>
            <div class="card mt-3">
              <div class="col-md-2 mt-3">
                <b-form-group class="payment-form">
                  <datepicker
                    format="yyyy-MM-dd"
                    input-class="form-control"
                    :bootstrap-styling="true"
                    v-model="date"
                    :monday-first="true"
                    :language="sv"
                    placeholder="Välj datum"
                  ></datepicker>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 box-info">
                <h3>Konton</h3>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Kontonr</th>
                      <th>Belopp</th>
                    </tr>
                  </thead>
                  <tbody v-if="accounting">
                    <tr v-for="(r, index) in accounting" :key="index">
                      <td>{{ r.account }}</td>
                      <td>
                        {{
                          r.amount.toLocaleString("sv-SE", {
                            style: "currency",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                            currency: "SEK",
                          })
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import CustomerHeader from "@/components/CustomerHeader.vue";
import { mapState } from "vuex";
import Datepicker from "vuejs-datepicker";
import { sv } from "vuejs-datepicker/dist/locale";

var moment = require("moment");
moment.locale("sv");

export default {
  name: "economy-accounting",
  components: {
    Navbar,
    CustomerHeader,
    Datepicker,
  },
  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      hidden: (state) => state.sidebar.hidden,
      apiKey: (state) => state.auth.apiKey,
      accesslevel: (state) => state.auth.accesslevel,
    }),
  },

  data() {
    return {
      customer: null,
      date: new Date(),
      accounting: [],
      sv: sv,
    };
  },

  watch: {
    date: function (val) {
      this.getAccount(val);
    }
  },

  mounted() {
    this.getAccount(this.date);
  },

  methods: {
    getAccount(date) {
      this.$axios
        .get(
          this.baseUrl +
            "api/get.php?type=bookingAccounts&date=" +
            moment(date).format("YYYY-MM-DD") +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          console.log(response);
          if (response.data.status == "ok") {
            this.accounting = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.red {
  background-color: #ffaaaa;
}
.green {
  background-color: lightgreen;
}
.white {
  background-color: white;
}
input {
  margin-bottom: 8px;
}
</style>