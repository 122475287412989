<template>
  <div class="products">
    <div class="row">
      <div class="col-md-4">
        <template v-if="products.length > 0">
          <div
            class="card product"
            v-for="(product, index) in groupProducts"
            :key="index"
          >
            <div class="card-body">{{ index }}</div>
            <ul>
              <li
                v-for="(prod, key) in product"
                :key="key"
                @click="status === 'draft' ? handleAddProduct(prod) : null"
              >
                {{ prod.name }}
              </li>
            </ul>
          </div>
        </template>
        <div class="alert alert-primary" role="alert" v-else>
          <p>Ingen produkt hittades</p>
        </div>
      </div>
      <div class="col-md-8">
        <template v-if="selectedProducts.length > 0">
          <div
            class="productlist"
            v-for="(sp, spKey) in selectedProducts"
            :key="spKey"
          >
            <div
              class="card list"
              @click="status === 'draft' ? deleteSelectedProduct(sp) : null"
            >
              <div class="card-body">
                <span class="parent">{{ sp.name }}</span>
              </div>
            </div>
          </div>
        </template>
        <div class="alert alert-primary" role="alert" v-else>
          <p>Välj produkter till vänster genom att klicka på dem.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "products",
  props: ["insuranceid", "status"],
  data() {
    return {
      selectedProds: [],
    };
  },

  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      apiKey: (state) => state.auth.apiKey,
      products: (state) => state.product.products,
      selectedProducts: (state) => state.product.selectedProducts,
      subproducts: (state) => state.product.subproducts,
      insurances: "insurances",
    }),

    groupProducts() {
      return this.products.reduce((accumulator, obj) => {
        let key = obj["category"];
        if (!accumulator[key]) {
          accumulator[key] = [];
        }
        this.$set(accumulator[key], "selected", false);
        accumulator[key].push(obj);
        return accumulator;
      }, {});
    },
  },

  methods: {
    ...mapActions("product", ["fetchSelectedProducts"]),

    isProductSelected(product) {
      return this.selectedProducts.find(
        (sp) => sp.productID === product.productID
      );
    },

    sortSelectedProducts() {
      this.selectedProds = [];
      if (this.selectedProducts.length > 0) {
        this.selectedProducts.forEach((sp) => {
          sp.children = [];
          sp.files = [];
          sp.draggable = false;
          this.subproducts.forEach((product) => {
            if (sp.productID == product.parentID) {
              product.selected = false;
              product.draggable = true;
              sp.children.push(product);
            }
          });
          this.selectedProds.push(sp);
        });
      }
    },

    handleAddProduct(product) {
      var formData = new FormData();
      formData.append("insuranceID", this.insuranceid);
      formData.append("productID", product.productID);
      this.$axios
        .get(
          this.baseUrl +
            "api/draft.php?type=addProduct&insuranceID=" +
            this.insuranceid +
            "&productID=" +
            product.productID +
            "&key=" +
            this.apiKey,
          formData
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.fetchSelectedProducts(this.insuranceid).then(() => {
              this.sortSelectedProducts();
              this.$store.dispatch("fetchAllObjects", this.insuranceid);
              this.$store.dispatch(
                "fetchInsuranceGuidelines",
                this.insuranceid
              );
              this.$store.dispatch("fetchExtraGuidelines", this.insuranceid);
              this.$store.commit("FILTER_GUIDELINES");
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deleteSelectedProduct(product) {
      console.log(product);
      let insurance = this.insurances.find(
        (insurance) => insurance.draftProductID == product.draftProductID
      );
      console.log(insurance);
      if (insurance && insurance.objects.length > 0) {
        this.$swal
          .fire({
            icon: "warning",
            title:
              "Det finns objekt inlagt. Är du säker på att du vill radera?",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Radera",
            cancelButtonText: "Avbryt",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.deleteProduct(product);
            }
          });
      } else {
        this.deleteProduct(product);
      }
    },

    deleteProduct(product) {
      this.$axios
        .get(
          this.baseUrl +
            "api/draft.php?type=deleteProduct&draftProductID=" +
            product.draftProductID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.fetchSelectedProducts(this.insuranceid).then(() => {
              this.sortSelectedProducts();
              this.$store.dispatch("fetchAllObjects", this.insuranceid);
              this.$store.dispatch(
                "fetchInsuranceGuidelines",
                this.insuranceid
              );
              this.$store.dispatch("fetchExtraGuidelines", this.insuranceid);
              this.$store.commit("FILTER_GUIDELINES");
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.hideProduct {
  display: none;
}
.products {
  .alert p {
    margin: 0;
  }
  .card {
    border: 0;
    border-radius: 0;
    .card-body {
      font-weight: 600;
      background: #f1f1f1;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
    }
  }
  ul {
    padding-left: 0;
    margin-bottom: 0;
    li {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      list-style: none;
      padding: 15px 20px;
      &:hover {
        background: #eff7ff;
      }
      .plus {
        color: #99a3a4;
      }
      .checked {
        color: #16a085;
      }
    }
  }
  .productlist {
    .card-body {
      background: #fff;
      border-top: 1px solid #ddd;
      border-bottom: 0;
      cursor: pointer;
    }

    .sub:last-child {
      border-bottom: 0;
    }
  }
}
</style>
