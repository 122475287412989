<template>
  <div class="changes-log w-100">
    <navbar />
    <div class="content p-4">
      <div class="row">
        <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control form-control-lg"
                    placeholder="Kund"
                    v-model="customer"
                    @keyup="fetchChangesLog"
                  />
                </div>
                <div class="col">
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control form-control-lg"
                    placeholder="Ändrat av"
                    v-model="user"
                    @keyup="fetchChangesLog"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <table class="table table-striped" v-if="changelogs.length > 0">
            <thead>
              <tr>
                <th scope="col">Tidpunkt</th>
                <th scope="col">Kund</th>
                <th scope="col">Skada</th>
                <th scope="col">Ändrat av</th>
                <th scope="col">Till</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(changelog, index) in changelogs" :key="index">
                <td>{{ changelog.ts }}</td>
                <td>
                  <router-link
                    :to="{
                      name: 'customer-show',
                      params: { id: changelog.id },
                    }"
                    >{{
                      changelog.companyName
                        ? changelog.companyName
                        : changelog.firstName + " " + changelog.lastName
                    }}</router-link
                  >
                </td>
                <td>{{ changelog.rubrik }}</td>
                <td>{{ changelog.realname }}</td>
                <td>
                  {{ changelog.status == "deleted" ? "Raderad" : "Återställd" }}
                </td>
                <td>
                  <button
                    v-if="changelog.status == 'deleted'"
                    type="button"
                    class="btn btn-secondary btn-block"
                    @click="undoDelete(changelog.damageID)"
                  >
                    Återställ
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Navbar from "@/components/Navbar.vue";
export default {
  name: "DeletedDamages",
  data() {
    return {
      changelogs: [],
      customer: "",
      user: "",
      valueType: "",
    };
  },

  computed: {
    ...mapState({
      baseUrl: "baseUrl",
      apiKey: (state) => state.auth.apiKey,
    }),
  },

  components: {
    Navbar,
  },

  mounted() {
    this.fetchChangesLog();
  },

  methods: {
    undoDelete(id) {
      var postdata = new FormData();
      postdata.append("id", id);

      this.$axios
        .post(
          this.baseUrl + "api/undoDeleteDamage.php?key=" + this.apiKey,
          postdata
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.fetchChangesLog();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchChangesLog() {
      this.$axios
        .get(
          this.baseUrl +
            "api/get.php?type=deletedDamages&search=" +
            encodeURIComponent(this.customer) +
            "&user=" +
            encodeURIComponent(this.user) +
            "&key=" +
            encodeURIComponent(this.apiKey)
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.changelogs = response.data.data;
            console.log(response.data);
          }
        });
      // console.log('Customer: ' + this.customer)
      // console.log('User: ' + this.user)
      // console.log('valueType: ' + this.valueType)
    },
  },
};
</script>

<style lang="scss" scoped>
.changes-log {
  .card {
    background: #f1f1f1;
  }
  .table {
    td {
      font-size: 0.96rem;
      a {
        color: #4c4c4c;
        font-weight: 600;
        font-size: 0.9rem;
      }
    }
    tr:hover {
      td a {
        color: #3498db;
      }
    }
  }
}
</style>